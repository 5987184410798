import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Grid, Input, Modal } from 'semantic-ui-react';
import { CURRENCIES } from '../Utilities/forex';
import { PATTERN, FIELD_TYPE, validateField, isAllowed } from '../Utilities/core';
import { REST_CLIENTS_TYPE, createClient } from '../../rest';

const TITLE = 'Trade Commitment';

const SCHEMA = [
  { fieldName: 'partyId', fieldtype: FIELD_TYPE.DROPDOWN, required: true },
  {
    fieldName: 'transactionDate',
    fieldRule: PATTERN.SAFE_CHARS,
    fieldtype: FIELD_TYPE.STRING,
    required: true,
  },
  {
    fieldName: 'financeAmt',
    fieldRule: PATTERN.DECIMAL,
    fieldtype: FIELD_TYPE.NUMBER,
    required: false,
  },
  { fieldName: 'financeCurrency', fieldtype: FIELD_TYPE.DROPDOWN, required: false },
  {
    fieldName: 'settlementAmt',
    fieldRule: PATTERN.DECIMAL,
    fieldtype: FIELD_TYPE.NUMBER,
    required: false,
  },
  { fieldName: 'settlementCurrency', fieldtype: FIELD_TYPE.DROPDOWN, required: false },
];

const FORM = (props) => {
  const [post, setPost] = useState(props.data || {});
  const [errors, setErrors] = useState(
    Object.assign({}, ...Object.keys(props.data || {}).map((k) => ({ [k]: false })))
  );
  const [submit, setSubmit] = useState(false);
  const [apiAlert, setApiAlert] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => setSubmit(isValidForm()), [errors]);

  const onChange = (e, { name, value }) => {
    setPost({ ...post, [name]: value });
    setErrors(validateField(name, value, props.schema, errors));
  };

  const isValidForm = () => {
    const schema = props.schema || [];
    for (let i = 0; i < schema.length; i++) {
      let fd = schema[i];
      if (fd.required) {
        if (!errors.hasOwnProperty(fd.fieldName) || errors[fd.fieldName]) {
          return false;
        }
      } else {
        if (errors.hasOwnProperty(fd.fieldName) && errors[fd.fieldName]) {
          return false;
        }
      }
    }
    return true;
  };

  function submitForm() {
    setButtonLoading(true);
    if (isAllowed()) {
      if (props.data.id) {
        createClient(REST_CLIENTS_TYPE.Otc)
          .updateCommit(post)
          .then(props.onSuccess)
          .catch((e) => {
            setButtonLoading(false);
            console.error(`Failed to update OTC Commitment ${post}`, e);
            setApiAlert(e.message);
          });
      } else {
        createClient(REST_CLIENTS_TYPE.Otc)
          .createCommit(post)
          .then(props.onSuccess)
          .catch((e) => {
            setButtonLoading(false);
            console.error(`Failed to create OTC Commitment ${post}`, e);
            setApiAlert(e.message);
          });
      }
    } else {
      setButtonLoading(false);
      setApiAlert("You Don't Have Access to this. Please contact admin.");
    }
  }

  function getCounterpartyOptions() {
    return Object.entries(props.data.counterparties)
      .sort((a, b) => a[1].toLowerCase().localeCompare(b[1].toLowerCase()))
      .map((kv) => {
        return { name: kv[1], value: kv[0], text: kv[1] };
      });
  }

  return (
    <Grid centered columns={1}>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error saving data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Counterparty</label>
          <Dropdown
            selection
            fluid
            search
            name="partyId"
            value={post.partyId}
            placeholder="Counterparty"
            options={getCounterpartyOptions()}
            onChange={onChange}
          />
          {errors.partyId && <span className="error-text">Counterparty is required</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Transaction Date</label>
          <Input
            error={errors.transactionDate}
            fluid
            type="date"
            name="transactionDate"
            value={post.transactionDate}
            onChange={onChange}
          />
          {errors.transactionDate && <span className="error-text">Transaction Date is required</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={9} className="noPadding">
          <label>Financing Amount</label>
          <Input
            error={errors.financeAmt}
            fluid
            className="control"
            name="financeAmt"
            placeholder="Financing Amount"
            type="number"
            value={post.financeAmt}
            onChange={onChange}
          />
          {errors.financeAmt && <span className="error-text">Financing Amount is invalid</span>}
        </Grid.Column>
        <Grid.Column width={3} className="noPadding">
          <label>Currency</label>
          <Dropdown
            selection
            fluid
            search
            name="financeCurrency"
            options={CURRENCIES}
            placeholder="BTC"
            value={post.financeCurrency}
            onChange={onChange}
          />
          {errors.financeCurrency && (
            <span className="error-text">Financing Amount is invalid</span>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={9} className="noPadding">
          <label>Settlement Amount</label>
          <Input
            error={errors.settlementAmt}
            fluid
            className="control"
            name="settlementAmt"
            placeholder="Settlement Amount"
            type="number"
            value={post.settlementAmt}
            onChange={onChange}
          />
          {errors.settlementAmt && (
            <span className="error-text">Settlement Amount is required</span>
          )}
        </Grid.Column>
        <Grid.Column width={3} className="noPadding">
          <label>Currency</label>
          <Dropdown
            selection
            fluid
            search
            name="settlementCurrency"
            options={CURRENCIES}
            placeholder="USD"
            value={post.settlementCurrency}
            onChange={onChange}
          />
          {errors.settlementCurrency && (
            <span className="error-text">Settlement Currency is invalid</span>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding noMargin">
          <Button loading={buttonLoading} fluid primary disabled={!submit} onClick={submitForm}>
            {props.data.id ? 'Update ' : 'Create '}Trade Commitment
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export { TITLE, FORM, SCHEMA };
