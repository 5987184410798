import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import PerAssetExposure from './PerAssetExposure';
import axios from 'axios';
import config from "../../config";
import { Select } from 'semantic-ui-react';
import {getUserToken} from "../../js/Auth";

const Organizations = [
  {
    key: 'paradox_a',
    text: 'Paradox A',
    value: 'paradox_a',
    image: { avatar: true, src: '/avatar/paradox.png' },
  },
  {
    key: 'paradox_b',
    text: 'Paradox B',
    value: 'paradox_b',
    image: { avatar: true, src: '/avatar/paradox.png' },
  },
];


const EXPOSURE_API = config.EXPOSURE_API

const ExposureDetails = (props) => {
  const [selectedOrg, setSelectedOrg] = useState(Organizations[0].value)
  const [exposure, setExposure] = useState({});
  const extended = false; // If you want to retrieve actual vendor_meta data as well.

  const pollData = async (opts) => {
    const fn = axios.get(`${EXPOSURE_API}/exposures?${new URLSearchParams({org: selectedOrg, extended})}`, {headers: {'Authorization': getUserToken()}});
    fn.then(r => {
        console.log('received data', r.data.queries.org, selectedOrg, r);
        // r.data.wallets = getExposures(selectedOrg).concat(r.data.wallets);
        setExposure(r.data);
      })
      .catch(e => {
        console.error(`Failed to fetch exposures retrying`, e);
        setTimeout(() => pollData(), 1000);
      });
  }

  useEffect((d, e) => pollData(), [exposure]);

  const selectOrg = (v) => {
    console.log('selecting organisation', v);
    setSelectedOrg(v.value);
  }
  return (
    <div className="exposure-details">
      <Grid>
        <Grid.Row>
          <Select selection className="Nav-organizations" defaultValue={selectedOrg} options={Organizations} onChange={(e, v) => selectOrg(v)} />
          <PerAssetExposure byVendor={props.match.params.exchange} exposure={exposure}/>
        </Grid.Row>
      </Grid>
    </div>
  )
};

export default ExposureDetails;