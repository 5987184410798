// source: common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.osf.pb.Currency', null, global);
goog.exportSymbol('proto.osf.pb.Organization', null, global);
/**
 * @enum {number}
 */
proto.osf.pb.Currency = {
  UNKNOWN_CURRENCY: 0,
  USD: 1,
  CAD: 2,
  EUR: 3,
  JPY: 4,
  GBP: 5,
  CHF: 6,
  BTC: 100,
  ETH: 101,
  DOGE: 102,
  BCH: 103,
  USDT: 104,
  BSV: 105,
  ADA: 106,
  ZEC: 107,
  USDC: 108,
  XMR: 109,
  XRP: 110,
  XLM: 111,
  UST: 112,
  XAUT: 113,
  EOS: 114,
  DOT: 115,
  XTZ: 116,
  BTG: 117,
  FTT: 118,
  LEO: 119,
  ETC: 120,
  DASH: 121,
  LTC: 122,
  REP: 123,
  XEM: 124
};

/**
 * @enum {number}
 */
proto.osf.pb.Organization = {
  UNKNOWN_ORGANIZATION: 0,
  PARADOX: 1
};

goog.object.extend(exports, proto.osf.pb);
