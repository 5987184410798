import React, { useState, useEffect, Fragment } from 'react';
import {
  Icon,
  Grid,
  Table,
  List,
  Button,
  Confirm,
  Dimmer,
  Loader,
  Dropdown,
  Modal,
} from 'semantic-ui-react';
import FormModal from '../Utilities/FormModal';
import { REST_CLIENTS_TYPE, createClient } from '../../rest';
import { decimals, numberWithCommas } from '../../utils/numberFormat';
import moment from 'moment';
import { isAllowed } from '../Utilities/core';

export default function Commitments(props) {
  const [commits, setCommits] = useState([]);
  const [counterparties, setCounterparties] = useState(new Map());
  const [dimmerActive, setDimmerActive] = useState(true);
  const [refetch, setRefetch] = useState(0);
  const [filters, setFilters] = useState({});
  const [apiAlert, setApiAlert] = useState(false);

  useEffect(() => {
    Promise.all([
      createClient(REST_CLIENTS_TYPE.Otc).listAllCommits(),
      createClient(REST_CLIENTS_TYPE.Party).getAll(),
    ])
      .then((results) => {
        const [fetchedCommits, fetchedCounterparties] = results;
        setCommits(fetchedCommits);
        // Make a map from counterparty ID to name
        setCounterparties(
          fetchedCounterparties.reduce((map, cp) => {
            map[cp.id] = cp.name;
            return map;
          }, {})
        );
        setDimmerActive(false);
      })
      .catch((e) => {
        console.error('Failed to load commitments', e);
        setApiAlert(e.message);
      });
  }, [refetch]);

  const onUpdate = () => {
    setRefetch(refetch + 1);
  };

  const [showModal, setShowModal] = useState(false);

  const showDelete = (id) => setShowModal({ [id]: true });
  const hideDelete = (id) => setShowModal({ [id]: false });

  const handleDeleteEntry = (id) => {
    if (isAllowed()) {
      createClient(REST_CLIENTS_TYPE.Otc)
        .deleteCommit(id)
        .then((resp) => {
          console.log(`Trade Commitment Entry deleted`, resp);
          onUpdate();
        })
        .catch((error) => {
          console.error(`Failed to delete Trade Commitment entry`, error);
          setApiAlert(error.message);
        })
        .finally(() => setShowModal({ [id]: false }));
    } else {
      setApiAlert('You are not allowed to perform this operation.');
    }
  };

  const getCounterpartyList = () => {
    if (!counterparties || !commits) return [];
    const uniqueParties = [...new Set(commits.map((x) => counterparties[x.partyId]))];
    return uniqueParties
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map((x) => {
        return {
          name: x,
          value: Object.keys(counterparties).find((key) => counterparties[key] === x),
          text: x,
        };
      });
  };

  const filterTable = () => {
    if (!commits) return [];
    return commits.filter((key) => {
      if (!filters.counterparty) return true;
      else if (filters.counterparty && filters.counterparty === key.partyId) return true;
    });
  };

  const onChange = (e, { name, value }) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <Grid>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      {dimmerActive && (
        <Dimmer active={dimmerActive} inverted>
          <Loader content="Loading" />
        </Dimmer>
      )}
      <Grid.Row>
        <Grid.Column>
          <div className="commitments">
            <div className="title">Trade Commitments</div>
            <div className="controls">
              <FormModal
                for="NewTradeCommitment"
                data={{ counterparties }}
                onSuccess={onUpdate}
                trigger={
                  <Button primary floated="right" disabled={!isAllowed()}>
                    New Entry
                  </Button>
                }
              />
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                search
                options={getCounterpartyList()}
                floated="right"
                name="counterparty"
                placeholder="Filter by counterparty"
                value={filters.counterparty}
                onChange={onChange}
              />
              {/*  <Input className="control" name="endDate" placeholder="End date" type="date" onChange={this.handleDateChange} />
                <Input className="control" name="startDate" placeholder="Start date" type="date" onChange={this.handleDateChange} /> */}
            </div>

            <Table basic singleLine selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Counterparty</Table.HeaderCell>
                  <Table.HeaderCell>Financing Amount</Table.HeaderCell>
                  <Table.HeaderCell>Settlement Amount</Table.HeaderCell>
                  <Table.HeaderCell>Rate recorded</Table.HeaderCell>
                  <Table.HeaderCell>Execution date</Table.HeaderCell>
                  <Table.HeaderCell>Transaction type</Table.HeaderCell>
                  <Table.HeaderCell>Trade ID</Table.HeaderCell>
                  <Table.HeaderCell>Broker Fee %</Table.HeaderCell>
                  <Table.HeaderCell>Broker fee</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filterTable().map((tx, i) => {
                  return (
                    <Fragment key={i}>
                      <Table.Row>
                        <Table.Cell className="counterparty">
                          {counterparties[tx.partyId]}
                        </Table.Cell>
                        <Table.Cell>
                          {numberWithCommas(decimals(tx.financeAmt)) + ' ' + tx.financeCurrency}
                        </Table.Cell>
                        <Table.Cell>
                          {numberWithCommas(decimals(tx.settlementAmt)) + ' ' + tx.settlementCurrency}
                        </Table.Cell>
                        <Table.Cell>{numberWithCommas(decimals(tx.rateRecorded))}</Table.Cell>
                        <Table.Cell>{moment(tx.transactionDate).format('MMMM DD, YYYY')}</Table.Cell>
                        <Table.Cell>{tx.transactionType}</Table.Cell>
                        <Table.Cell className="tradeID">{tx.id}</Table.Cell>
                        <Table.Cell>{decimals(tx.otcFee)}</Table.Cell>
                        <Table.Cell>{`${decimals(tx.otcFee * 0.0001 * tx.financeAmt)} ${tx.financeCurrency}`}</Table.Cell>
                        <Table.Cell disabled={!isAllowed()}>
                          <div className="tooltip">
                            <Icon link name="ellipsis horizontal" />
                            <List className="tooltiptext otc">
                              <FormModal
                                for="NewTradeCommitment"
                                data={{ ...tx, counterparties: counterparties }}
                                uuid={tx.id}
                                onSuccess={onUpdate}
                                trigger={<List.Item>Edit Details</List.Item>}
                              />
                              <List.Item onClick={() => showDelete(tx.id)}>Delete</List.Item>
                              <Confirm
                                size="tiny"
                                className="delete"
                                open={showModal[tx.id]}
                                header="Delete this Entry?"
                                cancelButton="Cancel"
                                confirmButton="Yes, delete"
                                content="Are you sure you want to delete this entry? You can not undo this action and it may impact existing OTC or bank records."
                                onCancel={() => hideDelete(tx.id)}
                                onConfirm={() => handleDeleteEntry(tx.id)}
                              />
                            </List>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </Fragment>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
