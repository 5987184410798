import { useState, useEffect } from 'react';
import { Button, Dimmer, Grid, Loader, Modal } from 'semantic-ui-react';
import ShowCounterpartyDetails from './ShowCounterpartyDetails';
import FormModal from '../Utilities/FormModal';
import { createClient, REST_CLIENTS_TYPE } from '../../rest'

const Counterparties = () => {
  const [counterparties, setCounterparties] = useState();
  const [banks, setBanks] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [dimmerActive, setDimmerActive] = useState(true);
  const [refetch, setRefetch] = useState(0);
  const [apiAlert, setApiAlert] = useState(false);
  const [lastOp, setLastOp] = useState(null);

  useEffect(() => {
    async function populateCps() {
      setDimmerActive(true);
      try {
        const cpResp = await createClient(REST_CLIENTS_TYPE.Party).getAll();
        const banksResp = await createClient(REST_CLIENTS_TYPE.Bank).getAll();
        setCounterparties(cpResp);
        setBanks(banksResp);
        onSelect(activeIndex);
        if (lastOp === 'Created' || activeIndex >= cpResp.length) {
          setTimeout(() => setActiveIndex(cpResp.length - 1), 200);
        }
        setDimmerActive(false);
      } catch (e) {
        console.error(`Failed to fetch counterparties`, e);
        setApiAlert(e.message);
      }
    }
    populateCps();
  }, [refetch]);

  const onSelect = (index) => {
    setActiveIndex(index);
  };

  const onCounterpartiesUpdate = (op) => {
    setLastOp(op);
    setRefetch(refetch + 1);
  };

  return (
    <Grid className="page-wrapper counterparty-page">
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error loading data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Column className="list-view" width={2}>
        <Grid width={2}>
          <Grid.Row as="header" centered>
            <FormModal
              for="NewCounterparty"
              onSuccess={onCounterpartiesUpdate}
              trigger={<Button primary>Create Counterparty</Button>}
            />
          </Grid.Row>
          <Grid.Column width={16} className="full-list">
            {dimmerActive ? (
              <Dimmer active={dimmerActive} inverted>
                <Loader content="Loading" />
              </Dimmer>
            ) : (
              counterparties &&
              counterparties.map((d, i) => {
                return (
                  <Grid.Row
                    key={i}
                    columns={1}
                    onClick={() => onSelect(i)}
                    className={`list-item ${i === activeIndex ? 'active' : ''}`}
                  >
                    <Grid.Column width={10} className="text-title">
                      {d.name}
                    </Grid.Column>
                  </Grid.Row>
                );
              })
            )}
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column className="detail-view" width={12}>
        {banks && counterparties && counterparties[activeIndex] && (
          <ShowCounterpartyDetails
            banks={banks}
            onCounterpartyUpdate={onCounterpartiesUpdate}
            info={counterparties[activeIndex]}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Counterparties;
