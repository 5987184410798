import { ClientBase } from "../rest_client";

type Counterparty = {
  id: string;
  name: string;
  email: string;
  country: string;
  contactName: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  postal: string;
  otcFee: number;
  note: string;
}

export class CounterpartyClient extends ClientBase {

  async create(counterparty: Omit<Counterparty, 'id'>) {
    this.log('Creating new counterparty: ', counterparty);
    await this.client.post('/counterparties', counterparty)
  }

  async update(counterparty: Counterparty) {
    this.log('Updating counterparty: ', counterparty);
    await this.client.put(`/counterparties/${counterparty.id}`, counterparty)
  }

  async delete(counterparty_id: string) { 
    this.log('deleting counterparty: ', counterparty_id);
    await this.client.delete(`/counterparties/${counterparty_id}`)
  }

  //async get(counterparty_id: string) : Promise<Counterparty> {
  //  this.log('Getting counterparty: ', counterparty_id);
  //  return {
  //    id: counterparty_id, 
  //    name: 'fake counterparty', 
  //    email: "test@user", 
  //    country: "USA", 
  //    contactName: "ryan hunter",
  //    phone: "5083846291",
  //    address: "303 3rd",
  //    city: "cambridge",
  //    state: "MA",
  //    otcFee: 0.1,
  //    postal: '02151',
  //    note: 'some notes'
  //  }
  //}

  async getAll() : Promise<Counterparty[]> {
    this.log('Getting all counterparties')
    const resp = await this.client.get('/counterparties')
    return resp.data.data
  }
}
