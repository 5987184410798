import axios from 'axios'
import * as auth from './Auth'
import { createHashHistory } from 'history'
import config from '../config'

const history = createHashHistory()

const api = axios.create({
  baseURL : config.SMRT_API,
  validateStatus: (status) => true
})

api.interceptors.request.use(config => {
  if (
    config.url === '/authentication/authenticate'
  ) {
    return config
  }

  if (!auth.isLoggedIn()){
    auth.logout().then(() => {
      history.push('/')
    })
  }

  config.headers.Authorization = `Basic ${localStorage.token}`

  return config
}, err => {
  return Promise.Reject(err)
})

export const post = api.post
export const get = api.get
export const del = api.delete

