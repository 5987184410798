import React, { useRef, useEffect, useState } from "react";
import numberFormat from '../../../utils/numberFormat.js'

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
  
const QuoteInfo = (
  {
    quote: {
      side,
      commitmentsId,
      quotePrice,
      taken,
      livePrice,
      prevLive,
      expiryTimestamp,
      isPending,
      partyId,
      baseAmount,
      quoteAmount,
      baseCurrency,
      quoteCurrency
    },
    executeQuote,
  }) => {
  const [expiresInSeconds, setExpiresInSeconds] = useState('')
  useInterval(() => {
    const decayTime = 1e-3
    let expiryLeft = (decayTime * (expiryTimestamp - Date.now())).toFixed(1)
    if(parseFloat(expiryLeft) === decayTime*100) {
      expiryLeft = 0
    }

    setExpiresInSeconds(
      expiryLeft
    )    
  }, !taken && expiryTimestamp > Date.now() && isPending  ? 100 : null);

  return (
    <div className={isPending && expiresInSeconds > 0 ? "block-wrapper" : "block-wrapper disabled"}>
      <div className="block-head">
        <div className="text left"><span className="capitalize">{side}</span>ing {numberFormat.decimals(baseAmount || quoteAmount / quotePrice)} {baseCurrency} {side === "buy" ? "with" : "for"} ${numberFormat.decimals(quotePrice * (baseAmount || quoteAmount / quotePrice))} {quoteCurrency}</div>
        <div className="text right">{(new Date(expiryTimestamp || "")).toLocaleString()}</div>
      </div>
      <div className="block-body">
        <div className="block-container top">
          <div className="block-field">
            <div className="block-field-content">
              <span className="label">You're {side}ing</span>
              <h4>{numberFormat.decimals(baseAmount || quoteAmount / quotePrice)} {baseCurrency}</h4>
            </div>
          </div>
          <div className="block-field">
            <div className="block-field-content">
              <span className="label">Price ({quoteCurrency}) per ({baseCurrency})</span>
              <h4>${numberFormat.decimals(quotePrice)} / <span style={{"fontSize": "16px"}} dangerouslySetInnerHTML={{__html: numberFormat.highlightDiffFont(livePrice, prevLive, null, "$", "")}}></span></h4>
            </div>
          </div>
          <div className="block-field">
            <div className="block-field-content">
              <span className="label">Total cost ({quoteCurrency})</span>
              <h4>${numberFormat.decimals((baseAmount || quoteAmount / quotePrice) * quotePrice)}</h4>
            </div>
          </div>
        </div>
        <div className="block-container bottom">
        { isPending && expiresInSeconds > 0 ?
          <div className="block-timer-wrapper">
            <div className="block-timer-container">
              <div className="block-timer">Quote expires in <b>{expiresInSeconds}s</b></div>
              <div className="block-bar-container">
                <div style={{width: ((expiresInSeconds / 10) * 100) + "%"}} className="block-bar"></div> 
              </div>
            </div>     
            <button
              className="execute"
              onClick={() => executeQuote(partyId)}>
              Accept this quote
            </button> 
          </div>
          : taken ?
          <div className="block-timer-wrapper taken">
            <div className="block-timer-container">
              <div className="block-timer"><span className="success-checkmark"></span><span>Trade processed - ID: {commitmentsId}</span></div>
            </div>
          </div>
          :
          <div className="block-timer-wrapper">
            <div className="block-timer-container">
              <div className="block-timer">Quote expired</div>
            </div>        
          </div>
        }
        </div>      
      </div>
    </div>
  )
}

export default QuoteInfo
