import Counterparties from "./Counterparties.jsx"
import QuoteInfo from "./QuoteInfo.jsx"
import React, { useState } from "react"
import axios from "axios";
import config from "../../../config"

let {symbols} = config

const Index = () => {
  const [quotes, setQuotes] = useState({})

  const requestQuote = ({ side, baseAmount, quoteAmount, baseCurrency, quoteCurrency, partyId, premium }) => {
    let symbolObject = symbols.find(d=> d.base === baseCurrency && d.quote === quoteCurrency) 
    let apiSymbol = symbolObject.apiSymbol    

    const url = [
      "/api/quotes",
      new URLSearchParams({ apiSymbol, side, baseAmount, quoteAmount, baseCurrency, quoteCurrency, partyId, premium }).toString(),
    ].join("?");
    let isOpen = false;
    const sse = new EventSource(url, { withCredentials: true });

    sse.onopen = () => {
      isOpen = true
      console.log("connected")
    };

    sse.onmessage = (d) => {
      const { data: rawData, lastEventId: id } = d
      const { type, data } = JSON.parse(rawData)
      console.log(`received ${type} event`)
      data.isPending = data.status === 'pending'
      if(!data.isPending) {
        sse.close()
      }
      setQuotes(prev => ({...prev, [id]: data}))
    }

    sse.onerror = (e) => {
      if (!isOpen) {
        alert("failed to get quote, check your inputs");
      }
      sse.close()
    };
  };

  const executeQuote = async (id) => {
    try {
      let executed = await axios.get(`/api/quotes/${id}`)
      quotes[executed.data.data.id] = executed.data.data
      setQuotes({...quotes})

    } catch(e) {
      console.error(e)
    }
  }

  return (
    <div className="quotes">
      <Counterparties requestQuote={requestQuote}></Counterparties>
      {Object.entries(quotes).reverse().map(([id, quote]) => (
        <QuoteInfo
          key={id}
          quote={quote}
          executeQuote={() => executeQuote(id)}
        />
      ))}
    </div>
  )
}

export default Index
  