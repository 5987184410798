import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

const FormModal = (props) => {
  const [open, setOpen] = useState(props.open);

  console.log('Rendering Form Modal', props);
  const { FORM, TITLE, SCHEMA } = require(`../Forms/${props.for}`);

  const onSuccess = (resp) => {
    setOpen(false);
    props.for === `Share` ? null : props.onSuccess(resp);
  };

  return (
    <Modal
      closeIcon
      centered
      size="small"
      className="FormModal"
      trigger={props.trigger}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <header>
        {props.for === `Share` ? null : props.data && props.data.id ? 'Update ' : 'Add '}
        {TITLE}
      </header>

      <Modal.Content>
        <Modal.Description>
          <FORM
            name={props.name}
            email={props.email}
            schema={SCHEMA}
            otcTableData={props.otcTableData}
            data={props.data}
            uuid={props.uuid}
            bank={props.bank}
            bankId={props.bankId}
            partyId={props.partyId}
            onSuccess={onSuccess}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default FormModal;
