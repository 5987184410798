import { ClientBase } from '../rest_client';
import { Payment, Commitment, PaginatedResponse } from '../../ServerTypes'
import { roundCurrency } from '../../utils/rounding'

type UICommitment = {
  id: string;
  org: string;
  partyId: string;
  financeAmt: string;
  financeCurrency: string;
  settlementAmt: string;
  settlementCurrency: string;
  transactionDate: string;
  rateRecorded: number;
  transactionType: "Manual Entry" | "API" | "API_TEST"
}

type BasePayment = {
  id: string,
  org: string,
  partyId: string,
  currency: string,
  paymentDate: string,
  note: string,
}

type SettlementPayment = BasePayment & {
  partyAcctId: string,
  settlementAmt: string,
  transactionType: 'Settlement',
  settledTo: string,
  settledFrom: string
}

type FinancePayment = BasePayment & {
  transactionType: 'Financing',
  financeAmt: string,
  financingTo: string,
}

type UIPayment = FinancePayment | SettlementPayment

type CurrencyTotals = {
  currency: string,
  pendingSettlement: number,
  pendingFinancing: number
}

type PartyBalance = {
  party_id: string,
  currency_totals: CurrencyTotals[]
}

type BalanceResponse = {
  byCurrency: CurrencyTotals[],
  byParty: PartyBalance[]
}



export class OtcClient extends ClientBase{

  async createCommit(commit: Omit<UICommitment, 'id'>) {
    let financeAmt = parseFloat(commit.financeAmt)
    let settlementAmt = parseFloat(commit.settlementAmt)

    const newCommit : Omit<Commitment, 'id'> = {
      quote_currency: commit.settlementCurrency,
      base_currency: commit.financeCurrency,
      base_proceeds: financeAmt,
      quote_cost: settlementAmt,
      trade_time: commit.transactionDate,
      transaction_type: "Manual Entry",
      rate: financeAmt > settlementAmt ? financeAmt / settlementAmt : settlementAmt / financeAmt,
      party_id: commit.partyId,
      org: 'PARADOX',
    }
    this.log('Creating CreateCommit request for ', newCommit);
    await this.client.post('/commitments', newCommit)
  }

  async updateCommit(commit: UICommitment) {
    const newCommit : Commitment = {
      id: commit.id,
      quote_currency: commit.settlementCurrency,
      base_currency: commit.financeCurrency,
      base_proceeds: parseFloat(commit.financeAmt),
      quote_cost: parseFloat(commit.settlementAmt),
      trade_time: commit.transactionDate,
      transaction_type: "Manual Entry",
      rate: parseFloat(commit.financeAmt)/parseFloat(commit.settlementAmt), // TODO: this needs to be done both directions
      party_id: commit.partyId,
      org: 'PARADOX',
    }
    this.log("Update commitment: ", newCommit);
    await this.client.put(`/commitments/${newCommit.id}`, newCommit)
  }

  async listAllCommits() : Promise<UICommitment[]> {
    this.log("List all commitments")
    const resp : PaginatedResponse<Commitment> = (await this.client.get('/commitments')).data

    return resp.data.map(commitment => {
      return {
        id: commitment.id,
        org: commitment.org,
        partyId: commitment.party_id,
        financeAmt: commitment.base_proceeds.toString(),
        financeCurrency: commitment.base_currency,
        settlementAmt: commitment.quote_cost.toString(),
        settlementCurrency: commitment.quote_currency,
        transactionDate: commitment.trade_time ?? "",
        rateRecorded: commitment.rate,
        transactionType: commitment.transaction_type,
        otcFee: commitment.otc_fee || 0,
      }
      })
  }

  async deleteCommit(id: string) { 
    this.log('delete commitment: ', id)
    await this.client.delete(`/commitments/${id}`)
  }

  async createPayment(data: Omit<FinancePayment, 'id'>|Omit<SettlementPayment, 'id'>) {
    let newPayment : Omit<Payment, 'id'>

    if(data.transactionType === 'Financing') {
      newPayment = {
        org: 'PARADOX',
        orgAcctId: data.financingTo,
        partyId: data.partyId,
        partyAcctId: "",
        amount: parseFloat(data.financeAmt),
        currency: data.currency,
        paymentDate: data.paymentDate,
        transactionType: data.transactionType,
        note: data.note
      }
    } else {
      newPayment = {
        org: 'PARADOX',
        orgAcctId: data.settledFrom,
        partyId: data.partyId,
        partyAcctId: data.settledTo,
        transactionType: data.transactionType,
        amount: parseFloat(data.settlementAmt),
        currency: data.currency,
        paymentDate: data.paymentDate,
        note: data.note
      }

    }

    this.log('Creating new payment: ', JSON.stringify(newPayment))
    await this.client.post('/payments', newPayment)
  }

  async updatePayment(data: UIPayment) {
    this.log('Creating UpdatePayment request for ', data);
    let newPayment : Payment

    if(data.transactionType === 'Financing') {
      newPayment = {
        id: data.id,
        org: 'PARADOX',
        orgAcctId: data.financingTo,
        partyId: data.partyId,
        partyAcctId: "",
        amount: parseFloat(data.financeAmt),
        currency: data.currency,
        paymentDate: data.paymentDate,
        transactionType: data.transactionType,
        note: data.note
      }
    } else {
      newPayment = {
        id: data.id,
        org: 'PARADOX',
        orgAcctId: data.settledFrom,
        partyId: data.partyId,
        partyAcctId: data.settledTo,
        transactionType: data.transactionType,
        amount: parseFloat(data.settlementAmt),
        currency: data.currency,
        paymentDate: data.paymentDate,
        note: data.note
      }

    }

    await this.client.put(`/payments/${newPayment.id}`, newPayment)
  }

  async getAllPayments() : Promise<UIPayment[]> {
    this.log('get all payments')
    const resp : Payment[] = (await this.client.get('/payments')).data.data
    const payments : UIPayment[] = resp.map(x => {
      const base : BasePayment = {
        id: x.id,
        org: x.org,
        partyId: x.partyId,
        currency: x.currency,
        paymentDate: x.paymentDate,
        note: x.note
      }
      if(x.transactionType === 'Financing') {
        // Financing payment 
        const financingPayment : FinancePayment = {
          transactionType: 'Financing',
          financeAmt: x.amount.toString(),
          financingTo: x.orgAcctId,
          ...base
        }
        return financingPayment
      } else {
        // Settlement payment
        const settlementPayment : SettlementPayment = {
          partyAcctId: x.partyAcctId ?? "", // in financing, party account isnt provided
          transactionType: 'Settlement',
          settlementAmt: x.amount.toString(),
          settledTo: x.partyAcctId ?? "",
          settledFrom: x.orgAcctId,
          ...base
        }
        return settlementPayment
      }
    })

    return payments
  }

  async deletePayment(id: string) {
    this.log("Deleting payment: ", id)
    await this.client.delete(`/payments/${id}`)
  }

  processCurrencyTotals(totals: CurrencyTotals[]) : CurrencyTotals[] {
      const t = totals.map(x => {return {
        currency: x.currency,
        pendingFinancing: roundCurrency(x.pendingFinancing, x.currency),
        pendingSettlement: roundCurrency(x.pendingSettlement, x.currency),
      }}).filter(x => x.pendingFinancing > 0 || x.pendingSettlement > 0)
      return t
  }

  async listBalances() : Promise<BalanceResponse> {
    this.log('Getting balances')
    const resp = await this.client.get('/balances')
    const balances : BalanceResponse = resp.data.data
    const t : BalanceResponse = {
      byCurrency: this.processCurrencyTotals(balances.byCurrency),
      byParty: balances.byParty.map(x => {
        return {
          party_id: x.party_id,
          currency_totals: this.processCurrencyTotals(x.currency_totals)
        }
      }).filter(x => x.currency_totals.length > 0),
    }
    return t
  }
}