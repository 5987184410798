import { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Table,
  Button,
  Dropdown,
  Popup,
  Icon,
  List,
  Confirm,
  Dimmer,
  Loader,
  Modal,
} from 'semantic-ui-react';
import FormModal from '../Utilities/FormModal';
import { REST_CLIENTS_TYPE, createClient } from '../../rest';
import { numberWithCommas } from '../../utils/numberFormat';
import moment from 'moment';
import { isAllowed } from '../Utilities/core';

const Payments = (props) => {
  const [otcPayment, setOtcPayment] = useState();
  const [counterparties, setCounterparties] = useState([]);
  const [banks, setBanks] = useState([]);
  const [allAccts, setAllAccts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dimmerActive, setDimmerActive] = useState(true);
  const [apiAlert, setApiAlert] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [filters, setFilters] = useState({});
  const types = [
    {
      key: 'Financing',
      text: 'Financing',
      value: 'Financing',
    },
    {
      key: 'Settlement',
      text: 'Settlement',
      value: 'Settlement',
    },
  ];

  useEffect(() => {
    async function populateOtcPayments() {
      setDimmerActive(true);
      try {
        const resp = await createClient(REST_CLIENTS_TYPE.Otc).getAllPayments();
        const cpResp = await createClient(REST_CLIENTS_TYPE.Party).getAll();
        const bankResp = await createClient(REST_CLIENTS_TYPE.Bank).getAll();
        const respAllAccounts = await createClient(REST_CLIENTS_TYPE.Acct).getAll();

        console.log('Received trades as ', resp, respAllAccounts);
        setCounterparties(cpResp);
        setBanks(bankResp);
        setAllAccts(respAllAccounts);
        setOtcPayment(resp);
        setDimmerActive(false);
      } catch (e) {
        console.error('Failed to fetch payments', e);
        setApiAlert(e.message);
      }
    }
    populateOtcPayments();
  }, [refetch]);

  const onUpdate = () => {
    setRefetch(refetch + 1);
  };

  const getBankCurrency = (bankId) => {
    if (!banks) return [];
    let bank = banks.find((x) => x.id === bankId);
    return bank?.currency;
  };

  const getAccount = (accountId) => {
    if (!allAccts) return [];
    let account = allAccts.find((x) => x.id === accountId);
    if (account) {
      return account.title + ' ' + (account?.isBankAcct ? getBankCurrency(account.bankId) : account.currency + ' ' + account.accountNumber);
    }
  };

  const handleDeleteEntry = (id) => {
    if (isAllowed()) {
      createClient(REST_CLIENTS_TYPE.Otc)
        .deletePayment(id)
        .then((resp) => {
          onUpdate();
        })
        .catch((error) => {
          console.error(`Failed to delete OTC Payment entry`, error);
          setApiAlert(error);
        })
        .finally(() => setShowModal({ [id]: false }));
    } else {
      setApiAlert('You are not allowed to perform this operation.');
    }
  };

  const showDelete = (id) => setShowModal({ [id]: true });
  const hideDelete = (id) => setShowModal({ [id]: false });

  const getCounterpartyList = () => {
    if (!counterparties || !otcPayment) return [];
    const uniqueParties = [
      ...new Set(otcPayment.map((x) => counterparties.find((cp) => cp.id === x.partyId))),
    ];
    return uniqueParties
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .map((x) => {
        return {
          name: x.name,
          value: x.id,
          text: x.name,
        };
      });
  };

  const filterTable = () => {
    if (!otcPayment) return [];
    return otcPayment.filter((key) => {
      if (!filters.counterparty && !filters.type) return true;
      else if (filters.counterparty && !filters.type && filters.counterparty === key.partyId)
        return true;
      else if (
        !filters.counterparty &&
        filters.type &&
        filters.type === (key.financeAmt ? 'Financing' : 'Settlement')
      )
        return true;
      else
        return (
          filters.counterparty === key.partyId &&
          filters.type === (key.financeAmt ? 'Financing' : 'Settlement')
        );
    });
  };

  const onChange = (e, { name, value }) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <Grid>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error loading data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      {dimmerActive && (
        <Dimmer active={dimmerActive} inverted>
          <Loader content="Loading" />
        </Dimmer>
      )}
      <Grid.Row>
        <Grid.Column>
          <div className="payments">
            <div className="title">OTC Payments</div>
            <div className="controls">
              <FormModal
                for="NewOtcPayment"
                onSuccess={onUpdate}
                trigger={
                  <Button primary floated="right" disabled={!isAllowed()}>
                    New Entry
                  </Button>
                }
              />
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                options={getCounterpartyList()}
                floated="right"
                name="counterparty"
                placeholder="Filter by counterparty"
                value={filters.counterparty}
                onChange={onChange}
              />
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                options={types}
                floated="right"
                name="type"
                placeholder="Filter by transaction type"
                value={filters.type}
                onChange={onChange}
              />
              {/*  <Input className="control" name="endDate" placeholder="End date" type="date" onChange={this.handleDateChange} />
                <Input className="control" name="startDate" placeholder="Start date" type="date" onChange={this.handleDateChange} /> */}
            </div>
            <Table basic singleLine selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Counterparty</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Sent from</Table.HeaderCell>
                  <Table.HeaderCell>Sent to</Table.HeaderCell>
                  <Table.HeaderCell>Notes</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {otcPayment &&
                  filterTable().map((tx, i) => {
                    return (
                      <Fragment key={i}>
                        <Table.Row>
                          <Table.Cell className="counterparty">
                            {counterparties.find((cp) => cp.id === tx.partyId).name}
                          </Table.Cell>
                          <Table.Cell>{tx.financeAmt ? 'Financing' : 'Settlement'}</Table.Cell>
                          <Table.Cell>
                            {tx.financeAmt
                              ? numberWithCommas(tx.financeAmt)
                              : numberWithCommas(tx.settlementAmt)}{' '}
                            {tx.currency}
                          </Table.Cell>
                          <Table.Cell>
                            {tx.settlementAmt ? (
                              <Popup
                                trigger={
                                  <div className="clipText">{getAccount(tx.settledFrom)}</div>
                                }
                                position="bottom center"
                                inverted
                                content={getAccount(tx.settledFrom)}
                              />
                            ) : (
                              '-'
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <div className="clipText">
                                  {tx.financeAmt
                                    ? getAccount(tx.financingTo)
                                    : getAccount(tx.settledTo)}
                                </div>
                              }
                              position="bottom center"
                              inverted
                              content={
                                tx.financeAmt
                                  ? getAccount(tx.financingTo)
                                  : getAccount(tx.settledTo)
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={<div className="clipText">{tx.note}</div>}
                              position="bottom center"
                              inverted
                              content={tx.note}
                            />
                          </Table.Cell>
                          <Table.Cell>{moment(tx.paymentDate).format('MMMM DD, YYYY')}</Table.Cell>
                          <Table.Cell className="transactionID">{tx.id}</Table.Cell>
                          <Table.Cell disabled={!isAllowed()}>
                            <div className="tooltip">
                              <Icon link name="ellipsis horizontal" />
                              <List className="tooltiptext otc">
                                <FormModal
                                  for="NewOtcPayment"
                                  data={{
                                    ...tx,
                                    transactionType: tx.financeAmt ? 'Financing' : 'Settlement',
                                  }}
                                  uuid={tx.id}
                                  onSuccess={onUpdate}
                                  trigger={<List.Item>Edit Details</List.Item>}
                                />
                                <List.Item onClick={() => showDelete(tx.id)}>Delete</List.Item>
                                <Confirm
                                  size="tiny"
                                  className="delete"
                                  open={showModal[tx.id]}
                                  header="Delete this Entry?"
                                  cancelButton="Cancel"
                                  confirmButton="Yes, delete"
                                  content="Are you sure you want to delete this entry? You can not undo this action and it may impact existing OTC or bank records."
                                  onCancel={() => hideDelete(tx.id)}
                                  onConfirm={() => handleDeleteEntry(tx.id)}
                                />
                              </List>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      </Fragment>
                    );
                  })}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Payments;
