import React from 'react';
import { Table } from 'semantic-ui-react';

const colorsPalette = ["#4361D8", "#4D4CAC", "#9698D6", "#C8C9E9", "#FF808B"]

export default class TableView extends React.Component {
  alertLevels = []

  constructor(props) {
    super(props);
    this.state = {
      data:props.data
    }
  }

  generateProgressBar = (progressBarTitle, data) => {
    const progressColumnIndex = data[0].indexOf(progressBarTitle);
    if(!progressColumnIndex || progressColumnIndex < 0) return;
    const progressBar = []
      for(let i = 1; i < data.length; i++) {
        progressBar.push(<div className="color-bar" key={i} style={{background: colorsPalette[i-1] || "#000", width: data[i][progressColumnIndex]+"%"}}>&nbsp;</div>)
      }
    return progressBar;
  }

  generateTableRow = (colIndex, rowVal, alertColumnIndex, highlightTitleIndex, changeColumnIndex) => {
    let rowContent = rowVal || "-"
    if(colIndex === alertColumnIndex) {
      this.alertLevels.push(rowVal)
      rowContent = <span className="alertTag">{rowContent}</span>
    } else if (colIndex === highlightTitleIndex) {
      const firstPart = rowVal.split("/")[0].trim()
      const secondPart = rowVal.split("/")[1].trim()
      rowContent = (<span>
        <span className="red">{firstPart}</span>&nbsp;/&nbsp;
        <span className="green">{secondPart}</span>
      </span>)
    }
    else if(colIndex === changeColumnIndex) {
      
      let plusOrMinus = Math.ceil(Math.random() * 99) * (Math.round(Math.random()) ? 1 : -1)
      let changeClass
      if(plusOrMinus < 0)
        changeClass = "change-negative"
      else
        changeClass = "change-positive"

      rowContent = (<span>{rowContent}<span className={`value ${changeClass}`}>{plusOrMinus}%</span></span>)
    }
    return <td key={colIndex} className={`col-${colIndex}`}>{rowContent}</td>
  }

  render() {
    const {title,progressBarTitle,data,alertColumn,highlightTitle,changeColumn}=this.props
    const tableRows = []
    const progressBar = this.generateProgressBar(progressBarTitle, data)
    const alertColumnIndex = data[0].indexOf(alertColumn)
    const highlightTitleIndex = data[0].indexOf(highlightTitle)
    const changeColumnIndex = data[0].indexOf(changeColumn)


    for(let i = 1; i < data.length; i++) {
      let row=[]
      if(progressBar)  row.push(<td><div style={{background: colorsPalette[i-1] || "#000",width:'15px',height:'15px',borderRadius:'3px'}}>&nbsp;</div></td>) 
      data[i].map((rowVal, colIndex) => row.push(this.generateTableRow(colIndex, rowVal, alertColumnIndex, highlightTitleIndex, changeColumnIndex)))
      tableRows.push(row)
    }

    return (
      <div className={`Dashboard-block ${title==="Account Balances" ? "account-widget" : title==="Pending Settlement" ? "pending-settlement-widget":""}`}>
          <div className={`titleBlock`}>{title}</div>
          {progressBar && <div className="color-bar-wrapper">{progressBar}</div>}
        <Table basic='very' className={`exposures-block-table ${title==="Account Balances" ? "account-widget-table" : title==="Pending Settlement" ? "pending-settlement-widget-table":""}`}>
           { <thead>
              <tr> 
                {progressBar && <th></th>}
                {data[0].map((v, i)=> <th key={i}>{v}</th>)}
              </tr>
            </thead>} 
            <tbody>
              {tableRows.map((v, i) => <tr key={i} className={this.alertLevels[i]}>{v}</tr>)}
            </tbody>
          </Table>
      </div>
   )
  }
}