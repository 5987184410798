import { useState } from 'react';
import { withRouter } from 'react-router';
import { Button, Checkbox, Form, Divider } from 'semantic-ui-react';
import * as auth from '../js/Auth';
import { useHistory } from 'react-router-dom';

const Login = (props) => {
  const history = useHistory();
  const [login, setLogin] = useState({ email: '', password: '', error: false });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await auth.login(login.email, login.password);
      history.push('/');
    } catch (err) {
      if (err) console.log(err);
      setLogin({ email: '', password: '', error: true });
    }
  };

  const handleChange = (name, e) => {
    setLogin({ ...login, [name]: e.target.value, error: false });
  };

  return (
    <div className="LoginWrapper">
      <div className="Login">
        <span className="icon-osf">
          <span className="path1"></span>
          <span className="path2"></span>
        </span>
        <Form className="Login-container" onSubmit={handleSubmit}>
          <Form.Field>
            <label>Email</label>
            <input
              value={login.email}
              onChange={(e) => handleChange('email', e)}
              type="email"
              placeholder="Email"
            />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <input
              value={login.password}
              onChange={(e) => handleChange('password', e)}
              type="password"
              placeholder="Password"
            />
          </Form.Field>
          {login.error ? <p className="Login-error">Incorrect Username or Password</p> : null}
          <Form.Field className="login-checkbox">
            <Checkbox label="Remember me next time" />
          </Form.Field>
          <Button type="submit">Log In</Button>
        </Form>
        <div className="footer">
          <Divider section className="login-divider" />
          Have Questions? <a href="#">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
