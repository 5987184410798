import React, { useState } from 'react';
import { Dropdown, Grid, Table } from 'semantic-ui-react';
import { numberWithCommas } from '../../utils/numberFormat';

const Positions = (props) => {
  const positions = props.data.positions;
  const meta = props.data.meta.positions;
  const [filters, setFilters] = useState({});

  const onChange = (e, { name, value }) => {
    setFilters({ [name]: value });
  };

  const getFilterOptions = (filterBy) => {
    if (!positions) return [];
    const vendors = Array.from(new Set(positions.map((x) => x[filterBy])));
    return vendors.map((x) => {
      return { name: x, value: x, text: x.charAt(0).toUpperCase() + x.slice(1) };
    });
  };

  const getAssets = () => {
    if (!positions) return [];
    const marketSymbols = Array.from(new Set(positions.map((x) => x.market.type)));
    return marketSymbols.map((value, index) => {
      return { name: value, value: value, text: value };
    });
  };

  const getSymbols = () => {
    if (!positions) return [];
    const marketSymbols = Array.from(new Set(positions.map((x) => x.market.symbol)));
    return marketSymbols.map((value, index) => {
      return { name: value, value: value, text: value };
    });
  };

  const tableSearch = () => {
    if (!positions) return [];
    return positions
      .sort((a, b) => a.market.symbol.localeCompare(b.market.symbol))
      .filter((key) => {
        if (!filters.position && !filters.exchanges) return true;
        else if (filters.position && !filters.exchanges && filters.position === key.market.symbol)
          return true;
        else if (!filters.position && filters.exchanges && filters.exchanges === key.vendor)
          return true;
        else return filters.position === key.market.symbol && filters.exchanges === key.vendor;
      });
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div className="positions">
            <div className="title">Positions</div>
            <div className="controls">
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                floated="right"
                name="exchanges"
                placeholder="Filter by Exchange"
                options={getFilterOptions('vendor')}
                value={filters.exchanges}
                onChange={onChange}
              />
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                floated="right"
                name="asset"
                placeholder="Market type: All"
                options={getAssets()}
                value={filters.asset}
                onChange={onChange}
              />
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                floated="right"
                name="position"
                placeholder="Position type: All"
                options={getSymbols()}
                value={filters.position}
                onChange={onChange}
              />
            </div>
            <Table basic singleLine fixed selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Position</Table.HeaderCell>
                  <Table.HeaderCell>Exchange</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                  <Table.HeaderCell>Liquidation</Table.HeaderCell>
                  <Table.HeaderCell>UPNL</Table.HeaderCell>
                  <Table.HeaderCell>Avg Price</Table.HeaderCell>
                  <Table.HeaderCell>Health</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {positions &&
                  tableSearch().map((key, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{key.market.symbol}</Table.Cell>
                        <Table.Cell>
                          {key.vendor.charAt(0).toUpperCase() + key.vendor.slice(1)}
                        </Table.Cell>
                        <Table.Cell>{numberWithCommas(key.normalized_amount)}</Table.Cell>
                        <Table.Cell>{key.price}</Table.Cell>
                        <Table.Cell>
                          {key.liquidation_price ? numberWithCommas(key.liquidation_price) : '-'}
                        </Table.Cell>
                        <Table.Cell>
                          {key.unrealized_pnl ? numberWithCommas(key.unrealized_pnl) : '-'}
                        </Table.Cell>
                        <Table.Cell>{numberWithCommas(key.average_price)}</Table.Cell>
                        <Table.Cell>{meta[key.vendor].age_seconds + ' secs'}</Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Positions;
