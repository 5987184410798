import React from 'react';
import {timeParse} from 'd3-time-format'
import {scaleTime, scaleLinear} from 'd3-scale'
import {line, curveMonotoneX} from 'd3-shape'
import {select} from 'd3-selection'
import {max, extent} from 'd3-array'
import {axisBottom, axisLeft} from 'd3-axis'


var margin = {top: 20, right: 17, bottom: 40, left: 17},
    width = 430 - margin.left - margin.right,
    height = 300 - margin.top - margin.bottom;

// parse the date / time
var parseTime = timeParse("%s");

// set the ranges
var x = scaleTime().range([0, width]);
var y = scaleLinear().range([height, 0]);

// define the line
var valueline = line()
    .x(d => x(d.date))
    .y(d => y(d.value))
    .curve(curveMonotoneX)

var yGrid = () => axisLeft(y).ticks(5)

export default class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { "crypto_exposure": {}, "cash_exposure": {}}
    setTimeout(()=>this.makeFakeData(), 100)
  }

  makeFakeData = () => {
    var data = {
      "crypto_exposure": { "data": [] },
      "cash_exposure": { "data": [] }
     }

    for(var i = 0; i < 7; i++) {
      var date = new Date().getTime() + i*1000000
      data.crypto_exposure.data.push({
        date: date,
        value: (Math.random() * 100).toFixed(2)
      })

      data.cash_exposure.data.push({
        date: date,
        value: (Math.random() * 100).toFixed(2)
      })
    }
    this.setState({...data})
    setTimeout(this.generateGraph(), 500)
  }

  generateGraph = () => {
    let el = this.refs.graphWrapper
    var data = this.state
    var leftSpace =  margin.left + 40
    var svg = select(el).append("svg")
      .attr("height", height + margin.top + margin.bottom)
    .append("g")
      .attr("transform",
            "translate(" + leftSpace + "," + margin.top + ")");

    let allValues = []
    Object.keys(data).forEach(key => {
      data[key].data.forEach((d) => {
        d.date = parseTime(d.date)
        d.value = +d.value
      })
      allValues = allValues.concat(data[key].data)
    })

    // Scale the range of the data
    let maxValue = max(allValues, d => d.value)

    x.domain(extent(data[Object.keys(data)[0]].data, d =>  d.date))
    y.domain([0, maxValue])


    // add the Y gridlines
    svg.append("g")
    .attr("class", "grid")
    .call(yGrid()
        .tickSize(-width)
        .tickFormat("")
    )

    // Add the x Axis
    svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(axisBottom(x));

    // Add the y Axis
    svg.append("g")
        .call(axisLeft(y));

    var legendEl = this.refs.legend
      , legend = select(legendEl)

    Object.keys(data).forEach((key,i) => {
      svg.append("path")
        .data([data[key].data])
        .attr("class", `line${i}`)
        .attr("d", valueline)

      svg.selectAll(`.dot${i}`)
        .data(data[key].data)
      .enter().append("circle") // Uses the enter().append() method
        .attr("class", `dot${i}`) // Assign a class for styling
        .attr("cx", d => x(d.date))
        .attr("cy", d => y(d.value))
        .attr("r", 4)

        var legendItem = legend.select("tr:first-child").append("th")
        legendItem.append("span")
          .attr("class", `marker${i}`)
        legendItem.append("span")
          .attr("class", "label")
          .text(key.replace(/_/g, ' '))

        var dataLength = data[key].data.length
          , lastValue = data[key].data[dataLength - 1].value
          , lastChg = lastValue - data[key].data[dataLength - 2].value

        var legendValue = legend.select("tr:last-child").append("td")
        legendValue.append("span")
          .attr("class", "value")
          .text("$"+lastValue.toFixed(2))

        legendValue.append("span")
          .attr("class", `chg ${lastChg > 0 ? "positive" : "negative"}`)
          .text(lastChg.toFixed(2))
      })
  }

  componentDidMount = () => {}

  render () {
    return (
      <div className="LineChart exposure-block">
        <div className="legend">
          <table cellSpacing="0" cellPadding="0">
            <tbody ref="legend">
              <tr></tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
        <div ref="graphWrapper"></div>
      </div>
    );
  }
}
