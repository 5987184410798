import React,  { useState } from 'react'
import { Icon, Table, Message } from 'semantic-ui-react'
import { decimals } from '../../utils/numberFormat.js'
import shortid from 'shortid'
import { ForexService } from '../../utils/ForexService';

const getValue = (x, k) => x && x[k]? x[k].value: undefined

const initRow = () => ({
  'spot': 0, 'margin': 0, 'derivative': 0, 'sources': [],
  ...(['upnl', 'cvar99', 'price', 'interest', 'percent'].reduce((acc,k) =>{ acc[k] = '-'; return acc;}, {}))
});

const PerAssetExposure = (props) => {
  const [rowExpanded, setRowExpanded] = useState("none");

  const byVendor = props.byVendor;
  const exposure = props.exposure;
  let agg = {};
  let issues = [];
  let frxSvc;

  if (exposure) {
    const data = (exposure.positions || []).concat(exposure.wallets || []);
    frxSvc = ForexService.loadFromExposures(data);
    // NOTE: If you want to expand filter query do here like x.label = YourAccountLabel
    data.filter(x => x && (!byVendor || x.vendor === byVendor)).reduce((acc, v) => {
      const exposure = v.exposure;
      const type = v.vendor_wallet;

      if (typeof exposure === 'undefined') {
        issues.push(`${v.vendor}:${v.vendor_symbol} ${type} amount: ${getValue(v, 'amount')}, pnl: ${getValue(v, 'unrealized_pnl')}, avgPrice: ${getValue(v, 'average_price')}, liquidPrice: ${getValue(v, 'liquidation_price')}`);
        return acc;
      }

      for(const i of exposure) {
        if (typeof acc[i.unit.alias] === 'undefined') { acc[i.unit.alias] = initRow() }
        acc[i.unit.alias][type] += i.value / (i.unit.alias_multiplier || 1);
        acc[i.unit.alias].sources.push(v);
        const usdRate = frxSvc.evaluateUsdNotional(i.unit.alias);
        if (i.value !== 0 && !usdRate) issues.push(`Usd Price ticker missing for ${i.unit.alias}`);
        acc[i.unit.alias].price = usdRate;
        acc[i.unit.alias].type = i.unit.type
      }
      return acc;
    }, agg);
  }

  const expandRow = (row) => {
    let rowState = false
    if(rowExpanded === row) {
      rowState = false
    } else {
      rowState = row
    }
    setRowExpanded(rowState)
  }

  const filter = (r, byVendor) => {
    const isEmpty = r.spot !== 0 || r.margin !== 0 || r.derivative !== 0;
    return typeof byVendor !== 'undefined'? true : isEmpty;
  }


  let cryptoTotalExposure = 0
  let commodityExposure = 0
  let fxExposure = 0
  let totalExposure = 0
  if(Object.keys(agg).length) {
    Object.keys(agg).filter(k => filter(agg[k], byVendor)).forEach(k => {
      let assetExposure = (agg[k].spot + agg[k].margin + agg[k].derivative) * agg[k].price
      if(assetExposure) {
        totalExposure += assetExposure

        if(agg[k].type === "cryptocurrency") {
          cryptoTotalExposure += assetExposure
        }
        if(k === "XAUT") {
          commodityExposure += assetExposure
        }
        if(k === "CAD" || k === "EUR" || k === "GBP") {
          fxExposure += assetExposure
        }
      }
    }) 
  }

  return (
    <div className="exposure-asset-table">
      {issues.length > 0 && <Message
        error
        header='Following market/currencies were missing and needs to be defined first.'
        list={issues}
      />}
      <div className="top-info-wrapper">
        <div className="top-info-block">
          <div className="label">NAV</div>
          <div className="value">${decimals(totalExposure)}</div>
        </div>
        <div className="top-info-block">
          <div className="label">Crypto</div>
          <div className="value">${decimals(cryptoTotalExposure)}</div>
        </div>
        <div className="top-info-block">
          <div className="label">Precious Metals</div>
          <div className="value">${decimals(commodityExposure)}</div>  
        </div>
        <div className="top-info-block">
          <div className="label">FX</div>
          <div className="value">${decimals(fxExposure)}</div>
        </div>
      </div>
      <div className="title">Per Assets Exposure</div>
        <Table basic singleLine selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Asset</Table.HeaderCell>
                <Table.HeaderCell>Spot</Table.HeaderCell>
                <Table.HeaderCell>Positions</Table.HeaderCell>
                <Table.HeaderCell>Borrow</Table.HeaderCell>
                <Table.HeaderCell>Lend</Table.HeaderCell>
                <Table.HeaderCell>UPNL</Table.HeaderCell>
                <Table.HeaderCell>Unsettled Interest</Table.HeaderCell>
                <Table.HeaderCell>Price (USD)</Table.HeaderCell>
                <Table.HeaderCell>Percent of Portfolio</Table.HeaderCell>
                <Table.HeaderCell>Aggregate (In kind)</Table.HeaderCell>
                <Table.HeaderCell>Aggregate (USD)</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          
            {agg && Object.keys(agg).sort((a,b) => {
              return ((agg[b].spot + agg[b].margin + agg[b].derivative) * agg[b].price) - ((agg[a].spot + agg[a].margin + agg[a].derivative) * agg[a].price)
            }).filter(k => filter(agg[k], byVendor)).map((k)=>{
              const expDetails = (agg[k].sources) ? agg[k].sources.filter(d=> (d.vendor === byVendor || !byVendor) && d.amount.value): [];

              return(
              <Table.Body key={k}>
                <Table.Row onClick={() => expandRow(k)}>
                  <Table.Cell>{ k }</Table.Cell>
                  <Table.Cell>{ decimals(agg[k].spot) || "-" }</Table.Cell>
                  <Table.Cell>{ decimals(agg[k].margin) || "-" }</Table.Cell>
                  <Table.Cell>{ agg[k].borrow || "-" }</Table.Cell>
                  <Table.Cell>{ agg[k].lend || "-" }</Table.Cell>
                  <Table.Cell>{ agg[k].upnl || "-" }</Table.Cell>
                  <Table.Cell>{ agg[k].interest || "-" }</Table.Cell>
                  <Table.Cell>${ decimals(agg[k].price) || "-" }</Table.Cell>
                  <Table.Cell>{ agg[k].percent || "-" }</Table.Cell>
                  <Table.Cell>{ decimals(agg[k].spot +  agg[k].margin) || "-"}</Table.Cell>
                  <Table.Cell className="boldText">${`${decimals((agg[k].spot + agg[k].margin + agg[k].derivative) * agg[k].price) || "-"}`}</Table.Cell>
                  <Table.Cell>
                    <Icon className="icon-arrow-right" />
                  </Table.Cell>
                </Table.Row>
                <Table.Row className={rowExpanded === k ? "expandedRow header" : "expandedRow hide"}>
                  <Table.Cell>Vendor</Table.Cell>
                  <Table.Cell>Account</Table.Cell>
                  <Table.Cell>Type</Table.Cell>
                  <Table.Cell>Vendor Symbol</Table.Cell>
                  <Table.Cell>Amount</Table.Cell>
                  <Table.Cell>Avg Price</Table.Cell>
                  <Table.Cell>Liquidiation Price</Table.Cell>
                  <Table.Cell>UPNL</Table.Cell>
                  <Table.Cell>Exposure Price</Table.Cell>
                  <Table.Cell>Borrow Cost</Table.Cell>
                  <Table.Cell>Percent of Portfolio</Table.Cell>
                  <Table.Cell>Notional (USD)</Table.Cell>
                </Table.Row>
                  {
                    expDetails.map(d => {
                        const exposure = d.exposure.find(x => x.unit.alias === k);
                        const index = d.exposure.filter(x => x.value === k);
                        return (
                          <Table.Row key={shortid.generate()} className={rowExpanded === k ? "expandedRow" : "expandedRow hide"}>
                            <Table.Cell>{d.vendor}</Table.Cell>
                            <Table.Cell>{index}</Table.Cell>
                            <Table.Cell>{d.source_type}</Table.Cell>
                            <Table.Cell>{d.vendor_symbol}</Table.Cell>
                            <Table.Cell>{`${decimals(exposure.value)} ${exposure.unit.vendor_symbol}`}</Table.Cell>
                            <Table.Cell>{d.average_price ? decimals(d.average_price.value) : "-"}</Table.Cell>
                            <Table.Cell>{d.liquidation_price ? decimals(d.liquidation_price.value) : "-"}</Table.Cell>
                            <Table.Cell>{d.unrealized_pnl && d.unrealized_pnl.unit ? decimals(d.unrealized_pnl.value) + " " + d.unrealized_pnl.unit.alias : "-"}</Table.Cell>
                            <Table.Cell>{d.exposure_tick && d.exposure_tick.tick? decimals(d.exposure_tick.tick.price): '-'}</Table.Cell>
                            <Table.Cell>-</Table.Cell>
                            <Table.Cell>-</Table.Cell>
                            <Table.Cell>{decimals(frxSvc.evaluateUsdNotional(exposure.unit.alias, exposure.value / (exposure.unit.alias_multiplier || 1), d.vendor))}</Table.Cell>
                          </Table.Row>
                        )
                      })
                  }
              </Table.Body>
            )})}
      </Table>
    </div>
  )
}

export default PerAssetExposure