import { BankClient } from './clients/bank';
import { CounterpartyClient } from './clients/counterparty';
import { IntermediaryClient } from './clients/intermediary';
import { AccountClient  } from './clients/account';
import { OtcClient } from './clients/otc';

export const REST_CLIENTS_TYPE = {
  Bank: 'Bank',
  Otc: 'Otc',
  Party: 'Party',
  Acct: 'Acct',
  Intermediary: 'Intermediary',
};

export const restApiMap = {
  [REST_CLIENTS_TYPE.Bank]: BankClient,
  [REST_CLIENTS_TYPE.Otc]: OtcClient, 
  [REST_CLIENTS_TYPE.Intermediary]: IntermediaryClient,
  [REST_CLIENTS_TYPE.Party]: CounterpartyClient,
  [REST_CLIENTS_TYPE.Acct]: AccountClient, 
};

export function createClient(restClientType) {
  console.log(restApiMap);
  if (!(restClientType in restApiMap))
    throw new Error('Unsupported Client Requested ' + restClientType + ' ' + restApiMap);
  return new restApiMap[restClientType]();
}

export {
  BankClient,
  CounterpartyClient,
  IntermediaryClient,
  AccountClient,
  OtcClient,
}