import {ClientBase} from '../rest_client';
import { ACCOUNT_TYPE } from '../../const';

type Account = {
  id: string;
  isBankAcct: boolean;
  accountType:  'wire' | 'wallet' | 'intermediary';
  currency: string;
  title: string;
  name: string; // Name on account
  partyId?: string; 
  ownerOrg?: 'PARADOX' | 'UNKNOWN_ORGANIZATION'; 
  //Only set one of owner party id or owner org
  accountNumber: string;
  address: string;
  bankId: string;
  branch: string;
  city: string;
  postal: string; // postal code
  routing: string; // bank routing number
  swift: string;
  iban: string;
  beneficiary: string;
  clearingCode: string;
  memo: string;
  country: string;
}

export class AccountClient extends ClientBase {

  async create(account: Omit<Account, 'id'>) {

    if (!account.partyId) {
      account.ownerOrg= 'PARADOX'
    }

    account.isBankAcct = account.accountType === ACCOUNT_TYPE.WIRE;

    this.log('Create new account:', account)
    await this.request('POST', '/accounts', account)
  }

  async update(account: Account) { 
    this.log('Updating account: ', account.id)
    await this.request('PUT', `/accounts/${account.id}`, account)
  }

  async delete(account_id: string) {
    this.log('deleting account: ', account_id)
    await this.request('DELETE', `/accounts/${account_id}`)
  }
  // Original method sig {organization: string, partyId: string} = {}
  async get(query: {organization?: number, partyId?: string}) : Promise<Account[]> {
    this.log('Making List Request for: ', query)
    return (await this.getAll()).filter(x => { 
      if(('organization' in query && x.ownerOrg === query.organization) ||
      ('partyId' in query && x.partyId === query.partyId)) {
        return x
      }
    })
  }
  async getAll() : Promise<Account[]> {
    const account_list = await this.request('GET', '/accounts')
    return account_list.data.data
  }
}