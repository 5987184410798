export default {
  "API_URI": process.env.REACT_APP_API_URI || 'https://backend-api-sandbox.osfmanagement.com',
  "API_VERSION": process.env.REACT_APP_API_VERSION || 'v1',
  "SMRT_API": process.env.REACT_APP_SMRT_API,
  "ACCT_BE_URL": process.env.REACT_APP_ACCT_BE_URL || 'http://localhost:50051',
  "EXPOSURE_API": process.env.REACT_APP_EXPOSURE_API || 'https://ebeczsw7ab.execute-api.eu-central-1.amazonaws.com/dev',
  symbols: [
    {
      "apiSymbol": 'BTC/USD',
      "base": "BTC",
      "quote": "USD",
      "minBase": 0.0001,
      "minQuote": 25,
      "type": "spot"
    },
    {
      "apiSymbol": 'BTC/USDT',
      "base": "BTC",
      "quote": "USDT",
      "minBase": 0.0001,
      "minQuote": 25,
      "type": "spot"
    },
    {
      "apiSymbol": 'BTC/USD',
      "base": "BTC",
      "quote": "CAD",
      "minBase": 0.0001,
      "minQuote": 25,
      "type": "spot"
    },
    {
      "apiSymbol": 'ETH/USD',
      "base": "ETH",
      "quote": "USD",
      "minBase": 0.001,
      "minQuote": 25,
      "type": "spot"
    },
    {
      "apiSymbol": 'ETH/USDT',
      "base": "ETH",
      "quote": "USDT",
      "minBase": 0.001,
      "minQuote": 25,
      "type": "spot"
    },
    {
      "apiSymbol": 'ETH/USD',
      "base": "ETH",
      "quote": "CAD",
      "minBase": 0.001,
      "minQuote": 25,
      "type": "spot"
    },
    {
      "apiSymbol": 'ETH/BTC',
      "base": "ETH",
      "quote": "BTC",
      "minBase": 0.001,
      "minQuote": 0.0001,
      "type": "spot"
    },
    {
      "apiSymbol": 'BSV-PERP',
      "base": "BSV",
      "quote": "USD",
      "minBase": 0.01,
      "minQuote": 25,
      "type": "perp"
    }
  ]
};
