import config from '../../config';
import axios from 'axios';

export const BASE_URI = `${config.API_URI}/dashboard/${config.API_VERSION}`;
export const ROUTES = {
  ASSETS_HOLDERS: `${BASE_URI}/banks`,
  COUNTERPARTIES: `${BASE_URI}/users`,
  WIRES: `${BASE_URI}/users/wires`,
  WALLETS: `${BASE_URI}/users/wallets`,
  ASSETS_HOLDERS_ACCOUNTS: `${BASE_URI}/assetsHolders/accounts`,
  ASSETS_HOLDERS_ACCOUNTS_OTC: `${BASE_URI}/assetsHolders/accounts/otcTransaction`,
  BANKS: `${BASE_URI}/banks`,
  TRADES: `${BASE_URI}/trades`,
  EXPOSURES: `http://ilb.dev.osfmanagement.com/risk/v1/paradox/exposures/`,
  RISKPROFILE: `http://ilb.dev.osfmanagement.com/risk/v1/paradox/profile/`,
  POSITIONS: `http://ilb.dev.osfmanagement.com/risk/v1/paradox/positions/`,
  EXCHANGES: 'https://apigw.dev.osfmanagement.com/accounts/v1/paradox/wallets',
  POSITIONSPAGE: 'https://apigw.dev.osfmanagement.com/accounts/v1/paradox/positions',
};

export const publicPost = (path, data) => {
  return axios.post(path, data);
};

export const publicPatch = (path, data) => {
  return axios.patch(path, data);
};

export const publicGet = (path) => {
  return axios.get(path);
};

export const publicDelete = (path, data) => {
  return axios.delete(path, data);
};

export const privateGet = (path, headers) => {
  return axios.get(path, headers);
};

export const signedPost = () => {};

export const signedGet = () => {};
