import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Grid, Icon, Table } from 'semantic-ui-react';
import { ICONS } from '../Utilities/forex';
import { publicGet } from '../Utilities/restApi';

const AccountBalances = (props) => {
  console.log(`props on account balances page`, props);
  const vendor = props.match.params.account;
  const [rate, setRate] = useState([]);
  const wallets = props.exchangeList.wallets.filter((x) => x.vendor === vendor);
  const symbols = Array.from(new Set(wallets.map((x) => x.currency.symbol)));
  const walletTypes = Array.from(new Set(wallets.map((x) => x.vendor_wallet)));

  //{"FTX#margin#USD#label": x.amount }
  // const walletsById = Array.from(new Set(wallets.map((x) => x.vendor_wallet)));
  // wallets.map(x=>{
  //   return {
  //     [`${x.vendor}#${x.vendor_wallet}#${x.vendor}`]
  //   }
  // })

  let uniqueWallets = symbols.map((c) => {
    let totalInKind = 0;
    let obj = wallets
      .filter((x) => x.currency.symbol === c)
      .map((x) => {
        totalInKind = totalInKind + x.amount.value;
        return {
          [`${x.vendor_wallet}`]: x.amount.value,
        };
      });
    obj = Object.assign({ total: totalInKind }, ...obj);
    if (totalInKind === 0) return;
    return {
      [c]: obj,
    };
  });
  uniqueWallets = Object.assign({}, ...uniqueWallets);
  console.log('unique wallets', uniqueWallets);

  useEffect(() => {
    async function convertToUSD() {
      try {
        const apiUrl =
          'https://api-staging.osfmanagement.com/api-pub/v3/tickers/rates?base=' +
          symbols.join() +
          '&counter=USD';
        const resp = await publicGet(apiUrl);
        console.log(`response conversion`, resp);
        setRate(resp.data.data);
      } catch (e) {
        console.error(`Failed to fetch rate`, e);
      }
    }
    convertToUSD();
  }, [wallets]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div className="balances">
            <div className="title">{vendor.charAt(0).toUpperCase() + vendor.slice(1)}</div>
            <div className="controls">
              {/*<Dropdown selection floated="right" placeholder="Sort by: Total USD" />*/}
            </div>
            <Table basic singleLine selectable fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Symbol</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  {walletTypes &&
                    walletTypes.map((type, index) => {
                      return (
                        <Table.HeaderCell key={index} className="capitalize">
                          {type}
                        </Table.HeaderCell>
                      );
                    })}
                  <Table.HeaderCell>Total in kind</Table.HeaderCell>
                  <Table.HeaderCell>Total USD</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {uniqueWallets &&
                  Object.keys(uniqueWallets)
                    .sort((a, b) => a.localeCompare(b))
                    .map((symbol, i) => {
                      let convert = 1;
                      if (rate.find((c) => c.base === symbol) !== undefined) {
                        convert = rate.find((c) => c.base === symbol).price;
                      }
                      return (
                        <Fragment key={i}>
                          <Table.Row>
                            <Table.Cell className="symbol">
                              <Icon
                                className={
                                  ICONS.find((icon) => icon === symbol)
                                    ? `icon-${symbol}`
                                    : `icon-default`
                                }
                              >
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                                <span className="path6"></span>
                                <span className="path7"></span>
                              </Icon>
                              {symbol}
                            </Table.Cell>
                            <Table.Cell>{symbol}</Table.Cell>
                            {walletTypes &&
                              walletTypes.map((type, index) => {
                                return (
                                  <Table.Cell key={index}>{uniqueWallets[symbol][type]}</Table.Cell>
                                );
                              })}
                            <Table.Cell>{uniqueWallets[symbol].total}</Table.Cell>
                            <Table.Cell className="boldText">
                              ${rate ? uniqueWallets[symbol].total * convert : ''}
                            </Table.Cell>
                            <Table.Cell>
                              {/*<Link to={`${vendor}/${symbol}`}>*/}
                              {/*  <Icon className="icon-arrow-right" />*/}
                              {/*</Link>*/}
                            </Table.Cell>
                          </Table.Row>
                        </Fragment>
                      );
                    })}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AccountBalances;
