import React, { useState, useEffect, Fragment } from 'react';
import { Button, Dimmer, Grid, Loader, Modal } from 'semantic-ui-react';
import AccountDetails from './Counterparties/AccountDetails';
import { ACCOUNT_TYPE } from "../const";
import FormModal from './Utilities/FormModal';
import { REST_CLIENTS_TYPE, createClient} from '../rest';

const Wallets = () => {
  const [accounts, setAccounts] = useState([]);
  const [refetch, setRefetch] = useState(0);
  const [dimmerActive, setDimmerActive] = useState(true);
  const [apiAlert, setApiAlert] = useState(false);

  useEffect(() => {
    setDimmerActive(true);
    async function populateWallets() {
      try {
        const accounts = await createClient(REST_CLIENTS_TYPE.Acct).get({
          organization: "PARADOX"
        });
        console.log(`Received counterparties as `, accounts);
        setAccounts(accounts.filter((x) => !x.isBankAcct));
        setDimmerActive(false);
      } catch (e) {
        console.error(`Failed to fetch counterparties`, e);
        setApiAlert(e.message);
      }
    }
    populateWallets();
  }, [refetch]);

  const onUpdate = () => {
    setRefetch(refetch + 1);
  };

  return (
    <Grid stackable className="wallets">
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error Loading Wallets</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Row>
        <Grid.Column as="header" width={15}>
          <FormModal
            onSuccess={onUpdate}
            for="NewWallet"
            uuid={accounts.id}
            trigger={
              <Button primary floated="right">
                Add Wallet
              </Button>
            }
          />
          <div className="title">Wallets</div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {dimmerActive ? (
          <Dimmer active={dimmerActive} inverted>
            <Loader content="Loading" />
          </Dimmer>
        ) : (
          accounts &&
          accounts.map((w, i) => (
            <Grid.Column computer={15} largeScreen={7} widescreen={5} key={i}>
              <AccountDetails
                title={`${w.name} - ${w.title} ${w.currency} Wallet`}
                memo={w.memo}
                data={w}
                for={ACCOUNT_TYPE.WALLET}
                onUpdate={onUpdate}
              />
            </Grid.Column>
          ))
        )}
      </Grid.Row>
    </Grid>
  );
};

export default Wallets;
