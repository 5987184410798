import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import Balances from './Balances';
import Commitments from './Commitments';
import Payments from './Payments';
import TradeIndex from './Trade/Index.jsx';

const OtcIndex = (props) => {
  return (
    <div className="otc-index">
      <div className="tabs">
        <NavLink exact activeClassName="active" className="tab" to={`${props.match.path}/`}>
          Balances
        </NavLink>
        <NavLink activeClassName="active" className="tab" to={`${props.match.path}/commitments`}>
          Commitments
        </NavLink>
        <NavLink activeClassName="active" className="tab" to={`${props.match.path}/payments`}>
          Payments
        </NavLink>
        <NavLink activeClassName="active" className="tab" to={`${props.match.path}/trade`}>
          Trade
        </NavLink>
      </div>
      <div className="otc-tab">
        <Switch>
          <Route exact path={`${props.match.path}/`} component={Balances} />
          <Route path={`${props.match.path}/commitments`} component={Commitments} />
          <Route path={`${props.match.path}/payments`} component={Payments} />
          <Route path={`${props.match.path}/trade`} component={TradeIndex} />
        </Switch>
      </div>
    </div>
  );
};

export default OtcIndex;
