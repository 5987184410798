import { ClientBase } from "../rest_client";

type Intermediary = {
  id: string;
  bank_id: string;
  name: string;
  currency: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  institution: string;
  routing: number;
  swift: string;
  iban: string;
}

export class IntermediaryClient extends ClientBase {

  async create(intermediary: Omit<Intermediary, 'id'>) {
    this.log("create new intermediary", intermediary);
    await this.client.post('/intermediaries', intermediary)
  }

  async update(intermediary: Intermediary) {
    this.log("update intermediary", intermediary);
    await this.client.put(`/intermediaries/${intermediary.id}`, intermediary)
  }

  async delete(intermediary_id: string) {
    this.log("Delete intermediary: ", intermediary_id)
    await this.client.delete(`/intermediaries/${intermediary_id}`)
  }

  //No get intermediary by ID in original api. Only get intermediaries for bank
  async get(bankId: string): Promise<Intermediary[]> {
    this.log('Get intermediaries for bank: ', bankId)
    const resp = await this.client.get('/intermediaries')
    return resp.data.data.filter((x : { bankId: string }) => {
      if(x.bankId === bankId) {
        return x
      }
    })
  }
}
