import { Currency } from '../../grpc/common_pb';

const CURRENCIES = Object.keys(Currency)
  .reverse()
  .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  .map((num) => ({ key: num, value: num, text: num }));

const CRYPTO_CURRENCIES = Object.keys(Currency)
  .filter((x) => Currency[x] > 99)
  .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  .map((num) => ({ key: num, value: num, text: num }));

const FOREX_CURRENCIES = Object.keys(Currency)
  .filter((x) => Currency[x] > 0 && Currency[x] < 100)
  .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  .map((num) => ({ key: num, value: num, text: num }));

const FOREX_CURRENCIES_LIST = Object.keys(Currency)
  .filter((x) => Currency[x] > 0 && Currency[x] < 100)
  .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

const TRANSACTION_TYPE = [
  { key: 'Financing', text: 'Financing', value: 'Financing' },
  { key: 'Settlement', text: 'Settlement', value: 'Settlement' },
];

const EXCHANGES = [
  { key: 'Bitfinex', text: 'Bitfinex', value: 'Bitfinex' },
  { key: 'Ftx', text: 'Ftx', value: 'Ftx' },
  { key: 'Bitmex', text: 'Bitmex', value: 'Bitmex' },
  { key: 'Other', text: 'Other', value: 'Other' },
];

const ICONS = [
  'ADC',
  'AUR',
  'ADA',
  'BCH',
  'BCC',
  'BSV',
  'BTC',
  'BTG',
  'BTS',
  'CAD',
  'CHF',
  'DASH',
  'DCR',
  'default',
  'DOGE',
  'DOT',
  'DVC',
  'EOS',
  'ERC',
  'ETC',
  'ETH',
  'EUR',
  'FLASH',
  'FTT',
  'GAME',
  'GBP',
  'GEMZ',
  'GLM',
  'GNOSIS',
  'ICN',
  'IOTA',
  'JBS',
  'JPY',
  'KMD',
  'LEO',
  'LISK',
  'LTC',
  'MAID',
  'NEO',
  'NMC',
  'NXT',
  'OMG',
  'OMNI',
  'PIVX',
  'POT',
  'PPC',
  'RBIES',
  'REP',
  'RISE',
  'SNRG',
  'STEEM',
  'Stellar',
  'STORJ',
  'STRAT',
  'USD',
  'USDC',
  'USDT',
  'UST',
  'VTC',
  'WAVES',
  'XAUT',
  'XEM',
  'XLM',
  'XMR',
  'XPM',
  'XRP',
  'XTZ',
  'XVG',
  'ZEC',
];

const country_list = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bangladesh',
  'Barbados',
  'Bahamas',
  'Bahrain',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burma',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo-Brazzaville',
  'Congo-Kinshasa',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Curaçao',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'El Salvador',
  'Egypt',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Federated States of Micronesia',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Lands',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard and McDonald Islands',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Islands',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Réunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Barthélemy',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'Saint Vincent',
  'Samoa',
  'San Marino',
  'São Tomé and Príncipe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Swaziland',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Vietnam',
  'Venezuela',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

const COUNTRIES = [];
for (let i = 0; i < country_list.length; i++) {
  COUNTRIES.push({
    key: country_list[i],
    value: country_list[i],
    text: country_list[i],
  });
}

const curSymbols = [
  { code: 'AUD', symbol: '$' },
  { code: 'BSD', symbol: '$' },
  { code: 'CHF', symbol: '₣' },
  { code: 'CRC', symbol: '₡' },
  { code: 'EUR', symbol: '€' },
  { code: 'GBP', symbol: '£' },
  { code: 'ILS', symbol: '₪' },
  { code: 'INR', symbol: '₹' },
  { code: 'JPY', symbol: '¥' },
  { code: 'KRW', symbol: '₩' },
  { code: 'NGN', symbol: '₦' },
  { code: 'PHP', symbol: '₱' },
  { code: 'PLN', symbol: 'zł' },
  { code: 'PYG', symbol: '₲' },
  { code: 'THB', symbol: '฿' },
  { code: 'UAH', symbol: '₴' },
  { code: 'USD', symbol: '$' },
  { code: 'CAD', symbol: '$' },
  { code: 'VND', symbol: '₫' },
];

const getSymbol = (currency) => {
  return curSymbols.find((x) => x.code === currency)
    ? curSymbols.find((x) => x.code === currency).symbol
    : '';
};

export {
  FOREX_CURRENCIES,
  COUNTRIES,
  CRYPTO_CURRENCIES,
  CURRENCIES,
  FOREX_CURRENCIES_LIST,
  TRANSACTION_TYPE,
  EXCHANGES,
  ICONS,
  getSymbol,
};
