import React from 'react';
import moment from 'moment'
import {uuidv4} from '../utils'
import { Button, Dropdown, Input } from 'semantic-ui-react'
import ReactJson from 'react-json-view'
import VerticalBarChart from './VerticalBarChart.jsx'
import {highlightDiffFont, decimals} from '../utils/numberFormat'
import { get, post } from '../js/Request'
import { loginWithToken } from '../js/Auth'
import config from '../config'

const host = config.SMRT_API

export default class OrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.getQuote = this.getQuote.bind(this);
    this.toggleFinancingSettlement = this.toggleFinancingSettlement.bind(this)
    this.executeOrder = this.executeOrder.bind(this)

    this.state = {
      instruments: [],
      defaultCurrency: {
        financing: "BTC",
        settlement: "USD"
      },
      defaultAmount: {
        financing: "",
        settlement: ""
      },
      options: {
        isDeliverable: "on",
        infiniteCapital: "on",
        blacklist: [],
        whitelist: [],
        holdingDays: 1,
        maxPaths: 3,
        premium: 0.01
      },
      quote: null,
      exchanges: null
    }
  }

  componentDidMount() {
    this.getExchanges()
    this.getInstruments()
  }

  toggleFinancingSettlement() {
    this.setState({
      defaultCurrency: {
        financing: this.state.defaultCurrency.settlement,
        settlement: this.state.defaultCurrency.financing
      },
      defaultAmount: {
        financing: this.state.defaultAmount.settlement,
        settlement: this.state.defaultAmount.financing
      }
    })
  }

  getExchanges = async () => {
    try {
      var exhResp = await get(`${host}/admin/exchanges`, this.getAuthHeader())
      console.log("Response is ", exhResp)
      var exchanges = exhResp.data.map(d=> {
        return { key: d.name, text: d.name, value: d.name }
      })
      this.setState({exchanges})
    } catch(error){
      console.error(`failed get auth request`, error)
    }
  }

  getAuthHeader = () => {
     return {headers: { 'Authorization': `Basic ${localStorage.token}`}} }

  getInstruments= async () => {
    try {
      var insResp = await get(`${host}/instruments`, this.getAuthHeader())
      console.log(`Instruments `, insResp)

      let uniqueInstruments = []
      insResp.data.forEach((instrument) => {
        let base = instrument.symbol.substring(0,3)
        let quote = instrument.symbol.substring(4,7)

        if(!~uniqueInstruments.indexOf(base)) {
          uniqueInstruments.push(base)
        }

        if(!~uniqueInstruments.indexOf(quote)) {
          uniqueInstruments.push(quote)
        }
        this.setState({instruments: uniqueInstruments.sort()})
      })
    } catch(error){
      console.error(`Failed to fetch instruments`, error)
    }
  }

  getQuote = async () => {
    // validates input before quoting
    if((!parseFloat(this.state.defaultAmount.financing) && !parseFloat(this.state.defaultAmount.settlement))) {
      return
    }

    let whitelist = this.state.options.whitelist
    , blacklist = this.state.options.blacklist
    , isDeliverable = this.state.options.isDeliverable === "on" ? true : false
    , infiniteCapital = this.state.options.infiniteCapital === "on" ? true : false

    let obj = {
      "request_id": uuidv4(),
      "financing_currency": this.state.defaultCurrency.financing,
      "settlement_currency": this.state.defaultCurrency.settlement,
      "exchange_whitelist": whitelist,
      "exchange_blacklist": blacklist,
      "expected_holding_days": parseInt(this.state.options.holdingDays, 10),
      "is_deliverable": isDeliverable,
      "infinite_capital": infiniteCapital,
      "our_premium_fee_bp": parseFloat(this.state.options.premium),
      "max_path": parseInt(this.state.options.maxPaths, 10)
    }

    if(this.state.defaultAmount.financing && this.state.defaultAmount.financing.length) {
      obj.financing_amount = this.state.defaultAmount.financing
    } else {
      obj.settlement_amount = this.state.defaultAmount.settlement
    }

    try {
      var priceResp = await post(`${host}/detailed_prices`, obj, this.getAuthHeader())
      console.log(`Detailed Prices `, priceResp)
      let body = priceResp.data
      if(!body || body.error) {
        console.error(body.error)
        console.error(body.LOGS)
        return
      }
      var quote = body

      let our_acquisition_rate_with_fees = quote ? highlightDiffFont(quote.order_info.market_performance.our_acquisition_rate_with_fees,this.state.prevQuote ? this.state.prevQuote.order_info.market_performance.our_acquisition_rate_with_fees : "", null, "", "") : null
        , our_acquisition_rate_with_fees_inverted = quote ? highlightDiffFont(quote.order_info.market_performance.our_acquisition_rate_with_fees_inverted,this.state.prevQuote ? this.state.prevQuote.order_info.market_performance.our_acquisition_rate_with_fees_inverted : "", null, "", "") : null

      this.setState({quote: body, our_acquisition_rate_with_fees, our_acquisition_rate_with_fees_inverted, lastRequestTime: moment().format('MMM Do YY h:mm:ss') }, () => {
        setTimeout(() => {
          this.getQuote(); this.setState({prevQuote: body})
        } , 500)
      })
    } catch(error){
      console.error(`Failed to retrieve Detailed prices ${error}`)
    }
  }

  executeOrder = async () => {
    let obj = {
      "request_id": uuidv4(),
      "price_request_id": this.state.quote.meta.id
    }

    try{
      var executeResp = await post(`${host}/orders/instant`, obj, this.getAuthHeader())

      console.log("success")
      console.log(`Execute order response`, executeResp.data)
      console.log(this.state.quote)

    } catch(error){
      console.error(`Failed to execute order`, this.state.quote, error)
    }
  }

  handleInput(e, parentKey, childKey, values) {
    let current = this.state[parentKey]
    if(!values) {
      current[childKey] = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    } else {
      current[childKey] = values.value
    }
    this.setState({ [parentKey]: current })
  }

  render() {
    return (
        <div>
        { localStorage.token && localStorage.token.length> 10 ?
          <div>
            <div className="Sidebar-left">
              <div className="Order-block">
                <div className="Timer-block">
                  <label className="Input-label">Last request time</label>
                  <label className="Input-label">{this.state.lastRequestTime}</label>
                </div>

                <div className="Order-entry">
                  <div className="Order-field">
                    <input onKeyUp={this.getQuote} disabled={this.state.defaultAmount.settlement.length} type="text" value={this.state.defaultAmount.financing} placeholder="Financing Amount" onChange={(e) => this.handleInput(e, "defaultAmount", "financing")} />
                    <select value={this.state.defaultCurrency.financing} onChange={(e) => this.handleInput(e, "defaultCurrency", "financing")}>
                      {this.state.instruments.map((instrument, index) => {
                        return <option key={index}>{instrument}</option>
                      })}
                    </select>
                  </div>
                  <div onClick={this.toggleFinancingSettlement} className="Button-swap">⇄</div>
                  <div className="Order-field">
                  <input onKeyUp={this.getQuote} disabled={this.state.defaultAmount.financing.length} type="text" value={this.state.defaultAmount.settlement} placeholder="Settlement Amount" onChange={(e) => this.handleInput(e, "defaultAmount", "settlement")} />
                    <select value={this.state.defaultCurrency.settlement} onChange={(e) => this.handleInput(e, "defaultCurrency", "settlement")}>
                      {this.state.instruments.map((instrument, index) => {
                        return <option key={index}>{instrument}</option>
                      })}
                    </select>
                  </div>
                  <button className="Background-blue" onClick={this.executeOrder} >Execute</button>
                </div>
              </div>
              <div className="Order-entry">
                <div className="Order-field">
                  <input type="checkbox" checked={this.state.options.isDeliverable} onChange={(e) => this.handleInput(e, "options", "isDeliverable")} />
                  <label>is_deliverable</label>
                </div>
                <div className="Order-field">
                <input type="checkbox" checked={this.state.options.infiniteCapital} onChange={(e) => this.handleInput(e, "options", "infiniteCapital")} />
                  <label>infinite_capital</label>
                </div>
                <div className="Order-field Has-Label">
                <label className="Input-label">Exchange Blacklist</label>
                  <Dropdown placeholder='Exchange Blacklist' onChange={(e, values) => this.handleInput(e, "options", "blacklist", values)} fluid multiple search selection options={this.state.exchanges} />
                </div>
                <div className="Order-field Has-Label">
                  <label className="Input-label">Exchange Whitelist</label>
                  <Dropdown placeholder='Exchange Whitelist' onChange={(e, values) => this.handleInput(e, "options", "whitelist", values)} fluid multiple search selection options={this.state.exchanges} />
                </div>
                <div className="Order-field Has-Label">
                  <label className="Input-label">Holding Days</label>
                  <input type="text" value={this.state.options.holdingDays} onChange={(e) => this.handleInput(e, "options", "holdingDays")} />
                </div>
                <div className="Order-field Has-Label">
                  <label className="Input-label">Max Paths</label>
                  <input type="text" value={this.state.options.maxPaths} onChange={(e) => this.handleInput(e, "options", "maxPaths")} />
                </div>
                <div className="Order-field Has-Label">
                  <label className="Input-label">Our Premium</label>
                  <input type="text" value={this.state.options.premium} onChange={(e) => this.handleInput(e, "options", "premium")} />
                </div>
              </div>
            </div>
            <div className="Content">
              {this.state.quote ?
                <div className="Content-wrapper">
                  <div className="Content-head">
                    <div className="Order-results">
                      <label>Our cost per coin w/ fees</label>
                      <div className="value">
                        <span dangerouslySetInnerHTML={{ __html: this.state.our_acquisition_rate_with_fees }}></span><span className="Tag-currency">{this.state.defaultCurrency.settlement}</span>
                        <span> / </span>
                        <span dangerouslySetInnerHTML={{ __html: this.state.our_acquisition_rate_with_fees_inverted }}></span><span className="Tag-currency">{this.state.defaultCurrency.financing}</span>
                      </div>
                    </div>
                    <div className="Order-results">
                      <label>Client price</label>
              <div className="value">{this.state.quote.quote_to_client.price > this.state.quote.quote_to_client.price_inverted ? decimals(this.state.quote.quote_to_client.price) : decimals(this.state.quote.quote_to_client.price_inverted)}<span className="Tag-currency">{this.state.quote.quote_to_client.price > this.state.quote.quote_to_client.price_inverted ? this.state.defaultCurrency.financing : this.state.defaultCurrency.settlement}</span></div>
                    </div>
                    <div className="Order-results">
                      <label>Avg profit</label>
                      <div className="value">{decimals(this.state.quote.order_info.market_performance.avg_profit_USD)}<span className="Tag-currency">USD</span></div>
                    </div>
                    <div className="Order-results">
                      <label>Avg cost per coin</label>
                      <div className="value">{this.state.quote.order_info.market_performance.avg_total_cost_to_execute_all_trades}</div>
                    </div>
                    <div className="Order-results">
                      <label>Avg slippage</label>
                      <div className="value">{this.state.quote.fees_breakdown.avg_slippage}</div>
                    </div>
                  </div>
                  <div className="Content-body">
                    <div className="Msg-client">{this.state.quote.quote_to_client.description}</div>
                    <VerticalBarChart data={this.state.quote.path_summary}></VerticalBarChart>
                    <ReactJson src={this.state.quote} theme="rjv-default"></ReactJson>
                  </div>
                </div>
              : ""}
            </div>
          </div>
:
          <div>Loading...</div>

        }
        </div>
    )
  }
}
