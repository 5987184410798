import React from 'react'
import Performance from './Dashboard-Widgets/Performance.jsx'
import Correlations from './Dashboard-Widgets/Correlations.jsx'
import LineChart from './Dashboard-Widgets/LineChart.jsx'
import TableView from './Utilities/TableView.jsx'
import { Grid } from 'semantic-ui-react'
import axios from 'axios'
import { BASE_URI } from './Utilities/restApi'
import ExposureWidgets from './Exposures/ExposureWidgets.jsx'


var pendingSettlements = [
  ["Financed / Settled", "Counterparty", "Date"],
  ["$5.23k / 10.23 btc", "Kwikkle", "2 days ago"],
  ["$5.23k / 10.23 btc", "Kwikkle", "2 days ago"],
  ["$5.23k / 10.23 btc", "Kwikkle", "2 days ago"],
  ["$5.23k / 10.23 btc", "Kwikkle", "2 days ago"],
  ["$5.23k / 10.23 btc", "Kwikkle", "2 days ago"]
]

var liquidations = [
  ["Assets", "Risk level", "Exchange", "Adj. Liquidation price", "Percent away", "Current Price", "Position size", "Collateral required"],
  ["Position Name", "critical", "Bitfinex", "$3,283.32", "3%", "$3,827.99", "$204,832.27", "$104,812.84"],
  ["Position Name", "high", "Bitmex", "$3,283.32", "3%", "$3,827.99", "$204,832.27", "$104,812.84"],
  ["Position Name", "high", "Bitmex", "$3,283.32", "3%", "$3,827.99", "$204,832.27", "$104,812.84"],
  ["Position Name", "high", "Bitmex", "$3,283.32", "3%", "$3,827.99", "$204,832.27", "$104,812.84"],
  ["Position Name", "medium", "Bitmex", "$3,283.32", "3%", "$3,827.99", "$204,832.27", "$104,812.84"],
  ["Position Name", "medium", "Bitmex", "$3,283.32", "3%", "$3,827.99", "$204,832.27", "$104,812.84"],
]

var AccountBalanceData = [
  ["", ""],
  ["Bank Accounts", "$109,282,23"],
  ["Wallet Accounts", "$132,578,23"],
  ["Bank Accounts", "$109,282,23"],
  ["Wallet Accounts", "$132,578,23"],
  ["Bank Accounts", "$109,282,23"],
  ["Bank Accounts", "$109,282,23"],
  ["Wallet Accounts", "$132,578,23"],
]

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exposures: [[]]
    }
  }

  async componentDidMount() {
    axios.get(`${BASE_URI}/exposures`)
    .then(resp => {
      this.setState({exposures: resp.data})
    })
    .catch(function(error){
      console.error(`Failed to fetch exposures table data`, error)
    });
  }

  render() {
    
    const { exposures } = this.state
    var widgetsData = {
      "header": {
        "leverage": { "value": "2.6x", "24h_chg": "-1.45%", "type": "USD" },
        "margin_used": { "value": "32.21%", "24h_chg": "+5.24%", "type": "USD" },
        "total_crypto_longs": { "value": "$1,032,537.48", "24h_chg": "+$14,2928.23", "type": "USD" },
        "total_crypto_shorts": { "value": "$1,032,537.48", "24h_chg": "+$14,2928.23", "type": "USD" }
      }
    }

    return (
      <div className="dashboard">
        <Grid>
          <Grid.Row>
            <ExposureWidgets data={widgetsData} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={3} className="noPadding">
              <Performance />
              <TableView data={pendingSettlements} title="Pending Settlement" highlightTitle="Financed / Settled"/>
            </Grid.Column>
            <Grid.Column computer={4} className="noPadding">
              <TableView data={exposures} title="Exposures" changeColumn="Exposure"/>
              <LineChart />
            </Grid.Column>
            <Grid.Column computer={9} className="noPadding"> 
              <TableView data={liquidations} title="Liquidation risks" alertColumn="Risk level" changeColumn="Current Price"/>
              <Grid className="noMarginLeft">
                <Grid.Row>
                    <Correlations />
                    <TableView data={AccountBalanceData} title="Account Balances"/>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        
        <br/>
        
        {/* <TableView data={data} title="Liquidations"/> */}
      </div>
   )
  }
} 

