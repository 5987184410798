import React, { useEffect, useState } from 'react';
import { Button, Confirm, Dimmer, Grid, Loader, Modal, Label } from 'semantic-ui-react';
import FormModal from '../Utilities/FormModal';
import AccountDetails from './AccountDetails';
import { ACCOUNT_TYPE } from "../../const";
import { createClient, REST_CLIENTS_TYPE } from '../../rest';

const ShowCounterpartyDetails = (props) => {
  const cp = props.info;
  const { onCounterpartyUpdate } = props;
  const [accounts, setAccounts] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteCp, setDeleteCp] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [apiAlert, setApiAlert] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [dimmerActive, setDimmerActive] = useState(true);

  const show = () => setOpen(true);
  const handleCancel = () => setOpen(false);

  useEffect(() => {
    async function populateData() {
      setDimmerActive(true);
      try {
        const accountsResp = await createClient(REST_CLIENTS_TYPE.Acct).get({
          partyId: cp.id,
        });
        setAccounts(accountsResp);
        setDimmerActive(false);
      } catch (e) {
        console.error(`Failed to fetch counterparties`, e);
        setApiAlert(e.message);
      }
    }

    populateData();
  }, [refetch, cp]);

  const onUpdate = () => {
    onCounterpartyUpdate('Update');
    setRefetch(refetch + 1);
  };

  const showDelete = (uuid) => {
    setDeleteCp({ deleteCp: { [uuid]: true, ...deleteCp } });
  };
  const hideDelete = (uuid) => {
    setDeleteCp({ deleteCp: { [uuid]: false, ...deleteCp } });
  };

  const handleCounterpartyDelete = (partyId) => {
    setButtonLoading(true);
    createClient(REST_CLIENTS_TYPE.Party)
      .delete(partyId)
      .then((resp) => {
        props.onCounterpartyUpdate('Deleted');
        setOpen(false);
        setButtonLoading(false);
      })
      .catch((error) => {
        setButtonLoading(false);
        console.error(`Failed to delete counterparty`, error);
        setApiAlert(error.message);
      });
  };

  return (
    <>
      <Grid stackable>
        <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
          <Modal.Header color="red">Error saving data</Modal.Header>
          <Modal.Content>
            <p>{apiAlert}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
          </Modal.Actions>
        </Modal>
        <Grid.Row as="header">
          <Grid.Column className="title" floated="left" width={8}>
            <Grid.Row>{cp.name}</Grid.Row>
            <Grid.Row><Label>{cp.id}</Label></Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <FormModal
              for="NewCounterparty"
              data={cp}
              onSuccess={onUpdate}
              trigger={
                <Button primary floated="right">
                  Edit Details
                </Button>
              }
            />
            <Button basic floated="right" onClick={show}>
              Delete
            </Button>
            <Confirm
              size="tiny"
              className="delete"
              open={open}
              header="Delete this counterparty?"
              cancelButton="Cancel"
              confirmButton={<Button loading={buttonLoading} content="Yes, Delete" primary />}
              content="Are you sure you want to delete this entry? You can not undo this action and it may impact existing OTC or bank records. "
              onCancel={handleCancel}
              onConfirm={() => handleCounterpartyDelete(cp.id)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column className="counterparty-fields">
            <label>Name</label>
            <span>{cp.name}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Contact Name</label>
            <span>{cp.contactName}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Email</label>
            <span>{cp.email}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Phone number</label>
            <span>{cp.phone}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Address</label>
            <span>{cp.address}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>City</label>
            <span>{cp.city}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Province / State</label>
            <span>{cp.state}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Postal / ZIP</label>
            <span>{cp.postal}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Country</label>
            <span>{cp.country}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>OTC Fee</label>
            <span>{cp.otcFee + ' bps'}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Credit limit</label>
            <span>{cp.creditLimit + ' USD'}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Max Allowed Quote Size</label>
            <span>{cp.maxQuote + ' USD'}</span>
          </Grid.Column>
          <Grid.Column className="counterparty-fields">
            <label>Notes</label>
            <span>{cp.note}</span>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column>
            <Grid.Row as="header">
              <FormModal
                for="NewWire"
                partyId={cp.id}
                onSuccess={onUpdate}
                trigger={
                  <Button primary floated="right">
                    Add Wires
                  </Button>
                }
              />
              <div className="title">Wire Transfer Details</div>
            </Grid.Row>

            {dimmerActive ? (
              <Dimmer active={dimmerActive} inverted>
                <Loader content="Loading" />
              </Dimmer>
            ) : (
              accounts &&
              accounts
                .filter((x) => x.isBankAcct)
                .map((wire, i) => (
                  <Grid.Row key={i}>
                    <AccountDetails
                      title={wire.title}
                      bank={props.banks.find((x) => x.id === wire.bankId)}
                      data={wire}
                      partyId={cp.id}
                      onUpdate={onUpdate}
                      for={ACCOUNT_TYPE.WIRE}
                    />
                  </Grid.Row>
                ))
            )}
          </Grid.Column>
          <Grid.Column>
            <Grid.Row as="header">
              <FormModal
                for="NewWallet"
                partyId={cp.id}
                onSuccess={onUpdate}
                trigger={
                  <Button primary floated="right">
                    Add Wallets
                  </Button>
                }
              />
              <div className="title">Wallet Addresses</div>
            </Grid.Row>
            {dimmerActive ? (
              <Dimmer active={dimmerActive} inverted>
                <Loader content="Loading" />
              </Dimmer>
            ) : (
              accounts &&
              accounts
                .filter((x) => !x.isBankAcct)
                .map((w, i) => (
                  <Grid.Row key={i}>
                    <AccountDetails
                      title={`${w.title} ${w.currency} Wallet`}
                      memo={w.memo}
                      data={w}
                      partyId={cp.id}
                      onUpdate={onUpdate}
                      for={ACCOUNT_TYPE.WALLET}
                    />
                  </Grid.Row>
                ))
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default ShowCounterpartyDetails;
