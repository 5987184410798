import React, { useRef, useState } from 'react';
import {
  Confirm,
  Divider,
  Dropdown,
  Grid,
  Icon,
  List,
  Segment,
  Modal,
  Button,
} from 'semantic-ui-react';
import FormModal from '../Utilities/FormModal';
import { ACCOUNT_TYPE } from "../../const";
import { createClient, REST_CLIENTS_TYPE} from '../../rest';

const AccountDetails = (props) => {
  const [open, setOpen] = useState(props.open);
  const [apiAlert, setApiAlert] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const copyAddress = useRef();

  const onConfirmDelete = () => {
    setButtonLoading(true);
    const uuid = props.data.id;
    const restType =
      props.for === ACCOUNT_TYPE.INTERMEDIARY
        ? REST_CLIENTS_TYPE.Intermediary
        : REST_CLIENTS_TYPE.Acct;
    let api = createClient(restType).delete(uuid);
    api
      .then((resp) => {
        console.debug(`Successfully deleted`, resp);
        setOpen(false);
        props.onUpdate(resp);
        setButtonLoading(false);
      })
      .catch((e) => {
        setButtonLoading(false);
        console.error(`Failed to delete ${props.for} `, props.data, e);
        setApiAlert(e.message);
      });
  };

  const copyToClipboard = (e) => {
    const address = document.createElement('textarea');
    address.value = copyAddress.current.innerText;
    document.body.appendChild(address);
    address.select();
    document.execCommand('copy');
    document.body.removeChild(address);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 5000);
  };

  const displayFields = () => {
    const data = props.data;

    if (props.for === ACCOUNT_TYPE.WALLET)
      return <span ref={copyAddress}>{data.accountNumber}</span>;

    if (props.for === ACCOUNT_TYPE.INTERMEDIARY) {
      return (
        <>
          {data.name ? (
            <span>
              <b>Bank Name: </b>
              {data.name}
            </span>
          ) : (
            ''
          )}
          <span>
            <b>Bank Address: </b>
            {data.address}, {data.city}, {data.state}, {data.postal}, {data.country}
          </span>
          {data.institution ? (
            <span>
              <b>Institution Number: </b>
              {data.institution}
            </span>
          ) : (
            ''
          )}
          {data.routing ? (
            <span>
              <b>Routing Number: </b>
              <span style={{ all: 'revert' }} ref={copyAddress}>
                {data.routing}
              </span>
            </span>
          ) : (
            ''
          )}
          {data.swift ? (
            <span>
              <b>Swift Code / BIC: </b>
              {data.swift}
            </span>
          ) : (
            ''
          )}
        </>
      );
    }

    return (
      props.bank && (
        <>
          <span>
            <b>Name on Account: </b>
            {data.name}
          </span>
          {props.bank.name ? (
            <span>
              <b>Bank Name: </b>
              {props.bank.name}
            </span>
          ) : (
            ''
          )}
          <span>
            <b>Bank Address: </b>
            {data.address}, {data.city}, {data.postal}, {data.country}
          </span>
          {data.branch ? (
            <span>
              <b>Branch Name: </b>
              {data.branch}
            </span>
          ) : (
            ''
          )}
          {data.accountNumber ? (
            <span>
              <b>Account Number: </b>
              <span style={{ all: 'revert' }} ref={copyAddress}>
                {data.accountNumber}
              </span>
            </span>
          ) : (
            ''
          )}
          {data.routing ? (
            <span>
              <b>Routing Number: </b>
              {data.routing}
            </span>
          ) : (
            ''
          )}
          {data.swift ? (
            <span>
              <b>Swift Code / BIC: </b>
              {data.swift}
            </span>
          ) : (
            ''
          )}
          {props.bank.currency ? (
            <span>
              <b>Currency: </b>
              {props.bank.currency}
            </span>
          ) : (
            ''
          )}
        </>
      )
    );
  };
  const data = props.data;
  return (
    <Segment className="wire-details">
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error saving data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid className="noPadding">
        <Grid.Column width={13}>
          <div className="title">{props.title}</div>
          <span className="memo">{props.memo}</span>
          {props.verified ? (
            <span className="verified">
              <Icon name="check circle"></Icon>Verified
            </span>
          ) : (
            ''
          )}
        </Grid.Column>
        <Grid.Column width={3} verticalAlign="middle">
          <div className="tooltip">
            <Dropdown inline text="Edit" />
            <List className="tooltiptext">
              <FormModal
                for={`New${props.for.charAt(0).toUpperCase() + props.for.slice(1)}`}
                data={data}
                uuid={props.data.id}
                bank={props.bank}
                partyId={props.partyId}
                onSuccess={props.onUpdate}
                trigger={<List.Item>Edit {props.for}</List.Item>}
              />
              <List.Item onClick={() => setOpen(true)}>Delete {props.for}</List.Item>
              <Confirm
                size="tiny"
                className="delete"
                open={open}
                header={`Delete this ${props.for} details?`}
                cancelButton="Cancel"
                confirmButton={<Button loading={buttonLoading} content="Yes, Delete" primary />}
                content="Are you sure you want to delete this entry? You can not undo this action and it may impact existing OTC or bank records."
                onCancel={() => setOpen(false)}
                onConfirm={() => onConfirmDelete()}
              />
              <FormModal
                for={`Share`}
                data={data}
                uuid={data.id}
                trigger={<List.Item>Share</List.Item>}
              />
              {copySuccess ? (
                <List.Item className={`green icon tick`}>
                  <Icon name="check circle" /> Copied!
                </List.Item>
              ) : (
                <List.Item onClick={copyToClipboard}>Copy address</List.Item>
              )}
            </List>
          </div>
        </Grid.Column>
      </Grid>
      <Divider />
      {displayFields()}
    </Segment>
  );
};

export default AccountDetails;
