import React from 'react';
import { scaleOrdinal } from 'd3-scale';
import { select } from 'd3-selection';
import { sum } from 'd3-array';
import { schemeAccent } from 'd3-scale-chromatic';
import { decimals } from '../utils/numberFormat';

export default class Piechart extends React.Component {
  constructor(props) {
    super(props);
    this.createChart = this.createChart.bind(this);
    this.state = {};
  }

  componentDidMount() {
    var data = this.props.data;
    data = Object.keys(data)
      .map(function (key) {
        return {
          value: parseFloat(key.replace(/,/g, '')),
          path: data[key].replace(/\|/g, ':').replace(/,/g, ' → '),
        };
      })
      .sort((a, b) => b.value - a.value);

    this.createChart(data);
  }

  componentDidUpdate() {
    var data = this.props.data;
    data = Object.keys(data)
      .map(function (key) {
        return {
          value: parseFloat(key.replace(/,/g, '')),
          path: data[key].replace(/\|/g, ':').replace(/,/g, ' → '),
        };
      })
      .sort((a, b) => b.value - a.value);

    select('.Chart-legend').selectAll('.Legend-item').remove().exit();

    this.createChart(data);
  }

  createChart(data) {
    var width = 800,
      minWidth = 5;

    var total = sum(data, (d) => d.value);

    var color = scaleOrdinal(schemeAccent);

    var legend = select('.Chart-legend').selectAll('.Legend-item').data(data).enter();

    var legendItem = legend.append('div').attr('class', 'Legend-item');

    legendItem
      .append('span')
      .attr('class', 'Legend-color')
      .style('width', (d) => {
        return (d.value / total) * (width - minWidth) + minWidth + 'px';
      })
      .style('background', (d) => color(d.path));

    var legendDesc = legendItem.append('span').attr('class', 'Legend-description');

    legendDesc
      .append('span')
      .attr('class', 'Legend-text')
      .text((d) => d.path);

    legendDesc
      .append('span')
      .attr('class', 'Legend-value')
      .text((d) => decimals(d.value));
  }

  render() {
    return <div className="Chart-legend"></div>;
  }
}
