import { useState, useEffect } from 'react';
import { Button, Dimmer, Grid, Loader, Modal } from 'semantic-ui-react';
import FormModal from '../Utilities/FormModal';
import ShowBankDetails from './ShowBankDetails';
import {createClient, REST_CLIENTS_TYPE } from '../../rest';

const Banks = () => {
  const [banks, setBanks] = useState();
  const [accounts, setAccounts] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [dimmerActive, setDimmerActive] = useState(true);
  const [apiAlert, setApiAlert] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [lastOp, setLastOp] = useState(null);

  useEffect(() => {
    async function populateBanks() {
      setDimmerActive(true);
      try {
        const bankResp = await createClient(REST_CLIENTS_TYPE.Bank).getAll();
        const respAccounts = await createClient(REST_CLIENTS_TYPE.Acct).get({
          organization: "PARADOX" 
        });
        setBanks(bankResp);
        setAccounts(respAccounts);
        onSelect(activeIndex);
        if (lastOp === 'Created' || activeIndex >= bankResp.length) {
          setTimeout(() => setActiveIndex(bankResp.length - 1), 200);
        }
        setDimmerActive(false);
      } catch (e) {
        console.error(`Failed to fetch banks`, e);
        setApiAlert(e.message);
      }
    }
    populateBanks();
  }, [refetch]);

  const onSelect = (index) => {
    setActiveIndex(index);
  };

  const onBanksUpdate = (op) => {
    setLastOp(op);
    setRefetch(refetch + 1);
  };

  const selectedWires = () => {
    return accounts && accounts.filter((x) => x.bankId === banks[activeIndex].id);
  };

  return (
    <Grid className="page-wrapper counterparty-page">
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error loading data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Column className="list-view" width={2}>
        <Grid width={6}>
          <Grid.Row as="header" centered>
            <FormModal
              for="NewBank"
              onSuccess={onBanksUpdate}
              trigger={<Button primary>Create Bank</Button>}
            />
          </Grid.Row>
          <Grid.Column width={16} className="full-list">
            {dimmerActive ? (
              <Dimmer active={dimmerActive} inverted>
                <Loader content="Loading" />
              </Dimmer>
            ) : (
              banks &&
              banks.map((bank, i) => {
                return (
                  <Grid.Row
                    key={i}
                    columns={1}
                    onClick={() => onSelect(i)}
                    className={`list-item ${i === activeIndex ? 'active' : ''}`}
                  >
                    <Grid.Column width={16} className="text-title">
                      {bank.name} - {bank.currency}
                    </Grid.Column>
                  </Grid.Row>
                );
              })
            )}
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column className="detail-view" width={12}>
        {banks && accounts && banks[activeIndex] && (
          <ShowBankDetails
            onBankUpdate={onBanksUpdate}
            info={banks[activeIndex]}
            wires={selectedWires()}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Banks;
