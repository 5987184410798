import React, { useState, useEffect, Fragment } from 'react';
import { Table, Header, Grid, Icon, Dimmer, Loader, Modal, Button } from 'semantic-ui-react';
import { getSymbol, ICONS } from '../Utilities/forex';
import { REST_CLIENTS_TYPE, createClient } from '../../rest';
import { Currency } from '../../grpc/common_pb';
import { decimals, numberWithCommas } from '../../utils/numberFormat';

const PENDING = {
  SETTLEMENT: 'Pending Settlement',
  FINANCING: 'Pending Financing',
};

export default function Balances() {
  const [currencyTotals, setCurrencyTotals] = useState([]);
  const [dimmerActive, setDimmerActive] = useState(true);
  const [apiAlert, setApiAlert] = useState(false);
  const [partyTotals, setPartyTotals] = useState([]);
  const [headers, setHeaders] = useState([]);
  let ct = [];

  useEffect(() => {
    setDimmerActive(true);
    Promise.all([
      createClient(REST_CLIENTS_TYPE.Otc).listBalances(),
      createClient(REST_CLIENTS_TYPE.Party).getAll(),
    ])
      .then((results) => {
        let [fetchedBalances, fetchedCounterparties] = results;
        setCurrencyTotals(fetchedBalances.byCurrency)
        // Make a map from counterparty ID to name
        let cps = fetchedCounterparties.reduce((map, cp) => {
          map[cp.id] = cp.name;
          return map;
        }, {});

        const tableBody = fetchedBalances.byParty.map((p) => {
          const name = cps[p.party_id];
          let tx = p.currency_totals.map((x) => {
            return {
              [x.currency]: {
                settlements: parseFloat(x.pendingSettlement),
                financing: parseFloat(x.pendingFinancing)
              },
            };
          });
          tx = Object.assign({}, ...tx);
          return { name, tx };
        });
        const tableHeaders = Array.from(new Set(tableBody.flatMap((x) => Object.keys(x.tx))));
        setPartyTotals(tableBody);
        setHeaders(tableHeaders);
        setDimmerActive(false);
      })
      .catch((e) => {
        console.error('Failed to load balances', e);
        setApiAlert(e.message);
      });
  }, []);

  const currencySort = () => {
    currencyTotals.map((cur) => {
      ct.push({
        currency: cur.currency,
        pendingSettlement: parseFloat(cur.pendingSettlement),
        pendingFinancing: parseFloat(cur.pendingFinancing)
      });
    });
    ct.sort(
      (a, b) =>
        a.pendingSettlement + a.pendingFinancing - (b.pendingSettlement + b.pendingFinancing)
    );
    return ct;
  };

  return (
    <Grid>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error Loading Balances</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      {dimmerActive && (
        <Dimmer active={dimmerActive} inverted>
          <Loader content="Loading" />
        </Dimmer>
      )}
      <Grid.Row className="noPaddingTop">
        <Grid.Column width={16}>
          <div className="balances">
            <div className="stats-widget">
              {currencySort()
                .reverse()
                .map((curr, i) => {
                  return (
                    <div key={i} className="block-box">
                      <div className="titleBlock">
                        <div className="value">
                          <Icon
                            className={
                              ICONS.find((icon) => icon === curr.currency)
                                ? `icon-${curr.currency}`
                                : `icon-default`
                            }
                          >
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                            <span className="path5"></span>
                            <span className="path6"></span>
                            <span className="path7"></span>
                            <span className="path8"></span>
                          </Icon>
                          {curr.currency}
                        </div>
                      </div>
                      <div className="settlement">
                        {getSymbol(curr.currency) + numberWithCommas(curr.pendingSettlement)}
                        <br />
                        <span>Pending Settlements</span>
                      </div>
                      <div className="financing">
                        {getSymbol(curr.currency) + numberWithCommas(curr.pendingFinancing)}
                        <br />
                        <span>Pending Financing</span>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="body">
              <Header size="large">History</Header>
              <Table basic singleLine padded>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    {headers.map((curr, i) => {
                      return <Table.HeaderCell key={i}>{curr}</Table.HeaderCell>;
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {partyTotals &&
                    partyTotals.map((balance, i) => {
                      return (
                        <Fragment key={i}>
                          <Table.Row>
                            <Table.Cell className="counterparty" rowSpan="2">
                              {balance.name}
                            </Table.Cell>
                            <Table.Cell className="boldText">{PENDING.SETTLEMENT}</Table.Cell>
                            {headers.map((curr) => (
                              <Table.Cell key={curr}>
                                {balance.tx[curr] && balance.tx[curr].settlements !== 0
                                  ? numberWithCommas(balance.tx[curr].settlements)
                                  : '-'}
                              </Table.Cell>
                            ))}
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell className="boldText">{PENDING.FINANCING}</Table.Cell>
                            {headers.map((curr) => (
                              <Table.Cell key={curr}>
                                {balance.tx[curr] && balance.tx[curr].financing !== 0
                                  ? numberWithCommas(balance.tx[curr].financing)
                                  : '-'}
                              </Table.Cell>
                            ))}
                          </Table.Row>
                        </Fragment>
                      );
                    })}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}