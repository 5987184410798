import React from 'react'
import { Table, Dropdown, Modal, Input } from 'semantic-ui-react'
import { ROUTES, publicGet } from "../Utilities/restApi"

var data = {
  "totals": [
    {
      "name": "bmo",
      "value": 123,
      "24h_chg": 213,
      "type": "USD"
    },
    {
      "name": "bmo",
      "value": 123,
      "24h_chg": 213,
      "type": "CAD"
    },
    {
      "name": "deltec",
      "value": 123,
      "24h_chg": 213,
      "type": "USD"
    },
    {
      "name": "deltec",
      "value": 123,
      "24h_chg": 213,
      "type": "CAD"
    },
    {
      "name": "deltec",
      "value": 123,
      "24h_chg": 213,
      "type": "CHF"
    },
    {
      "name": "silvergate",
      "value": 123,
      "24h_chg": 213,
      "type": "USD"
    }
  ],
  "transactions": [
    {
      "name": "deltec",
      "counterpart": "alameda",
      "accountingDate": "1234",
      "transactionDate": "1234",
      "id": 1234,
      "endingBalance": 1234,
      "status": "pending",
      "value": 123,
      "type": "USD"
    },
    {
      "name": "deltec",
      "counterpart": "alameda",
      "accountingDate": "1234",
      "transactionDate": "1234",
      "id": 1234,
      "endingBalance": 1234,
      "status": "pending",
      "value": 123,
      "type": "USD"
    }    

  ]
}

export default class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetsHolders: [],
      accounts: {},
      reconciliationFormData: {}
    }
  }

  async componentDidMount() {
    try {
      const resp = await publicGet(ROUTES.ASSETS_HOLDERS);
      console.log("Received assetsHolders as ", resp.data);
      try {
        const accountsResp = await publicGet(ROUTES.ASSETS_HOLDERS_ACCOUNTS);
        console.log("Received assetsHolders accounts as ", accountsResp.data);
        this.setState({ assetsHolders: resp.data.data, accounts: accountsResp.data.data });
      } catch (e) {
        console.error(`Failed to fetch all accounts for assets holders`, e);
      }
    } catch (e) {
      console.error(`Failed to fetch assetsHolders`, e);
    }
  }

  getAllAssetsHoldersOptions = () => {
    const {assetsHolders} = this.state
    if(!assetsHolders) return []
    return assetsHolders.map(x=> {return {"name": x.name, "value": x.uuid, "text": x.name}})
  }

  handleReconciliationsFields = (e,{name,value}) => {
    this.setState({ reconciliationFormData: { ...this.state.reconciliationFormData, [name]: value } });
  }

  getSelectedAssetsHolderCurrency = (fieldName) => {
    if(!this.state.reconciliationFormData.hasOwnProperty(fieldName)) return []
    const uuid = this.state.reconciliationFormData[fieldName]
    if(!this.state.accounts.hasOwnProperty(uuid)) {
      return []
    }
    if(!this.state.accounts[uuid]) return []
    return this.state.accounts[uuid].map(x=> {return {"name": x.currency, "value": x.uuid, "text": x.currency}})
  }

  render() {
    return (
      <div className="Reconiliations">
        <div className="Accounts-block">
          {
            data.totals.map((bank,i) => {
              return (
                <div key={i} className="block-box">
                  <div className="titleBlock">
                    <div className="iconWrapper">
                      <span className={"icon icon-" + bank.name}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </span>
                    </div>
                    <div className="value">{bank.value}</div>
                    <div className="title">{bank.name} - {bank.type}</div>
                    <div className="change green lightGreenBg">$123.12</div>
                  </div>
                </div>
              )

            })
          }
        </div>
        <div className="body">
          <div className="title">History</div>
          <div className="controls">
            <input className="control" placeholder="Start date" type="date"  />
            <input className="control" placeholder="End date" type="date" />
            <Dropdown
                className="control"
                placeholder='Bank name'
                fluid
                search
                selection
                clearable
                onChange={(e, values) => this.handleInput(e, "filters", "selectedAccount", values)}
              />
            <Dropdown
                className="control"
                placeholder='Counterparty'
                fluid
                search
                selection
                clearable
                onChange={(e, values) => this.handleInput(e, "filters", "selectedAccount", values)}
              />
              <Modal className="FormModal" trigger={<div className="button-rectangle">New Entry</div>}>
              <Modal.Header>Create new bank record</Modal.Header>
                <Modal.Content scrolling>
                  <Modal.Description>
                    <div className="form">
                      <div className="row">
                        <div className="floatLeft">
                          <label>Bank</label>
                          <Dropdown selection search fluid clearable placeholder="Select Bank" name="selectedBank" options={this.getAllAssetsHoldersOptions()} onChange={this.handleReconciliationsFields}/>
                        </div>
                        <div className="floatRight">
                        <label>Counterpart</label>
                        <Dropdown selection search fluid clearable placeholder="Select Counterparty" name="selectedCounterparty" options={this.getAllAssetsHoldersOptions()} onChange={this.handleReconciliationsFields}/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="floatLeft">
                          <label>Accounting date</label>
                          <Input fluid className="control" name="accountingDate" placeholder="Enter Date" type="date" onChange={this.handleReconciliationsFields}/>
                        </div>

                        <div className="floatRight">
                          <label>Transaction date</label>
                          <Input fluid className="control" name="transactionDate" placeholder="Enter Date" type="date" onChange={this.handleReconciliationsFields}/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="floatLeft">
                          <label>Amount</label>
                          <Input fluid type="number" className="control" name="amount" placeholder="Amount" onChange={this.handleReconciliationsFields} />
                        </div>
                        <div className="floatRight">
                          <label>Currency</label>
                          <Dropdown className="control" name="currency" placeholder='Currency' fluid search selection clearable options={this.getSelectedAssetsHolderCurrency("selectedBank")} onChange={this.handleReconciliationsFields} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="button-rectangle">Confirm Transaction</div>
                      </div>
                    </div>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
          </div>
          <Table basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Bank name
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Counterpart
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Accounting date
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Transaction date
                </Table.HeaderCell>
                <Table.HeaderCell>
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Amount
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Ending balance
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Verified
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {
              data.transactions.map((tx, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <div className="iconWrapper">
                        <span className={"icon icon-" + tx.name}>
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </span>
                      </div>
                      <div>{tx.name}</div>
                    </Table.Cell>
                    <Table.Cell>{tx.counterpart}</Table.Cell>
                    <Table.Cell>{tx.accountingDate}</Table.Cell>
                    <Table.Cell>{tx.transactionDate}</Table.Cell>
                    <Table.Cell>{tx.id}</Table.Cell>
                    <Table.Cell>{tx.value}</Table.Cell>
                    <Table.Cell>{tx.endingBalance}</Table.Cell>
                    <Table.Cell><div className="circle"></div>{tx.status}</Table.Cell>
                  </Table.Row>
                )
              })
            }
            </Table.Body>
          </Table>

        </div>
      </div>
   )
  }
}

