import React, { Fragment, useEffect, useState } from 'react';
import { Button, Confirm, Dimmer, Grid, Loader, Modal } from 'semantic-ui-react';
import { ACCOUNT_TYPE } from "../../const";
import FormModal from '../Utilities/FormModal';
import AccountDetails from '../Counterparties/AccountDetails';
import { REST_CLIENTS_TYPE, createClient} from '../../rest';

const ShowBankDetails = (props) => {
  const bank = props.info;
  const { onBankUpdate } = props;
  const [intermediaries, setIntermediaries] = useState([]);
  const [apiAlert, setApiAlert] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [dimmerActive, setDimmerActive] = useState(true);

  const show = () => setOpen(true);

  useEffect(() => {
    async function populateIntermediary() {
      setDimmerActive(true);
      try {
        const intermediaryResp = await createClient(REST_CLIENTS_TYPE.Intermediary).get(bank.id);
        setIntermediaries(intermediaryResp);
        setDimmerActive(false);
      } catch (e) {
        console.error(`Failed to fetch intermediaries`, e);
        setApiAlert(e.message);
      }
    }

    populateIntermediary();
  }, [refetch, bank]);

  const onUpdate = () => {
    onBankUpdate('Update');
    setRefetch(refetch + 1);
  };

  const handleBankDelete = (bankId) => {
    setButtonLoading(true);
    createClient(REST_CLIENTS_TYPE.Bank)
      .delete(bankId)
      .then(function (resp) {
        console.log(`Bank deleted`, resp);
        props.onBankUpdate('Deleted');
        setOpen(false);
        setButtonLoading(false);
      })
      .catch(function (error) {
        setButtonLoading(false);
        console.error(`Failed to delete bank`, error);
        setApiAlert(error.message);
      });
  };

  const handleCancel = () => setOpen(false);

  return (
    <Grid stackable>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error Deleting Bank</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Row as="header">
        <Grid.Column className="title" floated="left" width={8}>
          {bank.name} - {bank.currency}
        </Grid.Column>
        <Grid.Column width={8}>
          <FormModal
            for="NewBank"
            data={bank}
            onSuccess={onUpdate}
            trigger={
              <Button primary floated="right">
                Edit Details
              </Button>
            }
          />
          <Button basic floated="right" onClick={show}>
            Delete
          </Button>
          <Confirm
            size="tiny"
            className="delete"
            open={open}
            header="Delete this bank?"
            cancelButton="Cancel"
            confirmButton={<Button loading={buttonLoading} content="Yes, Delete" primary />}
            content="Are you sure you want to delete this bank? You can not undo this action and it may impact existing OTC or bank records."
            onCancel={handleCancel}
            onConfirm={() => handleBankDelete(bank.id)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column className="counterparty-fields">
          <label>Name</label>
          <span>{bank.name}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Currency</label>
          <span>{bank.currency}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Phone number</label>
          <span>{bank.phone}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Type</label>
          <span>Bank</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Address</label>
          <span>{bank.address}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>City</label>
          <span>{bank.city}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Province / State</label>
          <span>{bank.state}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Postal / ZIP</label>
          <span>{bank.postal}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Country</label>
          <span>{bank.country}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Swift Code / BIC</label>
          <span>{bank.swift}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Routing / ABA</label>
          <span>{bank.routing}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Institution Number</label>
          <span>{bank.institution}</span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>Transfer Free</label>
          <span>
            {bank.transferFee} {bank.currency}
          </span>
        </Grid.Column>
        <Grid.Column className="counterparty-fields">
          <label>FX Fee Pips</label>
          <span>{bank.fxFee ? bank.fxFee + ' bps' : ''}</span>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column>
          <Grid.Row as="header">
            <FormModal
              for="NewWire"
              bank={bank}
              bankId={bank.id}
              onSuccess={onUpdate}
              trigger={
                <Button primary floated="right">
                  Add Wire
                </Button>
              }
            />
            <div className="title">OSF Wire details</div>
          </Grid.Row>

          {dimmerActive ? (
            <Dimmer active={dimmerActive} inverted>
              <Loader content="Loading" />
            </Dimmer>
          ) : (
            props.wires &&
            props.wires.map((wire, i) => (
              <Grid.Row key={i}>
                <AccountDetails
                  bank={props.info}
                  title={wire.title}
                  bankId={bank.id}
                  data={wire}
                  onUpdate={onUpdate}
                  for={ACCOUNT_TYPE.WIRE}
                />
              </Grid.Row>
            ))
          )}
        </Grid.Column>
        <Grid.Column>
          <Grid.Row as="header">
            <FormModal
              for="NewIntermediary"
              bank={bank}
              bankId={bank.id}
              onSuccess={onUpdate}
              trigger={
                <Button primary floated="right">
                  Add Intermediary
                </Button>
              }
            />
            <div className="title">Intermediary Bank</div>
          </Grid.Row>
          <Grid.Row>
            {dimmerActive ? (
              <Dimmer active={dimmerActive} inverted>
                <Loader content="Loading" />
              </Dimmer>
            ) : (
              intermediaries &&
              intermediaries.map((intermediary, i) => (
                <Grid.Row key={i}>
                  <AccountDetails
                    title={`${intermediary.name}`}
                    data={intermediary}
                    onUpdate={onUpdate}
                    id={bank.id}
                    for={ACCOUNT_TYPE.INTERMEDIARY}
                  />
                </Grid.Row>
              ))
            )}
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ShowBankDetails;
