import React, { Fragment, useEffect, useState } from 'react';
import { Dropdown, Grid, Icon, Table } from 'semantic-ui-react';
import LedgerBlock from './LedgerBlock';
import { useHistory, useParams } from 'react-router-dom';

const AccountLedger = (props) => {
  const { account, currency } = useParams();
  const [activeExchange, setActiveExchange] = useState(0);
  const [active, setActive] = useState(currency);
  const history = useHistory();
  const type = props.type;
  const accountData =
    props.type === 'exchange'
      ? props.exchangeList[account].wallets
      : props.bankList[account].accounts;

  console.log(`props`, props, active);

  useEffect(() => {
    setActive(currency);
  }, [currency]);

  const blocks = [
    {
      title: 'total',
      value: '18.522954',
    },
    {
      title: 'Trading wallet',
      value: '18.522954',
    },
    {
      title: 'Margin Wallet',
      value: '18.522954',
    },
    {
      title: 'funding wallet',
      value: '18.522954',
    },
    {
      title: 'derivatives wallet',
      value: '18.522954',
    },
    {
      title: 'credit line',
      value: '18.522954',
    },
  ];

  const [totalLedgerData, setTotalLedgerData] = useState([
    {
      description: 'Total funding payment on wallet funding',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '',
      debit: '2.383830',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$32,132,578.58',
    },
    {
      description: 'Settlement @ 77.344 on wallet margin',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description: 'Transfer of 8.284829 BTC from trading wallet to funding wallet',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '',
      debit: '2.383830',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description: 'Transfer of 3.884930 from credit line to trading wallet',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description: 'Exchange 3.0 BSV for BTC @ 0.188383 on funding wallet',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description: 'Exchange 1461.28393930 USDT for BTC @ 0.018384 on wallet exchange',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description:
        'Transfer of 2.383830 BTC from trading wallet to Kiwkkle BTC Wallet - 3P4odUrnoWZRuMX7BuFeVv8N1zb8nLKy95 for OTC',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
  ]);

  const bankLedgerData = [
    {
      description: 'A description about this transaction can go here',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '',
      debit: '2.383830',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$32,132,578.58',
    },
    {
      description: 'A description about this transaction can go here',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description: 'A description about this transaction can go here',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '',
      debit: '2.383830',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description: 'A description about this transaction can go here',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description: 'A description about this transaction can go here',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description: 'A description about this transaction can go here',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
    {
      description: 'A description about this transaction can go here',
      txDate: 'Nov 08, 2020 - 1:31pm',
      credit: '1.483850',
      debit: '',
      selected: '12.482452',
      rate: '$39,583.34',
      usd: '$132,578.58',
    },
  ];

  const toggleExchangeActive = (index) => {
    setActiveExchange(index);
  };

  const toggleBankAccount = (currency) => {
    setActive(currency);
    setTotalLedgerData(bankLedgerData);
    history.replace(`/accounts/bank/${account}/${currency}`);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div className="ledger">
            {type === 'exchange' && (
              <div className="path">
                <span className="name">{account}</span> <Icon className="icon-arrow-right" />{' '}
                <span className="currency">{currency} Wallets</span>
              </div>
            )}
            <div className="widgets">
              {blocks.map((key, index) => {
                return (
                  type === 'exchange' && (
                    <LedgerBlock
                      key={index}
                      isActive={activeExchange === index}
                      title={`${key.title} - ${currency}`}
                      value={key.value}
                      onClick={() => toggleExchangeActive(index)}
                    />
                  )
                );
              })}
              {Object.keys(accountData).map((key, index) => {
                return (
                  type === 'bank' && (
                    <LedgerBlock
                      key={index}
                      isActive={active === key}
                      title={`${key} Account`}
                      value={accountData[key]}
                      onClick={() => toggleBankAccount(key)}
                    />
                  )
                );
              })}
            </div>
            <div className="body">
              <div className="title">
                {account} - {currency}
              </div>
              <div className="controls">
                <Dropdown selection floated="right" placeholder="Counterparty: All" />
                <Dropdown selection floated="right" placeholder="Sort by: Transaction date" />
              </div>
              <Table basic selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Transaction date</Table.HeaderCell>
                    <Table.HeaderCell>Credit</Table.HeaderCell>
                    <Table.HeaderCell>Debit</Table.HeaderCell>
                    <Table.HeaderCell>Balance ({currency})</Table.HeaderCell>
                    <Table.HeaderCell>Rate</Table.HeaderCell>
                    <Table.HeaderCell>Balance (USD)</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {totalLedgerData &&
                    totalLedgerData.map((asset, i) => {
                      return (
                        <Fragment key={i}>
                          <Table.Row>
                            <Table.Cell width={6}>{asset.description}</Table.Cell>
                            <Table.Cell>{asset.txDate}</Table.Cell>
                            <Table.Cell className="green">{asset.credit}</Table.Cell>
                            <Table.Cell className="red">{asset.debit}</Table.Cell>
                            <Table.Cell>{asset.selected}</Table.Cell>
                            <Table.Cell>{asset.rate}</Table.Cell>
                            <Table.Cell>{asset.usd}</Table.Cell>
                          </Table.Row>
                        </Fragment>
                      );
                    })}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default AccountLedger;
