import { ClientBase } from "../rest_client";


type Bank = {
  id: string;
  name: string;
  currency: string;
  country: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  postal: string;
  institution: string;
  routing: number;
  swift: string;
  iban: string;
  fxFee: number;
  transferFee: number;
  transferFeeCurrency: string;
}

export class BankClient extends ClientBase {

  async create(bank: Omit<Bank, 'id'>) {
    this.log('Creating new bank', bank);
    await this.client.post('/banks', bank)
  }

  async update(bank: Bank) {
    this.log('Updating bank', bank);
    await this.client.put(`/banks/${bank.id}`, bank)
  }

  async delete(bank_id: string) {
    this.log('Delete bank', bank_id)
    await this.client.delete(`/banks/${bank_id}`)
  }

  async getAll(): Promise<Bank[]> {
    this.log("Getting list of banks")
    const resp = await this.client.get('/banks')
    return resp.data.data
  }

}
