import React from 'react'

const ExposureWidgets = (props) => {

  var data = props.data

  return (
    <div className="exposure-widgets">
        { 
            Object.keys(data["header"]).map((key, i) => (
            <div className="block-box" key={i}>
                <div className="titleBlock">
                <div className="title">{ key.replace(/_/g, ' ') }</div>
                <div className="value">{ data["header"][key].value }</div>
                <div className={`change-${data["header"][key]["24h_chg"].charAt(0) === "+" ? "positive":"negative"}`}>{ data["header"][key]["24h_chg"] }</div>
                </div>
            </div>
            ))
        }
    </div>
  )

}

export default ExposureWidgets