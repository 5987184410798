import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Grid, Input, Modal } from 'semantic-ui-react';
import { CRYPTO_CURRENCIES, EXCHANGES } from '../Utilities/forex';
import { PATTERN, FIELD_TYPE } from '../Utilities/core';
import { ACCOUNT_TYPE } from "../../const";
import WAValidator from 'wallet-address-validator';
import { REST_CLIENTS_TYPE, createClient  } from '../../rest';

const TITLE = 'Wallet';

const SCHEMA = [
  {
    fieldName: 'title',
    fieldRule: PATTERN.SAFE_CHARS,
    fieldtype: FIELD_TYPE.STRING,
    required: true,
  },
  { fieldName: 'currency', fieldtype: FIELD_TYPE.DROPDOWN, required: true },
  { fieldName: 'name', fieldtype: FIELD_TYPE.DROPDOWN },
  { fieldName: 'accountNumber', fieldRule: PATTERN.BITCOIN, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'memo', fieldRule: PATTERN.SAFE_CHARS, fieldtype: FIELD_TYPE.STRING },
];

const FORM = (props) => {
  console.log('new wallet props', props);
  const [post, setPost] = useState(props.data || {});
  const [errors, setErrors] = useState(
    Object.assign({}, ...Object.keys(props.data || {}).map((k) => ({ [k]: false })))
  );
  const [submit, setSubmit] = useState(false);
  const [apiAlert, setApiAlert] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => setSubmit(isValidForm()), [errors]);

  useEffect(() => {
    let validationResult = errors;
    Object.keys(post).forEach((k) => {
      validationResult = validateField(k, post[k], props.schema, validationResult);
    });
    setErrors(validationResult);
  }, [post]);

  const onChange = (e, { name, value }) => {
    setPost({ ...post, [name]: value });
  };

  const isValidForm = () => {
    const schema = props.schema || [];
    for (let i = 0; i < schema.length; i++) {
      let fd = schema[i];
      if (fd.required) {
        if (!errors.hasOwnProperty(fd.fieldName) || errors[fd.fieldName]) {
          return false;
        }
      } else {
        if (errors.hasOwnProperty(fd.fieldName) && errors[fd.fieldName]) {
          return false;
        }
      }
    }
    return true;
  };

  const validateField = (fieldName, fieldValue, schema, errors) => {
    const fieldDefinition = schema.find((f) => f.fieldName === fieldName);
    if (fieldName === 'accountNumber') {
      let isValidAdd = true;
      try {
        isValidAdd = WAValidator.validate(fieldValue, post.currency, 'both');
      } catch (e) {
        console.debug(`Validation for ${post.currency} not Available`);
      }
      return { ...errors, [fieldName]: !isValidAdd };
    }
    if (!fieldDefinition) {
      console.warn(`Field definition is missing for ${fieldName} in ${schema}`);
      return { ...errors, [fieldName]: false };
    }

    if (fieldDefinition.fieldtype === FIELD_TYPE.DROPDOWN) {
      return { ...errors, [fieldName]: false };
    }
    if (!fieldDefinition.required) {
      if (['', null, undefined].indexOf(fieldValue) >= 0) {
        return { ...errors, [fieldName]: false };
      }
    }

    return { ...errors, [fieldName]: !fieldDefinition.fieldRule.test(fieldValue) };
  };

  const submitForm = () => {
    setButtonLoading(true);
    post.partyId = props.partyId;
    if (props.data) {
      createClient(REST_CLIENTS_TYPE.Acct)
        .update({ accountType: ACCOUNT_TYPE.WALLET, ...post })
        .then(props.onSuccess)
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to update wallet ${post}`, e);
          setApiAlert(e.message);
        });
    } else {
      createClient(REST_CLIENTS_TYPE.Acct)
        .create({ accountType: ACCOUNT_TYPE.WALLET, ...post })
        .then(props.onSuccess)
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to create wallet ${post}`, e);
          setApiAlert(e.message);
        });
    }
  };

  return (
    <Grid centered columns={1}>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error saving data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Row>
        <Grid.Column width={9} className="noPadding">
          <label>Description</label>
          <Input
            error={errors.title}
            fluid
            placeholder="Add a Description"
            name="title"
            value={post.title}
            onChange={onChange}
          />
          {errors.title && <span className="error-text">Description is required</span>}
        </Grid.Column>
        <Grid.Column width={3} className="noPadding">
          <label>Currency</label>
          <Dropdown
            selection
            fluid
            placeholder="Currency"
            name="currency"
            options={CRYPTO_CURRENCIES}
            value={post.currency}
            onChange={onChange}
          />
          {errors.currency && <span className="error-text">Please select currency</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={props.partyId ? 12 : 9} className="noPadding">
          <label>Wallet Address</label>
          <Input
            error={errors.accountNumber}
            fluid
            placeholder="Wallet Address"
            name="accountNumber"
            value={post.accountNumber}
            onChange={onChange}
          />
          {errors.accountNumber && (
            <span className="error-text">{`Please provide correct ${post.currency} address`}</span>
          )}
        </Grid.Column>
        {!props.partyId && (
          <Grid.Column width={3} className="noPadding">
            <label>Exchange</label>
            <Dropdown
              selection
              fluid
              placeholder="Exchange"
              name="name"
              options={EXCHANGES}
              value={post.name}
              onChange={onChange}
            />
            {errors.name && <span className="error-text">Please select exchange</span>}
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding">
          <label>Add Memo</label>
          <Input
            name="memo"
            fluid
            className="control"
            placeholder="Enter memo"
            value={post.memo}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding noMargin">
          <Button loading={buttonLoading} fluid primary disabled={!submit} onClick={submitForm}>
            {props.data ? 'Update ' : 'Add '}Wallet
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export { TITLE, FORM, SCHEMA };
