import React, { useState, useEffect } from 'react';
import { Grid, Input, Dropdown, Button, TextArea, Modal } from 'semantic-ui-react';
import { PATTERN, FIELD_TYPE, validateField } from '../Utilities/core';
import { COUNTRIES } from '../Utilities/forex';
import { REST_CLIENTS_TYPE, createClient } from '../../rest';
const TITLE = 'Counterparty';


const SCHEMA = [
  { fieldName: 'name', fieldRule: PATTERN.NAME, fieldtype: FIELD_TYPE.STRING, required: true },
  { fieldName: 'email', fieldRule: PATTERN.EMAIL, fieldtype: FIELD_TYPE.STRING, required: true },
  { fieldName: 'phone', fieldRule: PATTERN.PHONE, fieldtype: FIELD_TYPE.NUMBER },
  { fieldName: 'contactName', fieldRule: PATTERN.NAME, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'address', fieldRule: PATTERN.ADDRESS, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'city', fieldRule: PATTERN.CITY, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'state', fieldRule: PATTERN.STATE, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'postal', fieldRule: PATTERN.POSTAL_CODE, fieldtype: FIELD_TYPE.STRING },
  {
    fieldName: 'country',
    fieldRule: PATTERN.COUNTRY,
    fieldtype: FIELD_TYPE.STRING,
    required: true,
  },
  { fieldName: 'otcFee', fieldRule: PATTERN.DECIMAL, fieldtype: FIELD_TYPE.NUMBER },
  { fieldName: 'creditLimit', fieldRule: PATTERN.DECIMAL, fieldtype: FIELD_TYPE.NUMBER },
  { fieldName: 'maxQuote', fieldRule: PATTERN.DECIMAL, fieldtype: FIELD_TYPE.NUMBER },
  { fieldName: 'note', fieldRule: PATTERN.SAFE_CHARS, fieldtype: FIELD_TYPE.TEXT_AREA },
];

const FORM = (props) => {
  const [post, setPost] = useState(props.data || {});
  const [errors, setErrors] = useState(
    Object.assign({}, ...Object.keys(props.data || {}).map((k) => ({ [k]: false })))
  );
  const [submit, setSubmit] = useState(false);
  const [apiAlert, setApiAlert] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  useEffect(() => setSubmit(isValidForm()), [errors]);

  const onChange = (e, { name, value }) => {
    setPost({ ...post, [name]: value });
    setErrors(validateField(name, value, props.schema, errors));
  };

  const isValidForm = () => {
    const schema = props.schema || [];
    for (let i = 0; i < schema.length; i++) {
      let fd = schema[i];
      if (fd.required) {
        if (!errors.hasOwnProperty(fd.fieldName) || errors[fd.fieldName]) {
          return false;
        }
      } else {
        if (errors.hasOwnProperty(fd.fieldName) && errors[fd.fieldName]) {
          return false;
        }
      }
    }
    return true;
  };

  const submitForm = () => {
    setButtonLoading(true);
    if (props.data) {
      createClient(REST_CLIENTS_TYPE.Party)
        .update(post)
        .then(props.onSuccess('Updated'))
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to update counterparties records ${post}`, e);
          setApiAlert(e.message);
        });
    } else {
      createClient(REST_CLIENTS_TYPE.Party)
        .create(post)
        .then(props.onSuccess('Created'))
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to create counterparties records ${post}`, e);
          setApiAlert(e.message);
        });
    }
  };

  return (
    <Grid centered columns={1}>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error saving data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Name</label>
          <Input
            fluid
            placeholder="Enter Name"
            name="name"
            error={errors.name}
            value={post.name}
            onChange={onChange}
          />
          {errors.name && <span className="error-text">Name is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Contact Name</label>
          <Input
            fluid
            placeholder="Enter Contact"
            name="contactName"
            error={errors.contactName}
            value={post.contactName}
            onChange={onChange}
          />
          {errors.contactName && <span className="error-text">Contact Name is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Email</label>
          <Input
            fluid
            type="email"
            placeholder="Enter Email"
            name="email"
            error={errors.email}
            value={post.email}
            onChange={onChange}
          />
          {errors.email && <span className="error-text">Email is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Phone number</label>
          <Input
            fluid
            className="control"
            name="phone"
            placeholder="Enter Phone number"
            type="number"
            error={errors.phone}
            value={post.phone}
            onChange={onChange}
          />
          {errors.phone && <span className="error-text">Phone is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Address</label>
          <Input
            fluid
            type="text"
            className="control"
            name="address"
            placeholder="Enter Address"
            error={errors.address}
            value={post.address}
            onChange={onChange}
          />
          {errors.address && <span className="error-text">Address is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>City</label>
          <Input
            fluid
            className="control"
            name="city"
            placeholder="Enter City"
            error={errors.city}
            value={post.city}
            onChange={onChange}
          />
          {errors.city && <span className="error-text">City is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Province / State</label>
          <Input
            fluid
            className="control"
            name="state"
            placeholder="Enter Province / State"
            error={errors.state}
            value={post.state}
            onChange={onChange}
          />
          {errors.state && <span className="error-text">Province is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Postal Code / ZIP</label>
          <Input
            fluid
            className="control"
            name="postal"
            placeholder="Enter Postal / Zip Code"
            error={errors.postal}
            value={post.postal}
            onChange={onChange}
          />
          {errors.postal && <span className="error-text">Postal is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Country</label>
          <Dropdown
            search
            fluid
            selection
            className="control"
            name="country"
            placeholder="Select Country"
            options={COUNTRIES}
            value={post.country}
            onChange={onChange}
          />
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>OTC Fee (BPS)</label>
          <Input
            fluid
            type="number"
            className="control"
            name="otcFee"
            placeholder="Enter OTC Fee"
            error={errors.otcFee}
            value={post.otcFee}
            onChange={onChange}
          />
          {errors.otcFee && <span className="error-text">Fee is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Credit limit (USD)</label>
          <Input
            fluid
            type="number"
            className="control"
            name="creditLimit"
            placeholder="Enter Credit Limit"
            error={errors.creditLimit}
            value={post.creditLimit}
            onChange={onChange}
          />
          {errors.creditLimit && <span className="error-text">Credit Limit is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Max Quote Limit (USD)</label>
          <Input
            fluid
            type="number"
            className="control"
            name="maxQuote"
            placeholder="Enter Max Quote limit"
            error={errors.maxQuote}
            value={post.maxQuote}
            onChange={onChange}
          />
          {errors.maxQuote && <span className="error-text">Max Quote Limit is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding notes">
          <label>Notes</label>
          <TextArea
            name="note"
            className="control"
            placeholder="Add a note here"
            error={errors.note}
            value={post.note}
            onChange={onChange}
          />
          {errors.note && <span className="error-text">Note is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Enabled Currencies</label>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding noMargin">
          <Button loading={buttonLoading} fluid primary disabled={!submit} onClick={submitForm}>
            {props.data ? 'Update' : 'Add'} Counterparty
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export { TITLE, FORM, SCHEMA };
