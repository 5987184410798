import React, { useState, useEffect } from 'react';
import { Button, TextArea, Grid, Input, Icon } from 'semantic-ui-react';
import { PATTERN, FIELD_TYPE, validateField } from '../Utilities/core';

const TITLE = 'Share wallet details';

const SCHEMA = [
  { fieldName: 'email', fieldRule: PATTERN.EMAIL, fieldtype: FIELD_TYPE.STRING, required: true },
  {
    fieldName: 'memo',
    fieldRule: PATTERN.SAFE_CHARS,
    fieldtype: FIELD_TYPE.STRING,
    required: false,
  },
];

const FORM = (props) => {
  const [post, setPost] = useState(props.data || {});
  const [errors, setErrors] = useState(
    Object.assign({}, ...Object.keys(props.data || {}).map((k) => ({ [k]: false })))
  );
  const [submit, setSubmit] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [shareSuccess, setShareSuccess] = useState(false);

  useEffect(() => setSubmit(isValidForm()), [errors]);

  const onChange = (e, { name, value }) => {
    setPost({ ...post, [name]: value });
    setErrors(validateField(name, value, props.schema, errors));
  };

  const isValidForm = () => {
    const schema = props.schema || [];
    for (let i = 0; i < schema.length; i++) {
      let fd = schema[i];
      if (fd.required) {
        if (!errors.hasOwnProperty(fd.fieldName) || errors[fd.fieldName]) {
          return false;
        }
      } else {
        if (errors.hasOwnProperty(fd.fieldName) && errors[fd.fieldName]) {
          return false;
        }
      }
    }
    return true;
  };

  const submitForm = () => {
    setButtonLoading(true);

    setTimeout(() => {
      setButtonLoading(false);
      setShareSuccess(true);
    }, 1000);
    setTimeout(() => props.onSuccess(), 3000);
  };

  return (
    <Grid centered columns={1}>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding">
          <label>Recipient Email</label>
          <Input
            fluid
            type="email"
            placeholder="Enter email address"
            name="email"
            error={errors.email}
            onChange={onChange}
          />
          {errors.email && <span className="error-text">Email is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding notes">
          <label>Note</label>
          <TextArea
            name="memo"
            className="control"
            placeholder="Add a note here"
            onChange={onChange}
          />
          {errors.memo && <span className="error-text">Note is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <Button fluid basic onClick={props.onSuccess}>
            Cancel
          </Button>
        </Grid.Column>
        <Grid.Column width={6} className="noPadding noMargin">
          <Button
            loading={buttonLoading}
            icon
            fluid
            primary
            disabled={!submit}
            onClick={submitForm}
          >
            {shareSuccess ? <Icon name="check" /> : `Send`}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export { TITLE, FORM, SCHEMA };
