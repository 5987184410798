import { useEffect, useState } from 'react';
import AccountsAggregate from './Aggregate.jsx';
import { NavLink, Route, Switch } from 'react-router-dom';
import AccountBalances from './Balances.jsx';
import AccountLedger from './Ledger.jsx';
import { privateGet, ROUTES } from '../Utilities/restApi';
import { getUserToken } from '../../js/Auth';

const AccountIndex = (props) => {
  const exchangeList = {
    Bitfinex: {
      type: 'exchange',
      totals: '$25,393,333.23',
      wallets: {
        USD: {
          name: 'US Dollars',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BTC: {
          name: 'Bitcoin',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        USDT: {
          name: 'Tether',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        EOS: {
          name: 'EOS',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XAUT: {
          name: 'Tether Gold',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BSV: {
          name: 'Bitcoin SV',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        ETH: {
          name: 'Ethereum',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        DOT: {
          name: 'Polkadot',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XTZ: {
          name: 'Tezos',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
      },
    },
    FTX: {
      type: 'exchange',
      totals: '$25,393,333.23',
      wallets: {
        USD: {
          name: 'US Dollars',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BTC: {
          name: 'Bitcoin',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        USDT: {
          name: 'Tether',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        EOS: {
          name: 'EOS',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XAUT: {
          name: 'Tether Gold',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BSV: {
          name: 'Bitcoin SV',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        ETH: {
          name: 'Ethereum',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        DOT: {
          name: 'Polkadot',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XTZ: {
          name: 'Tezos',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
      },
    },
    Bitmex: {
      type: 'exchange',
      totals: '$25,393,333.23',
      wallets: {
        USD: {
          name: 'US Dollars',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BTC: {
          name: 'Bitcoin',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        USDT: {
          name: 'Tether',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        EOS: {
          name: 'EOS',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XAUT: {
          name: 'Tether Gold',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BSV: {
          name: 'Bitcoin SV',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        ETH: {
          name: 'Ethereum',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        DOT: {
          name: 'Polkadot',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XTZ: {
          name: 'Tezos',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
      },
    },
    Deribit: {
      type: 'exchange',
      totals: '$25,393,333.23',
      wallets: {
        USD: {
          name: 'US Dollars',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BTC: {
          name: 'Bitcoin',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        USDT: {
          name: 'Tether',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        EOS: {
          name: 'EOS',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XAUT: {
          name: 'Tether Gold',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BSV: {
          name: 'Bitcoin SV',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        ETH: {
          name: 'Ethereum',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        DOT: {
          name: 'Polkadot',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XTZ: {
          name: 'Tezos',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
      },
    },
    B2C2: {
      type: 'exchange',
      totals: '$25,393,333.23',
      wallets: {
        USD: {
          name: 'US Dollars',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BTC: {
          name: 'Bitcoin',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        USDT: {
          name: 'Tether',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        EOS: {
          name: 'EOS',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XAUT: {
          name: 'Tether Gold',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BSV: {
          name: 'Bitcoin SV',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        ETH: {
          name: 'Ethereum',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        DOT: {
          name: 'Polkadot',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XTZ: {
          name: 'Tezos',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
      },
    },
    'FTX-OTC': {
      type: 'exchange',
      totals: '$25,393,333.23',
      wallets: {
        USD: {
          name: 'US Dollars',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BTC: {
          name: 'Bitcoin',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        USDT: {
          name: 'Tether',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        EOS: {
          name: 'EOS',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XAUT: {
          name: 'Tether Gold',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BSV: {
          name: 'Bitcoin SV',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        ETH: {
          name: 'Ethereum',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        DOT: {
          name: 'Polkadot',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XTZ: {
          name: 'Tezos',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
      },
    },
    'Jane St': {
      type: 'exchange',
      totals: '$25,393,333.23',
      wallets: {
        USD: {
          name: 'US Dollars',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BTC: {
          name: 'Bitcoin',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        USDT: {
          name: 'Tether',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        EOS: {
          name: 'EOS',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XAUT: {
          name: 'Tether Gold',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BSV: {
          name: 'Bitcoin SV',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        ETH: {
          name: 'Ethereum',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        DOT: {
          name: 'Polkadot',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XTZ: {
          name: 'Tezos',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
      },
    },
    Cumberland: {
      type: 'exchange',
      totals: '$25,393,333.23',
      wallets: {
        USD: {
          name: 'US Dollars',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BTC: {
          name: 'Bitcoin',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        USDT: {
          name: 'Tether',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        EOS: {
          name: 'EOS',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XAUT: {
          name: 'Tether Gold',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        BSV: {
          name: 'Bitcoin SV',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        ETH: {
          name: 'Ethereum',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        DOT: {
          name: 'Polkadot',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
        XTZ: {
          name: 'Tezos',
          trading: '23.373919',
          margin: '23.373919',
          funding: '0.383830',
          derivatives: '-',
          credit: '37.939393',
          totalInKind: '53.383839',
          totalUSD: '$23,132,578.58',
        },
      },
    },
  };
  const bankList = {
    BMO: {
      type: 'bank',
      totals: '$25,393,333.23',
      accounts: {
        CAD: '$1,322,842.34',
        USD: '$55,292.23',
        GBP: '£32,828.22',
        EUR: '€83,637.23',
        CHF: '₣97.85',
      },
    },
    Silvergate: {
      type: 'bank',
      totals: '$25,393,333.23',
      accounts: {
        USD: '$55,292.23',
        CAD: '$1,322,842.34',
        GBP: '£32,828.22',
        EUR: '€83,637.23',
        CHF: '₣97.85',
      },
    },
    Deltec: {
      type: 'bank',
      totals: '$25,393,333.23',
      accounts: {
        USD: '$55,292.23',
        CAD: '$1,322,842.34',
        GBP: '£32,828.22',
        EUR: '€83,637.23',
        CHF: '₣97.85',
      },
    },
  };
  const aggregateTotals = {
    totalUSD: '$125,393,333.23',
    totals: {
      USD: '$23,132,578.58',
      CAD: '$23,132,578.58',
      GBP: '$23,132,578.58',
      BTC: '$23,132,578.58',
      USDT: '$23,132,578.58',
      EOS: '$23,132,578.58',
      XAUT: '$23,132,578.58',
      BSV: '$23,132,578.58',
      ETH: '$23,132,578.58',
    },
  };
  const [exchanges, setExchanges] = useState();
  const apiHeaders = {
    headers: { Authorization: `${getUserToken()}` },
  };

  useEffect(() => {
    async function getExchanges() {
      try {
        const resp = await privateGet(ROUTES.EXCHANGES, apiHeaders);
        console.log(`response exchanges`, resp);
        setExchanges(resp.data.data);
      } catch (e) {
        console.error(`Failed to fetch exchanges`, e);
      }
    }
    setTimeout(() => {
      getExchanges();
    }, 5000);
  }, [exchanges]);

  return (
    <div className="accounts-index">
      <div className="tabs">
        <NavLink exact activeClassName="active" className="tab" to={`${props.match.path}/`}>
          Aggregate
        </NavLink>
        {exchanges &&
          Object.keys(exchanges.meta.wallets)
            .filter((k) => typeof exchanges.meta.wallets[k] === 'object')
            .map((exchange, index) => {
              return (
                <NavLink
                  key={index}
                  activeClassName="active"
                  className="tab"
                  to={{
                    pathname: `/accounts/exchange/${exchange}`,
                  }}
                >
                  {exchange.charAt(0).toUpperCase() + exchange.slice(1)}
                </NavLink>
              );
            })}
        {/*{bankList &&*/}
        {/*  Object.keys(bankList)*/}
        {/*    .filter((k) => typeof bankList[k] === 'object')*/}
        {/*    .map((bank, index) => {*/}
        {/*      return (*/}
        {/*        <NavLink*/}
        {/*          key={index}*/}
        {/*          activeClassName="active"*/}
        {/*          className="tab"*/}
        {/*          to={{*/}
        {/*            pathname: `/accounts/bank/${bank}/${Object.keys(bankList[bank].accounts)[0]}`,*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {bank}*/}
        {/*        </NavLink>*/}
        {/*      );*/}
        {/*    })}*/}
      </div>
      <div className="accounts-tab">
        <Switch>
          <Route
            exact
            path={`${props.match.path}/`}
            render={(props) =>
              exchanges && (
                <AccountsAggregate
                  {...props}
                  aggregate={aggregateTotals}
                  exchangeList={exchanges}
                  bankList={bankList}
                />
              )
            }
          />
          <Route
            path={`${props.match.path}/exchange/:account/:currency`}
            render={(props) =>
              exchanges && <AccountLedger {...props} exchangeList={exchanges} type="exchange" />
            }
          />
          <Route
            path={`${props.match.path}/exchange/:account`}
            render={(props) =>
              exchanges && <AccountBalances {...props} exchangeList={exchanges} type="exchange" />
            }
          />
          <Route
            path={`${props.match.path}/bank/:account/:currency`}
            render={(props) => <AccountLedger {...props} bankList={bankList} type="bank" />}
          />
          <Route
            path={`${props.match.path}/bank/:account`}
            render={(props) => <AccountLedger {...props} bankList={bankList} type="bank" />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default AccountIndex;
