import React, { Component } from 'react';
import OrderForm from './Components/Order.jsx';
import Exposures from './Components/Exposures/Index.jsx';
import Dashboard from './Components/Dashboard.jsx';
import AccountsIndex from './Components/Accounts/Index.jsx';
import AccountsAggregate from './Components/Accounts/Aggregate';
import Reconciliation from './Components/Accounts/Reconciliations.jsx';
import Login from './Components/Login.jsx';
import Container from './Components/Container.jsx';
import Counterparties from './Components/Counterparties/Counterparties.jsx';
import Nav from './Components/Nav.jsx';
import { isLoggedIn } from './js/Auth';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Banks from './Components/Banks/Banks.jsx';
import Wallets from './Components/Wallets.jsx';
import Balances from './Components/Otc/Balances.jsx';
import OtcIndex from './Components/Otc/Index.jsx';
import Commitments from './Components/Otc/Commitments.jsx';
import TradeIndex from './Components/Otc/Trade/Index.jsx';
import ExposureIndex from './Components/Exposures/Index.jsx';
import ExposureDetails from './Components/Exposures/ExposureDetails.jsx';
import { Redirect } from 'react-router';
import { isAllowed } from './Components/Utilities/core.jsx';
import ExposurePositions from './Components/Exposures/Positions';
import PositionsIndex from './Components/Positions/Index.jsx';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Container>
          <link
            rel="stylesheet"
            href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"
          />
          <link
            rel="stylesheet"
            href="https://i.icomoon.io/public/5662a48277/OSFDashboard/style.css"
          />

          <Route
            path="/"
            render={(props) =>
              !isLoggedIn() ? (
                <div className="App-body">
                  {/*<Login {...props} />*/}
                  <Route path={'/'} component={Login} />
                </div>
              ) : (
                <>
                  <Nav {...props} />
                  <div className="App-body">
                    <Switch>
                      <Route exact path={`/`}>
                        <Redirect to={'/otc'} />
                      </Route>
                      {/*<Route exact path={`/dashboard`} component={Dashboard} />*/}

                      <Route
                        path={`/accounts`}
                        render={() =>
                          isAllowed() ? <Route component={AccountsIndex} /> : <Redirect to="/" />
                        }
                      />
                      <Route
                        path={`/accounts/aggregate`}
                        render={() =>
                          isAllowed() ? (
                            <Route component={AccountsAggregate} />
                          ) : (
                            <Redirect to="/" />
                          )
                        }
                      />
                      {/*<Route path={`/accounts/reconciliation`} component={Reconciliation} />*/}

                      {/*<Route path={`/order`} component={OrderForm} />*/}

                      <Route path={`/exposures`} component={ExposureIndex} />
                      {/*<Route path={`/exposures/aggregate`} component={ExposureDetails} />*/}
                      <Route path={`/positions`} render={() => isAllowed() ? <Route component={PositionsIndex} /> : <Redirect to="/" /> } />

                      <Route path={`/otc`} component={OtcIndex} />
                      <Route path={`/otc/balances`} component={Balances} />
                      <Route path={`/otc/commitments`} component={Commitments} />
                      <Route path={`/otc/trade`} component={TradeIndex} />

                      <Route
                        path={`/counterparties`}
                        render={() => (isAllowed() ? <Counterparties /> : '')}
                      />
                      <Route path={`/banks`} render={() => (isAllowed() ? <Banks /> : '')} />
                      <Route path={`/wallets`} render={() => (isAllowed() ? <Wallets /> : '')} />
                    </Switch>
                  </div>
                </>
              )
            }
          />
        </Container>
      </BrowserRouter>
    );
  }
}

export default App;
