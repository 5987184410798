import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Grid, Input, Modal, TextArea } from 'semantic-ui-react';
import { CURRENCIES, TRANSACTION_TYPE } from '../Utilities/forex';
import { PATTERN, FIELD_TYPE, validateField } from '../Utilities/core';
import { REST_CLIENTS_TYPE, createClient } from '../../rest';

const TITLE = 'Payment Detail';

const SCHEMA = [
  { fieldName: 'partyId', fieldtype: FIELD_TYPE.DROPDOWN, required: true },
  {
    fieldName: 'paymentDate',
    fieldRule: PATTERN.SAFE_CHARS,
    fieldtype: FIELD_TYPE.STRING,
    required: true,
  },
  { fieldName: 'transactionType', fieldtype: FIELD_TYPE.DROPDOWN, required: true },
  {
    fieldName: 'financeAmt',
    fieldRule: PATTERN.DECIMAL,
    fieldtype: FIELD_TYPE.NUMBER,
    required: false,
  },
  { fieldName: 'currency', fieldtype: FIELD_TYPE.DROPDOWN, required: true },
  { fieldName: 'financingTo', fieldtype: FIELD_TYPE.DROPDOWN, required: false },
  {
    fieldName: 'settlementAmt',
    fieldRule: PATTERN.DECIMAL,
    fieldtype: FIELD_TYPE.NUMBER,
    required: false,
  },
  { fieldName: 'settledFrom', fieldtype: FIELD_TYPE.DROPDOWN, required: false },
  { fieldName: 'settledTo', fieldtype: FIELD_TYPE.DROPDOWN, required: false },
  { fieldName: 'note', fieldRule: PATTERN.SAFE_CHARS, fieldtype: FIELD_TYPE.TEXT_AREA },
];

const FORM = (props) => {
  const [post, setPost] = useState(props.data || {});
  const [errors, setErrors] = useState(
    Object.assign({}, ...Object.keys(props.data || {}).map((k) => ({ [k]: false })))
  );
  const [submit, setSubmit] = useState(false);
  const [apiAlert, setApiAlert] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [counterparties, setCounterparties] = useState([]);
  const [partyAccts, setPartyAccts] = useState([]);
  const [orgAccts, setOrgAccts] = useState([]);
  const [active, setActive] = useState(props.data ? props.data.transactionType : '');

  useEffect(() => setSubmit(isValidForm()), [errors]);

  useEffect(() => {
    async function getCounterparties() {
      try {
        const resp = await createClient(REST_CLIENTS_TYPE.Party).getAll();
        const respAccounts = await createClient(REST_CLIENTS_TYPE.Acct).get({
          organization: 'PARADOX'
        });
        console.log('organization accounts set', respAccounts);
        setCounterparties(resp);
        setOrgAccts(respAccounts);
      } catch (e) {
        console.error('Failed to fetch counterparties', e);
      }
    }
    getCounterparties();
  }, []);

  useEffect(() => {
    async function getPartyAccts() {
      if (post['partyId']) {
        try {
          const acctResp = await createClient(REST_CLIENTS_TYPE.Acct).getAll();
          const filteredResp = acctResp.filter((x) => x.partyId === post['partyId']);
          setPartyAccts(filteredResp);
          console.log('Received trades filtered party accounts', filteredResp);
          setPost({ settledFrom: undefined, ...post });
        } catch (e) {
          console.error('account options not set', e);
        }
      }
    }
    getPartyAccts();
  }, [post['partyId']]);

  const getCounterpartiesList = () => {
    if (!counterparties) return [];
    return counterparties
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .map((x) => {
        return { name: x.name, value: x.id, text: x.name };
      });
  };

  const getSelectedCounterpartyOptions = () => {
    return partyAccts.map((x) => {
      return {
        name: x.name,
        value: x.id,
        text: `${x.title} ${x.name} ${x.currency} ${x.accountNumber}`,
      };
    });
  };

  const getOrgAccountOptions = () => {
    return orgAccts
      .filter((x) => filterByCurrency(x.currency))
      .map((x) => {
        return {
          name: x.name,
          value: x.id,
          text: `${x.title} ${x.name} ${x.currency} ${x.accountNumber}`,
        };
      });
  };

  const filterByCurrency = (curr) => {
    if (!post.currency) return true;
    if (curr === post.currency) return true;
  };

  const onChange = (e, { name, value }) => {
    setPost({ ...post, [name]: value });
    setErrors(validateField(name, value, props.schema, errors));
    if (name === 'transactionType' && value === 'Financing') setActive('Financing');
    if (name === 'transactionType' && value === 'Settlement') setActive('Settlement');
    if (name === 'partyId') getSelectedCounterpartyOptions(name);
    if (name === 'currency' && post.settledFrom)
      setPost({ ...post, settledFrom: null, currency: value });
  };

  const isValidForm = () => {
    const schema = props.schema || [];
    for (let i = 0; i < schema.length; i++) {
      let fd = schema[i];
      if (fd.required) {
        if (!errors.hasOwnProperty(fd.fieldName) || errors[fd.fieldName]) {
          return false;
        }
      } else {
        if (errors.hasOwnProperty(fd.fieldName) && errors[fd.fieldName]) {
          return false;
        }
      }
    }
    return true;
  };

  const submitForm = () => {
    setButtonLoading(true);
    if (props.data) post.id = props.uuid;
    if (props.data) {
      createClient(REST_CLIENTS_TYPE.Otc)
        .updatePayment(post)
        .then(props.onSuccess)
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to update payment ${post}`, e);
          setApiAlert(e.message);
        });
    } else {
      createClient(REST_CLIENTS_TYPE.Otc)
        .createPayment(post)
        .then(props.onSuccess)
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to create payment ${post}`, e);
          setApiAlert(e.message);
        });
    }
  };

  return (
    <Grid centered columns={1}>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error saving data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Counterparty</label>
          <Dropdown
            selection
            fluid
            search
            name="partyId"
            value={post.partyId}
            placeholder="Counterparty"
            options={getCounterpartiesList()}
            onChange={onChange}
          />
          {errors.partyId && <span className="error-text">Counterparty is required</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Transaction Date</label>
          <Input
            error={errors.paymentDate}
            fluid
            type="date"
            name="paymentDate"
            value={post.paymentDate}
            onChange={onChange}
          />
          {errors.paymentDate && <span className="error-text">Transaction Date is required</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding">
          <label>Transaction Type</label>
          <Dropdown
            selection
            fluid
            name="transactionType"
            value={post.transactionType}
            placeholder="Select Transaction Type"
            options={TRANSACTION_TYPE}
            onChange={onChange}
          />
          {errors.transactionType && (
            <span className="error-text">transactionType is required</span>
          )}
        </Grid.Column>
      </Grid.Row>
      {active === 'Settlement' && (
        <>
          <Grid.Row>
            <Grid.Column width={9} className="noPadding">
              <label>Settlement Amount</label>
              <Input
                error={errors.settlementAmt}
                fluid
                className="control"
                name="settlementAmt"
                placeholder="Settlement Amount"
                type="number"
                value={post.settlementAmt}
                onChange={onChange}
              />
              {errors.settlementAmt && <span className="error-text">Settlement Amount is invalid</span>}
            </Grid.Column>
            <Grid.Column width={3} className="noPadding">
              <label>Currency</label>
              <Dropdown
                selection
                search
                selectOnBlur={false}
                clearable
                fluid
                name="currency"
                options={CURRENCIES}
                placeholder="BTC"
                value={post.currency}
                onChange={onChange}
              />
              {errors.currency && <span className="error-text">Settlement Amount is invalid</span>}
            </Grid.Column>
          </Grid.Row>
          {orgAccts && (
            <Grid.Row>
              <Grid.Column width={12} className="noPadding noMargin">
                <label>Settled from this venue</label>
                <Dropdown
                  selection
                  fluid
                  name="settledFrom"
                  placeholder="Settled From"
                  options={getOrgAccountOptions()}
                  value={post.settledFrom}
                  onChange={onChange}
                />
                {errors.settledFrom && (
                  <span className="error-text">Please Select Settlement Venue</span>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
          {partyAccts && (
            <Grid.Row>
              <Grid.Column width={12} className="noPadding noMargin">
                <label>Settled to this venue</label>
                <Dropdown
                  selection
                  fluid
                  name="settledTo"
                  placeholder="Settled to this venue"
                  options={getSelectedCounterpartyOptions()}
                  value={post.settledTo}
                  onChange={onChange}
                />
                {errors.settledTo && (
                  <span className="error-text">Please Select Settled to this Venue</span>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
        </>
      )}
      {active === 'Financing' && (
        <>
          <Grid.Row>
            <Grid.Column width={9} className="noPadding">
              <label>Financing Amount</label>
              <Input
                error={errors.financeAmt}
                fluid
                className="control"
                name="financeAmt"
                placeholder="Financing Amount"
                type="number"
                value={post.financeAmt}
                onChange={onChange}
              />
              {errors.financeAmt && (
                <span className="error-text">Financing Amount is required</span>
              )}
            </Grid.Column>
            <Grid.Column width={3} className="noPadding">
              <label>Currency</label>
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                search
                fluid
                name="currency"
                options={CURRENCIES}
                placeholder="USD"
                value={post.currency}
                onChange={onChange}
              />
              {errors.currency && (
                <span className="error-text">Financing Currency is invalid</span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} className="noPadding noMargin">
              <label>Financed to this venue</label>
              <Dropdown
                selection
                fluid
                name="financingTo"
                placeholder="Financed to this venue"
                options={getOrgAccountOptions()}
                value={post.financingTo}
                onChange={onChange}
              />
              {errors.financingTo && (
                <span className="error-text">Please Select Financed to this Venue</span>
              )}
            </Grid.Column>
          </Grid.Row>
        </>
      )}
      <Grid.Row>
        <Grid.Column width={12} className="noPadding notes">
          <label>Notes</label>
          <TextArea
            name="note"
            className="control"
            placeholder="Add a note here"
            error={errors.note}
            value={post.note}
            onChange={onChange}
          />
          {errors.note && <span className="error-text">Note is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding noMargin">
          <Button loading={buttonLoading} fluid primary disabled={!submit} onClick={submitForm}>
            {props.data ? 'Update ' : 'Create '}Payment Detail
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export { TITLE, FORM, SCHEMA };
