import config from './config'

export type UIConfig = {
  cognito: {
      poolId: string,
      clientId: string
  },
}

class GlobalConfig {
  configuration: UIConfig | undefined;

  set config(cfg: UIConfig) {
    if(!cfg) {
      throw new Error("Invalid config assignment")
    }
    this.configuration = cfg 
  }
  get config() : UIConfig {
    if(!this.configuration) {
      throw new Error('Configuration accessed before assignment')
    }
    return this.configuration
  }
}
export const configUrl = config.SMRT_API + '/config'
export const dynamic = new GlobalConfig()