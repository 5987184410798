import React from 'react';
import { scaleDownNumber, highlightDiffFont } from '../../utils/numberFormat.js'


// route: /getOrganizationOverview
// params: &organizations=["paradox"]
//         &categories=["equity","risk","margin_health","carry_costs"]

var data = {
  "paradox": {
    "equity": {
      "total": {
        "value": 12105274.24,
        "24h_chg": 1112.3,
        "type": "USD"
      },
      "gains_since_inception": {
        "value": 23212.12,
        "24h_chg": 12.3,
        "type": "USD"
      },
      "roi_yr": {
        "value": 6.66,
        "24h_chg": 12.3,
        "type": "percentage"
      }
    },
    "risk": {
      "cvar_99.9": {
        "value": 6.66,
        "24h_chg": 12.3,
        "type": "percentage"
      },
      "portfolio_std_percent": {
        "value": 6.66,
        "24h_chg": 12.3,
        "type": "percentage"
      }
    },
    "carry_costs": {
      "previous_interest_24h": {
        "value": -104.51,
        "24h_chg": 12.3,
        "type": "USD"
      },
      "expected_interest_24h": {
        "value": 1560,
        "24h_chg": 12.3,
        "type": "USD"
      }
    },
    "margin_health": {
      "margin_used": {
        "value": 21.25,
        "24h_chg": 12.3,
        "type": "percentage"
      },
      "leverage": {
        "value": 1.25,
        "24h_chg": 12.3,
        "type": "multiplier"
      }
    }
  }
}




export default class Perofrmance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
  }


  render() {
    return (
      <div className="PerformanceWidget Dashboard-block">
        {Object.keys(data).map((key)=>(
          <div key={key}>
            <table cellPadding="0" cellSpacing="0" className="OrganizationsBlock">
              <tbody className="headerBlock">
                <tr className="valuesRow">
                  <td>
                    <span className="value" dangerouslySetInnerHTML={{__html: highlightDiffFont(scaleDownNumber(data[key].equity.total.value), 0, 2, "$","")}}></span>
                    <span className="label">Equity</span>
                  </td>
                  <td>
                    <span className={`chgValue ${data[key].equity.total["24h_chg"] > 0 ? "green" : "red"}`}>{data[key].equity.total["24h_chg"]}</span><br/>
                    <span className={`chgValue ${((data[key].equity.total["24h_chg"] / data[key].equity.total.value) * 100).toFixed(2) > 0 ? "green" : "red"}`}>{((data[key].equity.total["24h_chg"] / data[key].equity.total.value) * 100).toFixed(2) + "%"}</span>
                  </td>
                </tr>
              </tbody>
              <tbody className="rowBlock">
                <tr>
                  <td className="label">Performance</td>
                  <td></td>
                </tr>
                <tr className="valuesRow">
                  <td>
                    <span className="value" dangerouslySetInnerHTML={{__html: highlightDiffFont(scaleDownNumber(data[key].equity.gains_since_inception.value), 0, 2, "$","")}}></span>
                    <span className={`chgValue ${((data[key].equity.gains_since_inception["24h_chg"] / data[key].equity.gains_since_inception.value) * 100).toFixed(2) > 0 ? "green" : "red"}`}>{((data[key].equity.gains_since_inception["24h_chg"] / data[key].equity.gains_since_inception.value) * 100).toFixed(2) + "%"}</span>
                    <span className="label">Total gains</span>
                  </td>
                  <td>
                  <span className="value" dangerouslySetInnerHTML={{__html: highlightDiffFont(data[key].equity.roi_yr.value, 0, 2, "","%")}}></span>
                  <span className={`chgValue ${((data[key].equity.roi_yr["24h_chg"] / data[key].equity.roi_yr.value) * 100).toFixed(2) > 0 ? "green" : "red"}`}>{((data[key].equity.roi_yr["24h_chg"] / data[key].equity.roi_yr.value) * 100).toFixed(2) + "%"}</span>
                    <span className="label">ROI / YR</span>
                  </td>
                </tr>
              </tbody>
              <tbody className="rowBlock">
                <tr>
                  <td className="label">Portfolio Risk</td>
                  <td></td>
                </tr>
                <tr className="valuesRow">
                  <td>
                    <span className="value" dangerouslySetInnerHTML={{__html: highlightDiffFont(data[key].risk["cvar_99.9"].value, 0, 2, "","%")}}></span>
                    <span className={`chgValue ${((data[key].risk["cvar_99.9"]["24h_chg"] / data[key].risk["cvar_99.9"].value) * 100).toFixed(2) > 0 ? "green" : "red"}`}>{((data[key].risk["cvar_99.9"]["24h_chg"] / data[key].risk["cvar_99.9"].value) * 100).toFixed(2) + "%"}</span>
                    <span className="label">CVAR 99.9</span>
                  </td>
                  <td>
                  <span className="value" dangerouslySetInnerHTML={{__html: highlightDiffFont(data[key].risk.portfolio_std_percent.value, 0, 2, "","%")}}></span>
                  <span className={`chgValue ${((data[key].risk.portfolio_std_percent["24h_chg"] / data[key].risk["cvar_99.9"].value) * 100).toFixed(2) > 0 ? "green" : "red"}`}>{((data[key].risk.portfolio_std_percent["24h_chg"] / data[key].risk["cvar_99.9"].value) * 100).toFixed(2) + "%"}</span>
                    <span className="label">ST. DEV.</span>
                  </td>
                </tr>
              </tbody>
              <tbody className="rowBlock">
                <tr>
                  <td className="label">Margin Health</td>
                  <td></td>
                </tr>
                <tr className="valuesRow">
                  <td>
                    <span className="value" dangerouslySetInnerHTML={{__html: highlightDiffFont(scaleDownNumber(data[key].margin_health.margin_used.value), 0, 2, "$","")}}></span>
                    <span className={`chgValue ${((data[key].margin_health.margin_used["24h_chg"] / data[key].margin_health.margin_used.value) * 100).toFixed(2) > 0 ? "green" : "red"}`}>{((data[key].margin_health.margin_used["24h_chg"] / data[key].margin_health.margin_used.value) * 100).toFixed(2) + "%"}</span>
                    <span className="label">Margin Used</span>
                  </td>
                  <td>
                  <span className="value" dangerouslySetInnerHTML={{__html: highlightDiffFont(data[key].margin_health.leverage.value, 0, 2, "","x")}}></span>
                  <span className={`chgValue ${((data[key].margin_health.leverage["24h_chg"] / data[key].margin_health.leverage.value) * 100).toFixed(2) > 0 ? "green" : "red"}`}>{((data[key].margin_health.leverage["24h_chg"] / data[key].margin_health.leverage.value) * 100).toFixed(2) + "%"}</span>
                    <span className="label">Leverage</span>
                  </td>
                </tr>
              </tbody>
              <tbody className="rowBlock">
                <tr>
                  <td className="label">Carry Costs</td>
                  <td></td>
                </tr>
                <tr className="valuesRow">
                  <td>
                    <span className={`value ${data[key].carry_costs.previous_interest_24h.value > 0 ? "green": "red"}`} dangerouslySetInnerHTML={{__html: highlightDiffFont(scaleDownNumber(data[key].carry_costs.previous_interest_24h.value), 0, 2, "$","")}}></span>
                    <span className="label">Last 24h</span>
                  </td>
                  <td>
                    <span className={`value ${data[key].carry_costs.expected_interest_24h.value > 0 ? "green": "red"}`} dangerouslySetInnerHTML={{__html: highlightDiffFont(scaleDownNumber(data[key].carry_costs.expected_interest_24h.value), 0, 2, "$","")}}></span>
                    <span className="label">Expected 24h</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
   )
  }
}

