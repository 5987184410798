import { Grid, Icon, Table } from 'semantic-ui-react';
import { ICONS } from '../Utilities/forex';
import { publicGet } from '../Utilities/restApi';
import { useState } from 'react';

const AccountsAggregate = (props) => {
  const exchanges = props.exchangeList;
  const banks = props.bankList;
  const aggregate = props.aggregate;

  const symbols = Array.from(new Set(exchanges.wallets.map((x) => x.currency.symbol)));

  const vendors = Array.from(new Set(exchanges.wallets.map((x) => x.vendor)));

  const totals = exchanges && exchanges.wallets.reduce((acc, { amount }) => acc + amount.value, 0);
  // const [totalsInUSD, setTotalsInUSD] = useState({});
  // const getValueInUSD = async (currency, amount) => {
  //   try {
  //     const apiUrl =
  //       'https://api-staging.osfmanagement.com/api-pub/v2/tickers/rates?base=' +
  //       currency +
  //       '&counter=USD';
  //     const resp = await publicGet(apiUrl);
  //     console.log(`response conversion`, resp.data.data);
  //     setTotalsInUSD({ [currency]: resp.data.data.price * amount }, ...totalsInUSD);
  //   } catch (e) {
  //     console.error(`Failed to fetch rate`, e);
  //   }
  // };

  let vendorTotals = symbols.map((c) => {
    let total = 0;
    let obj = exchanges.wallets
      .filter((x) => x.currency.symbol === c)
      .map((x) => {
        total = total + x.amount.value;
        return {
          [`${x.vendor}#${x.vendor_wallet}`]: x.amount.value,
        };
      });
    obj = Object.assign({}, ...obj);
    if (total === 0) return;
    return {
      [c]: obj,
    };
  });

  vendorTotals = Object.assign({}, ...vendorTotals);

  console.log('unique totals', vendorTotals);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div className="aggregate">
            <div className="title">Aggregate totals - {totals}</div>
            <Table basic singleLine selectable fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Symbol</Table.HeaderCell>
                  {exchanges &&
                    Object.keys(exchanges.meta.wallets)
                      .filter((k) => typeof exchanges.meta.wallets[k] === 'object')
                      .map((vendor, index) => {
                        return <Table.HeaderCell key={index}>{vendor}</Table.HeaderCell>;
                      })}
                  {/*{banks &&*/}
                  {/*  Object.keys(banks)*/}
                  {/*    .filter((k) => typeof banks[k] === 'object')*/}
                  {/*    .map((account, index) => {*/}
                  {/*      return <Table.HeaderCell key={index}>{account}</Table.HeaderCell>;*/}
                  {/*    })}*/}
                  <Table.HeaderCell>Total USD</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  {exchanges &&
                    Object.keys(exchanges.meta.wallets).map((key, index) => {
                      // return <Table.Cell key={index}>{vendorTotals[key].total}</Table.Cell>;
                    })}
                  {/*{banks &&*/}
                  {/*  Object.keys(banks).map((key, index) => {*/}
                  {/*    return <Table.Cell key={index}>{banks[key].totals}</Table.Cell>;*/}
                  {/*  })}*/}
                  <Table.Cell>{totals}</Table.Cell>
                </Table.Row>

                {vendorTotals &&
                  Object.keys(vendorTotals).map((currency, index) => {
                    console.log('unique keys', vendorTotals[currency]);
                    return (
                      <Table.Row key={index}>
                        <Table.Cell className="symbol">
                          <Icon
                            className={
                              ICONS.find((icon) => icon === currency)
                                ? `icon-${currency}`
                                : `icon-default`
                            }
                          >
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                            <span className="path5"></span>
                            <span className="path6"></span>
                            <span className="path7"></span>
                            <span className="path8"></span>
                          </Icon>
                          {currency}
                        </Table.Cell>
                        {exchanges.wallets
                          .filter((x) => x.amount !== 0)
                          .map((account, i) => {
                            // console.log('table data', account);
                            // return <Table.Cell key={i}>{account.amount}</Table.Cell>;
                          })}
                        {/*{Object.keys(banks).map((account, i) => {*/}
                        {/*  return (*/}
                        {/*    <Table.Cell key={i}>*/}
                        {/*      {banks[account].accounts[currency]*/}
                        {/*        ? banks[account].accounts[currency]*/}
                        {/*        : '-'}*/}
                        {/*    </Table.Cell>*/}
                        {/*  );*/}
                        {/*})}*/}
                        <Table.Cell>{aggregate.totals[currency]}</Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default AccountsAggregate;
