import React, { useEffect, useState } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { privateGet, ROUTES } from '../Utilities/restApi';
import Positions from './Positions';
import { getUserToken } from '../../js/Auth';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';

const PositionsIndex = (props) => {
  const [positions, setPositions] = useState();

  const apiHeaders = {
    headers: { Authorization: `${getUserToken()}` },
  };

  useEffect(() => {
    async function getPositions() {
      try {
        const resp = await privateGet(ROUTES.POSITIONSPAGE, apiHeaders);
        console.log(`response positions`, resp.data.data.positions);

        setPositions(resp.data.data);
      } catch (e) {
        console.error(`Failed to fetch positions`, e);
      }
    }
    setTimeout(() => {
      getPositions();
    }, 3000);
  }, [positions]);

  return (
    <div className="positions-index">
      <div className="tabs">
        <NavLink exact activeClassName="active" className="tab" to={`${props.match.url}/`}>
          Positions
        </NavLink>
      </div>
      <div className="positions-tab">
        <Switch>
          {/*<Route exact path={`${props.match.path}/`} component={Positions} />*/}
          <Route
            exact
            path={`${props.match.path}/`}
            render={(props) => positions && <Positions {...props} data={positions} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default PositionsIndex;
