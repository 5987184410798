import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Grid, Input, Modal } from 'semantic-ui-react';
import { CURRENCIES, COUNTRIES } from '../Utilities/forex';
import { PATTERN, FIELD_TYPE, validateField } from '../Utilities/core';
import { REST_CLIENTS_TYPE, createClient } from '../../rest';

const TITLE = 'Intermediary Details';



const SCHEMA = [
  { fieldName: 'name', fieldRule: PATTERN.BANKNAME, fieldtype: FIELD_TYPE.STRING, required: true },
  { fieldName: 'currency', fieldtype: FIELD_TYPE.DROPDOWN, required: false },
  { fieldName: 'phone', fieldRule: PATTERN.PHONE, fieldtype: FIELD_TYPE.NUMBER },
  { fieldName: 'address', fieldRule: PATTERN.ADDRESS, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'city', fieldRule: PATTERN.CITY, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'state', fieldRule: PATTERN.STATE, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'postal', fieldRule: PATTERN.POSTAL_CODE, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'country', fieldtype: FIELD_TYPE.DROPDOWN, required: true },
  { fieldName: 'institution', fieldRule: PATTERN.INSTITUTION, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'routing', fieldRule: PATTERN.ROUTING, fieldtype: FIELD_TYPE.NUMBER },
  { fieldName: 'swift', fieldRule: PATTERN.SWIFT, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'iban', fieldRule: PATTERN.IBAN, fieldtype: FIELD_TYPE.STRING },
];

const FORM = (props) => {
  const [post, setPost] = useState(props.data || {});
  const [errors, setErrors] = useState(
    Object.assign({}, ...Object.keys(props.data || {}).map((k) => ({ [k]: false })))
  );
  const [submit, setSubmit] = useState(false);
  const [apiAlert, setApiAlert] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => setSubmit(isValidForm()), [errors]);

  const onChange = (e, { name, value }) => {
    setPost({ ...post, [name]: value });
    setErrors(validateField(name, value, props.schema, errors));
  };

  const isValidForm = () => {
    const schema = props.schema || [];
    for (let i = 0; i < schema.length; i++) {
      let fd = schema[i];
      if (fd.required) {
        if (!errors.hasOwnProperty(fd.fieldName) || errors[fd.fieldName]) {
          return false;
        }
      } else {
        if (errors.hasOwnProperty(fd.fieldName) && errors[fd.fieldName]) {
          return false;
        }
      }
    }
    return true;
  };

  const submitForm = () => {
    setButtonLoading(true);
    if (props.data) {
      post.bankId = props.data.bankId;
      createClient(REST_CLIENTS_TYPE.Intermediary)
        .update(post)
        .then(props.onSuccess)
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to update bank Intermediary records ${post}`, e);
          setApiAlert(e.message);
        });
    } else {
      post.bankId = props.bankId;
      post.currency = props.bank.currency;
      createClient(REST_CLIENTS_TYPE.Intermediary)
        .create(post)
        .then(props.onSuccess)
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to create bank Intermediary records ${post}`, e);
          setApiAlert(e.message);
        });
    }
  };

  return (
    <Grid centered columns={1}>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error saving data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Name</label>
          <Input
            error={errors.name}
            fluid
            placeholder="Bank Name"
            name="name"
            value={post.name}
            onChange={onChange}
          />
          {errors.name && <span className="error-text">Name is required</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Currency</label>
          <Dropdown
            selection
            fluid
            name="currency"
            placeholder="Select Currency"
            options={CURRENCIES}
            value={(props.bank && props.bank.currency) || post.currency}
            onChange={onChange}
            disabled
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Phone number</label>
          <Input
            error={errors.phone}
            fluid
            className="control"
            name="phone"
            placeholder="Enter Phone number"
            type="number"
            value={post.phone}
            onChange={onChange}
          />
          {errors.phone && <span className="error-text">Phone number is required</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Address</label>
          <Input
            error={errors.address}
            fluid
            className="control"
            name="address"
            placeholder="Enter Address"
            value={post.address}
            onChange={onChange}
          />
          {errors.address && <span className="error-text">Address is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>City</label>
          <Input
            error={errors.city}
            fluid
            className="control"
            name="city"
            placeholder="Enter City"
            value={post.city}
            onChange={onChange}
          />
          {errors.city && <span className="error-text">City is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Province / State</label>
          <Input
            error={errors.state}
            fluid
            className="control"
            name="state"
            placeholder="Enter Province / State"
            value={post.state}
            onChange={onChange}
          />
          {errors.state && <span className="error-text">Province is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Postal / ZIP</label>
          <Input
            error={errors.postal}
            fluid
            className="control"
            name="postal"
            placeholder="Enter Postal / Zip Code"
            value={post.postal}
            onChange={onChange}
          />
          {errors.postal && <span className="error-text">Postal is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Country</label>
          <Dropdown
            search
            fluid
            selection
            className="control"
            name="country"
            placeholder="Select Country"
            options={COUNTRIES}
            value={post.country}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Institution Number</label>
          <Input
            error={errors.institution}
            fluid
            type="number"
            className="control"
            name="institution"
            placeholder="Enter Institution Number"
            value={post.institution}
            onChange={onChange}
          />
          {errors.institution && (
            <span className="error-text">Institution number should be 3 digits</span>
          )}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Routing Number</label>
          <Input
            error={errors.routing}
            fluid
            type="number"
            className="control"
            name="routing"
            placeholder="Enter Routing Number"
            value={post.routing}
            onChange={onChange}
          />
          {errors.routing && <span className="error-text">Routing number should be 9 digits</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Swift Code / BIC</label>
          <Input
            error={errors.swift}
            fluid
            className="control"
            name="swift"
            placeholder="Enter Swift Code / BIC"
            value={post.swift}
            onChange={onChange}
          />
          {errors.swift && <span className="error-text">Swift Code is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>IBAN</label>
          <Input
            error={errors.iban}
            fluid
            className="control"
            name="iban"
            placeholder="Add IBAN if necessary"
            value={post.iban}
            onChange={onChange}
          />
          {errors.iban && <span className="error-text">IBAN is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding noMargin">
          <Button loading={buttonLoading} fluid primary disabled={!submit} onClick={submitForm}>
            {props.data ? 'Update' : 'Add'} Intermediary Details
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export { TITLE, FORM, SCHEMA };
