import React from 'react'

const LedgerBlock = (props) => {
  return (
      <div className={`block${props.isActive?" active":""}`} onClick={props.onClick}>
        <div className="blockTitle">{props.title}</div>
        <div className="blockValue">{props.value}</div>
      </div>
  )
}
export default LedgerBlock