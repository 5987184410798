import axios, { AxiosInstance } from 'axios'
import config from '../config'
import { getUserToken } from '../js/Auth'

//import * as auth from './Auth'
//import { createHashHistory } from 'history'
//const history = createHashHistory()
//api.interceptors.request.use(config => {
//  if (
//    config.url === '/authentication/authenticate'
//  ) {
//    return config
//  }
//
//  if (!auth.isLoggedIn()){
//    auth.logout().then(() => {
//      history.push('/')
//    })
//  }
//
//  config.headers.Authorization = `Basic ${localStorage.token}`
//
//  return config
//}, err => {
//  return Promise.Reject(err)
//})


export class ClientBase {
    client: AxiosInstance
    constructor() {
        const baseUrl = config.SMRT_API

        this.client = axios.create({
            baseURL : config.SMRT_API,
          })

        this.client.interceptors.request.use((reqConfig) => { 
            reqConfig.headers = {
                'Authorization': getUserToken(),
                'Content-Type': 'application/json'
            }
            return reqConfig
        }, function(error) {
            return Promise.reject(error);
        })

        this.client.interceptors.response.use((response) => {
            if(response.status != 200) {
                return Promise.reject(response.statusText)
            }
            return response
        },
        (error) => {
            return Promise.reject(error)
        })

    }

    async request(method: 'GET' | 'PUT' | 'POST' | 'DELETE', url: string, data?: any) {
        const resp = await this.client.request({
            method: method,
            url: url,
            data: data
        })
        if (resp.status !== 200) {
            throw new Error('request was not successful')
        }
        return resp

    }

    generateUuid() {
        return "abc"
    }

    log(...messages: any[]) {
        const message = messages.map(m => {
            if(typeof m === 'string') {
                return m
            } else {
            return JSON.stringify(m)
            }
        }).join(" ")
        console.log(`REST_CLIENT::${this.constructor.name}: ${message}`)
    }
}