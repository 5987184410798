// Move to Helpers.

export const ASSET_HOLDER_TYPE = { BANK: 'bank', COUNTER_PARTY: 'counterparty' }
export const ACCOUNT_TYPE = {
  WIRE: 'wire',
  CRYPTO: 'crypto',
  WALLET: 'wallet',
  INTERMEDIARY: 'intermediary',
}

export const ROLES = {
  ADMIN: 'ROLE:ADMIN',
  AUDITOR: 'ROLE:AUDITOR',
  ACCOUNTING: 'ROLE:ACCOUNTING',
}

// List of currencies and appropriate rounding
export const CurrencyConfig = {
  USD: 2,
  CAD: 2,
  EUR: 2,
  JPY: 2,
  GBP: 2,
  CHF: 2,
  BTC: 10,
  ETH: 10,
  DOGE: 10,
  BCH: 10,
  USDT: 2,
  BSV: 10,
  ADA: 10,
  ZEC: 10,
  USDC: 2,
  XMR: 10,
  XRP: 10,
  XLM: 10,
  UST: 2,
  XAUT: 10,
  EOS: 10,
  DOT: 10,
  XTZ: 10,
  BTG: 10,
  FTT: 10,
  LEO: 10,
  ETC: 10,
  DASH: 10,
  LTC: 10,
  REP: 10,
  XEM: 10
}

export const Currencies = Object.keys(CurrencyConfig)

