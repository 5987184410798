import React from 'react';
import { Grid } from 'semantic-ui-react'

var data = {
  "labels": ["BTC","USD","EUR","LTC","XMR","ETH","XRP","BCH","BSV","CAD","S&P","XAU","GBP"],
  "correlations": []
}

data.labels.forEach((val, index) => {
  if(!data.correlations[index]) {
    data.correlations[index] = []
  }
  data.labels.forEach(() => {
    data.correlations[index].push((Math.random() * 100).toFixed(0))
  })
})

export default class Correlations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
  }

  render() {
    return (
      <div className="CorrelationsWidget Dashboard-block">
        <Grid>
        <div className="titleBlock">
          Asset correlation
        </div>
        <header className="row">
        { data.labels.map((label, index) => (
              <div key={index} className="label">{label}</div> )) }
        </header>
        <section>
        {
              data.labels.map((label, index) => (
                <div key={index} className="row">
                  <div className="label">{label}</div>
                  {data.correlations[index].map((val, index2) => (
                    <div key={index2} className="value" style={{ "background": val > 40 ? `rgba(14, 173, 106, ${val/100})` : "rgb(232,232,232" }}>{val}</div>
                  ))}
                </div>
              ))
            }

        </section>
        </Grid>
      </div>
   )
  }
}

