import React, { useState } from "react";
import config from "../../../config"

let {symbols} = config

const NewQuoteForm = ({ requestQuote, counterparties }) => {
  const [baseAmount, setBaseAmount] = useState("");
  const [quoteAmount, setQuoteAmount] = useState("");
  const [baseCurrency, setBaseCurrency] = useState("BTC");
  const [quoteCurrency, setQuoteCurrency] = useState("USD");
  const [side, setSide] = useState("buy");
  const [counterparty, setCounterparty] = useState(counterparties[0])
  const [premium, setPremium] = useState(20)
  const [error, setError] = useState({})

  let symbol = symbols.find(s => s.base === baseCurrency && s.quote === quoteCurrency)

  let assets = []
  symbols.forEach(function(symbol) {
    assets.push(symbol.base)
    assets.push(symbol.quote)
  })
  assets = [...new Set(assets)]
  return (
  <div className="head-wrapper">
    <div className="container">
      <div className="tab-container">
        <div
            className={side === "buy" ? "tab selected" : "tab"}
            name="side"
            value="buy"
            onClick={(v) => setSide("buy")}
        >Buy</div>
        <span className="checkmark"></span>
        <div
            className="tab"
            className={side === "sell" ? "tab selected" : "tab"}
            value="sell"
            name="side"
            onClick={(v) => setSide("sell")}
        >Sell</div>
        <span className="checkmark"></span>
      </div>
      <div className="head-content select">
        <select
            className="input"
            defaultValue={counterparties[0]}
            onClick={(v) => setCounterparty(v.target.value)}
        >
            {counterparties.map((s) => (
            <option key={s.name}>{s.name}</option>
            ))}
        </select>
        <span className="placeholder">Counterparty</span>
      </div>
      <div className="head-content select double-input">
        {error.baseAmount ?
            <div className="input-error">{error.baseAmount}</div>
            : null
        }
        <input
            id="baseAmount"
            className="input"
            onChange={(v) => {
            let error
            if(v.target.value < symbol.minBase) {
                error = {
                "baseAmount": `Min value is ${symbol.minBase}`
                }
            } else {
                error = {}
            }
            setError(error)
            setBaseAmount(v.target.value)
            setQuoteAmount("")
            }}
            value={baseAmount}
            placeholder="Amount"
        ></input>
        <span className="placeholder">{side === "buy" ? "Buy" : "Sell"}</span>
        <span className={"icon-" + baseCurrency.toUpperCase()}>
            <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span>
        </span>
        <select
            className="input"
            onChange={(v) => {
            setBaseCurrency(v.target.value)
            }}
            value={baseCurrency}
            >
            {symbols.map(d=>{if(d.quote===quoteCurrency){ return d.base }}).filter(d => d !== undefined).map((s) => (
            <option key={s}>{s}</option>
            ))}
        </select>
      </div>
      <div className="head-content select double-input">
        {error.quoteAmount ?
            <div className="input-error">{error.quoteAmount}</div>
            : null
        }
        <input
            id="quoteAmount"
            className="input"
            onChange={(v) => {
            let error
            if(v.target.value < symbol.minQuote) {
                error = {
                "quoteAmount": `Min value is ${symbol.minQuote}`
                }
            } else {
                error = {}
            }
            setError(error)
            setQuoteAmount(v.target.value)
            setBaseAmount("")
            }}
            value={quoteAmount}
            placeholder="Amount"
        ></input>
        <span className="placeholder">{side === "buy" ? "Spend" : "Get"}</span>
        <span className={"icon-" + quoteCurrency.toUpperCase()}>
            <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span>
        </span>
        <select
            className="input"
            onChange={(v) => setQuoteCurrency(v.target.value)}
            value={quoteCurrency}
            >
            {symbols.map(d=>{
            if(d.base===baseCurrency){
            return d.quote }}).filter(d => d !== undefined).map((s) => (
            <option key={s}>{s}</option>
            ))}
        </select>
      </div>
      <div className="head-content">
        <input
            id="premium"
            className="input"
            onChange={(v) => setPremium(v.target.value)}
            value={premium}
            placeholder="Fee"
        ></input>
        <span className="placeholder">Premium bps</span>
      </div>
      <div className="head-content">
        <button
            className={Object.keys(error).length || (!baseAmount && !quoteAmount) || !counterparty || !premium ? "execute disabled" : "execute"}
            onClick={() => {
            requestQuote({ symbols, baseAmount, quoteAmount, side, partyId: counterparty.id, premium, baseCurrency, quoteCurrency });
            }}
        >
            Get quote
        </button>
      </div>
    </div>
  </div>
  )
}

export default NewQuoteForm
