import {CurrencyConfig} from '../const'
export function toFixed(value, digits) {
    const mult = Math.pow(10,digits)
    const val = Math.round((value+Number.EPSILON)*mult)/mult
    return val
}

export function roundCurrency(amount, currency) {
    const digits = CurrencyConfig[currency] 
    if (!digits) throw new Error(`No currency config for ${currency}`)
    return toFixed(amount, digits )
}