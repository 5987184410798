import { getUserRoles } from '../../js/Auth';
import { ROLES } from '../../const'

export const VALID_EMAIL_REGEX = RegExp(
  /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

export const PATTERN = {
  SAFE_CHARS: new RegExp(/^[0-9A-Za-z](.*)+/),
  ADDRESS: new RegExp(/^[0-9A-Za-z](.*)+/),
  EMAIL: new RegExp(/^[^\s@]+@[^\s@]+$/),
  CITY: new RegExp(/^[0-9A-Za-z](.*)+/),
  COUNTRY: new RegExp(/^[0-9A-Za-z](.*)+/),
  STATE: new RegExp(/^[0-9A-Za-z](.*)+/),
  POSTAL_CODE: new RegExp(/^[0-9A-Za-z](.*)+/),
  NAME: new RegExp(/^[0-9a-zA-Z,\s]{2,80}$/),
  BANKNAME: new RegExp(/^[0-9a-zA-Z,&@*-_\'\"\s]{2,80}$/),
  PHONE: new RegExp(/^\+?[0-9\s]{8,15}$/),
  DECIMAL: new RegExp(/^[0-9.]+$/),
  // Bank Related validations
  INSTITUTION: new RegExp('^[0-9]{3}$'),
  ROUTING: new RegExp('^[0-9]{9}$'),
  SWIFT: new RegExp('^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$'),
  IBAN: new RegExp(
    /^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/
  ),
  BITCOIN: new RegExp(/^(.*)+$/),
};

export const FIELD_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  DROPDOWN: 'dropdown',
  TEXT_AREA: 'text_area',
};

export const validateField = (fieldName, fieldValue, schema, errors) => {
  const fieldDefinition = schema.find((f) => f.fieldName === fieldName);
  if (!fieldDefinition) {
    console.warn(`Field definition is missing for ${fieldName} in ${schema}`);
    return { ...errors, [fieldName]: false };
  }

  if (fieldDefinition.fieldtype === FIELD_TYPE.DROPDOWN) {
    return { ...errors, [fieldName]: false };
  }
  if (!fieldDefinition.required) {
    if (['', null, undefined].indexOf(fieldValue) >= 0) {
      return { ...errors, [fieldName]: false };
    }
  }
  return { ...errors, [fieldName]: !fieldDefinition.fieldRule.test(fieldValue) };
};

export const isAllowed = () => {
  return getUserRoles().some((element) => [ROLES.ADMIN, ROLES.ACCOUNTING].includes(element));
};
