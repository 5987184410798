import React, { useEffect, useState } from "react"
import NewQuoteForm from "./NewQuoteForm.jsx"
import { createClient, REST_CLIENTS_TYPE } from '../../../rest'


const Counterparties = ({requestQuote}) => {
  const [counterparties, setCounterparties] = useState([]);

  useEffect(async () => {
    const cpResp = await createClient(REST_CLIENTS_TYPE.Party).getAll();
    setCounterparties(cpResp)
  },[])
  return (
    <div>{
      counterparties && counterparties.length ?
      <div>
        <NewQuoteForm requestQuote={requestQuote} counterparties={counterparties.sort((a, b) => a.name.localeCompare(b.name))} />
      </div>
      : null
    }
    </div>)
}

export default Counterparties

