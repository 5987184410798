import {CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { dynamic } from '../dynamicConfig'

function getUserpool() {
  const poolData = {
    UserPoolId: dynamic.config.cognito.poolId,
    ClientId: dynamic.config.cognito.clientId
  };
  return new CognitoUserPool(poolData);
}

let currentUser = undefined

export function login(email, password) {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: getUserpool(),
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log('Successfully logged in');
        return resolve(data.getAccessToken().getJwtToken());
      },
      onFailure: (err) => {
        console.error('Login failed');
        reject(err);
      },
    });
  });
}

export function logout() {
  const currentUser = getUserpool().getCurrentUser()
  if (!currentUser) return null;
  return new Promise((resolve, reject) => {
    currentUser.signOut();
    setTimeout(resolve, 0);
  });
}

export function isLoggedIn() {
  const currentUser = getUserpool().getCurrentUser()
  if (!currentUser) return null;
  return currentUser.getSession(function (err, session) {
    if (err) {
      console.error('No session is authenticated', err.message || JSON.stringify(err));
      return false;
    }
    if (session.isValid()) return true;
  });
}

export function userIsType(usertype) {
  if (!usertype) return false;

  if (localStorage.roles.includes(usertype)) return true;

  return false;
}

export const getUserRoles = () => {
  const currentUser = getUserpool().getCurrentUser()
  if (!currentUser) return null;
  return currentUser.getSession(function (err, session) {
    if (err) {
      console.error(err);
      return;
    }
    const sessionInfo = session.getAccessToken().getJwtToken().split('.');
    const payload = JSON.parse(Buffer.from(sessionInfo[1], 'base64').toString());
    if ('cognito:groups' in payload ) {
      return payload['cognito:groups'];
    }
    return []
  });
};

export const getUserToken = () => {
  const currentUser = getUserpool().getCurrentUser()
  if (!currentUser) return null;
  return currentUser.getSession((err, session) => {
    if (err) {
      console.error(err);
      return;
    }
    //return session.getAccessToken().getJwtToken() 
    return session.getIdToken().getJwtToken(); // TODO: use access token instead, access token contains group assertions
  });
};
