import axios from "axios";
import { ReactElement} from "react";
import ReactDOM  from "react-dom";
import './styles/app.css';
import { dynamic, configUrl } from "./dynamicConfig";
import App from "./App";

axios.get(configUrl)
  .then((response) => {
    dynamic.config = response.data;
    return <App />;
  })
  .catch(e => {
      return <p style={{color: "red", textAlign: "center"}}>Error while fetching global config</p>;
  })
  .then((reactElement: ReactElement) => {
    ReactDOM.render(
      reactElement,
      document.getElementById("root")
    );
  });