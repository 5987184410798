import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Grid, Input, TextArea, Modal } from 'semantic-ui-react';
import { COUNTRIES } from '../Utilities/forex';
import { PATTERN, FIELD_TYPE, validateField } from '../Utilities/core';
import { ACCOUNT_TYPE } from "../../const";
import { REST_CLIENTS_TYPE, createClient } from '../../rest';

const TITLE = 'Wire Transfer';

const SCHEMA = [
  {
    fieldName: 'title',
    fieldRule: PATTERN.SAFE_CHARS,
    fieldtype: FIELD_TYPE.STRING,
    required: true,
  },
  { fieldName: 'name', fieldRule: PATTERN.NAME, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'bankId', fieldtype: FIELD_TYPE.DROPDOWN, required: true },
  { fieldName: 'branch', fieldRule: PATTERN.SAFE_CHARS, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'address', fieldRule: PATTERN.SAFE_CHARS, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'city', fieldRule: PATTERN.CITY, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'postal', fieldRule: PATTERN.POSTAL_CODE, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'country', fieldtype: FIELD_TYPE.DROPDOWN, required: true },
  {
    fieldName: 'accountNumber',
    fieldRule: PATTERN.DECIMAL,
    fieldtype: FIELD_TYPE.STRING,
    required: true,
  },
  { fieldName: 'beneficiary', fieldRule: PATTERN.NAME, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'clearingCode', fieldRule: PATTERN.SAFE_CHARS, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'routing', fieldRule: PATTERN.ROUTING, fieldtype: FIELD_TYPE.NUMBER },
  { fieldName: 'swift', fieldRule: PATTERN.SWIFT, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'iban', fieldRule: PATTERN.IBAN, fieldtype: FIELD_TYPE.STRING },
  { fieldName: 'memo', fieldRule: PATTERN.SAFE_CHARS, fieldtype: FIELD_TYPE.STRING },
];

const FORM = (props) => {
  let bankWire = {};
  if (props.bankId) {
    bankWire = { bankId: props.bankId };
  }
  const [post, setPost] = useState(props.data || bankWire);
  const [errors, setErrors] = useState(
    Object.assign({}, ...Object.keys(props.data || bankWire).map((k) => ({ [k]: false })))
  );
  const [submit, setSubmit] = useState(false);
  const [apiAlert, setApiAlert] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [banks, setBanks] = useState();

  useEffect(() => setSubmit(isValidForm()), [errors]);

  const onChange = (e, { name, value }) => {
    setPost({ ...post, [name]: value });
    setErrors(validateField(name, value, props.schema, errors));
  };

  useEffect(() => {
    async function getBanks() {
      try {
        const resp = await createClient(REST_CLIENTS_TYPE.Bank).getAll();
        setBanks(resp);
      } catch (e) {
        console.error(`Failed to fetch banks`, e);
      }
    }
    getBanks();
  }, []);

  const isValidForm = () => {
    const schema = props.schema || [];
    for (let i = 0; i < schema.length; i++) {
      let fd = schema[i];
      if (fd.required) {
        if (!errors.hasOwnProperty(fd.fieldName) || errors[fd.fieldName]) {
          return false;
        }
      } else {
        if (errors.hasOwnProperty(fd.fieldName) && errors[fd.fieldName]) {
          return false;
        }
      }
    }
    return true;
  };

  const getSelectedBankCurrency = () => {
    return banks.find((x) => x.id === props.bankId || post.bankId).currency;
  };

  const submitForm = async () => {
    setButtonLoading(true);
    if (props.partyId) {
      post.partyId = props.partyId;
      post.currency = getSelectedBankCurrency();
    } else if (props.bankId) {
      post.bankId = props.bankId;
      post.currency = props.bank.currency;
    }
    if (props.data) {
      createClient(REST_CLIENTS_TYPE.Acct)
        .update({ accountType: ACCOUNT_TYPE.WIRE, ...post })
        .then(props.onSuccess)
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to update wire ${post}`, e);
          setApiAlert(e.message);
        });
    } else {
      createClient(REST_CLIENTS_TYPE.Acct)
        .create({ accountType: ACCOUNT_TYPE.WIRE, ...post })
        .then(props.onSuccess)
        .catch((e) => {
          setButtonLoading(false);
          console.error(`Failed to create wire ${post}`, e);
          setApiAlert(e.message);
        });
    }
  };

  const getBankList = () => {
    if (!banks) return [];
    console.log('banks list', banks);
    return banks.map((x) => {
      return { name: x.name, value: x.id, text: x.name + ' - ' + x.currency };
    });
  };

  return (
    <Grid centered columns={1}>
      <Modal onClose={() => setApiAlert(false)} open={apiAlert} size="tiny">
        <Modal.Header color="red">Error saving data</Modal.Header>
        <Modal.Content>
          <p>{apiAlert}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary fluid content="Close" onClick={() => setApiAlert(false)} />
        </Modal.Actions>
      </Modal>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Wire Transfer Title</label>
          <Input
            error={errors.title}
            fluid
            placeholder="Enter Title"
            name="title"
            value={post.title}
            onChange={onChange}
          />
          {errors.title && <span className="error-text">Title is required</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Name on Account</label>
          <Input
            error={errors.name}
            fluid
            placeholder="Enter Name"
            name="name"
            value={post.name}
            onChange={onChange}
          />
          {errors.name && <span className="error-text">Name is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {props.bankId || (props.data && props.data.partyId === 0) ? (
          <Grid.Column width={6} className="noPadding">
            <label>Bank Name</label>
            <Dropdown
              error={errors.bankId}
              selection
              fluid
              placeholder="Bank Name"
              name="bankId"
              value={post.bankId}
              options={getBankList()}
              onChange={onChange}
              disabled
            />
            {errors.bankId && <span className="error-text">Bank name is required</span>}
          </Grid.Column>
        ) : (
          <Grid.Column width={6} className="noPadding">
            <label>Bank Name</label>
            <Dropdown
              error={errors.bankId}
              selection
              fluid
              placeholder="Bank Name"
              name="bankId"
              value={post.bankId}
              options={getBankList()}
              onChange={onChange}
            />
            {errors.bankId && <span className="error-text">Bank name is required</span>}
          </Grid.Column>
        )}
        <Grid.Column width={6} className="noPadding">
          <label>Branch Name</label>
          <Input
            error={errors.branch}
            fluid
            className="control"
            name="branch"
            value={post.branch}
            placeholder="Branch Name"
            onChange={onChange}
          />
          {errors.branch && <span className="error-text">Branch name is required</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Branch Address</label>
          <Input
            error={errors.address}
            fluid
            className="control"
            name="address"
            value={post.address}
            placeholder="Enter Address"
            onChange={onChange}
          />
          {errors.address && <span className="error-text">Address is required</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>City</label>
          <Input
            error={errors.city}
            fluid
            className="control"
            name="city"
            value={post.city}
            placeholder="Enter City"
            onChange={onChange}
          />
          {errors.city && <span className="error-text">City is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Postal / ZIP</label>
          <Input
            error={errors.postal}
            fluid
            className="control"
            name="postal"
            value={post.postal}
            placeholder="Enter Postal / ZIP Code"
            onChange={onChange}
          />
          {errors.postal && <span className="error-text">Postal is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Country</label>
          <Dropdown
            search
            fluid
            selection
            className="control"
            name="country"
            value={post.country}
            placeholder="Select Country"
            options={COUNTRIES}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Account Number</label>
          <Input
            error={errors.accountNumber}
            fluid
            type="number"
            className="control"
            name="accountNumber"
            value={post.accountNumber}
            placeholder="Enter Account Number"
            onChange={onChange}
          />
          {errors.accountNumber && <span className="error-text">Account number Required</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Routing Number</label>
          <Input
            error={errors.routing}
            fluid
            type="number"
            className="control"
            name="routing"
            value={post.routing}
            placeholder="Enter Routing Number"
            onChange={onChange}
          />
          {errors.routing && <span className="error-text">Routing number should be 9 digits</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Swift Code / BIC</label>
          <Input
            error={errors.swift}
            fluid
            className="control"
            name="swift"
            value={post.swift}
            placeholder="Enter Swift Code / BIC"
            onChange={onChange}
          />
          {errors.swift && <span className="error-text">Swift Code is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>Beneficiary Bank</label>
          <Input
            error={errors.beneficiary}
            fluid
            className="control"
            name="beneficiary"
            value={post.beneficiary}
            placeholder="Add beneficiary if nessesary"
            onChange={onChange}
          />
          {errors.beneficiary && <span className="error-text">Beneficiary Bank is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="noPadding">
          <label>Bank Clearing Code / NCC</label>
          <Input
            error={errors.clearingCode}
            fluid
            className="control"
            name="clearingCode"
            value={post.clearingCode}
            placeholder="Add clearing code if nessesary"
            onChange={onChange}
          />
          {errors.clearingCode && <span className="error-text">Clearing Code is invalid</span>}
        </Grid.Column>
        <Grid.Column width={6} className="noPadding">
          <label>IBAN</label>
          <Input
            error={errors.iban}
            fluid
            className="control"
            name="iban"
            value={post.iban}
            placeholder="Add IBAN if nessesary"
            onChange={onChange}
          />
          {errors.iban && <span className="error-text">IBAN is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding notes">
          <label>Memo</label>
          <TextArea
            name="memo"
            className="control"
            value={post.memo}
            placeholder="Add a note here"
            onChange={onChange}
          />
          {errors.memo && <span className="error-text">Memo is invalid</span>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12} className="noPadding noMargin">
          <Button loading={buttonLoading} fluid primary disabled={!submit} onClick={submitForm}>
            {props.data ? 'Update' : 'Add'} Wire Transfer
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export { TITLE, FORM, SCHEMA };
