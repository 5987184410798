import React,  { useState } from 'react';
import * as auth from '../js/Auth';
import { NavLink } from 'react-router-dom';
import { Select } from 'semantic-ui-react';
import { isAllowed } from './Utilities/core';

// const Organizations = [
//   {
//     key: 'paradox_a',
//     text: 'Paradox A',
//     value: 'paradox_a',
//     image: { avatar: true, src: '/avatar/paradox.png' },
//   },
//   {
//     key: 'paradox_b',
//     text: 'Paradox B',
//     value: 'paradox_b',
//     image: { avatar: true, src: '/avatar/paradox.png' },
//   },
// ];

const Nav = (props) => {
  // const [selectedPage, setSelectedPage] = useState('dashboard')
  // const [selectedOrg, setSelectedOrg] = useState(Organizations[0].value)
  console.log(props)
  return (
    <header className="Nav">
      <span className="icon-osf">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
      {/* <Select selection className="Nav-organizations" defaultValue={selectedOrg} options={Organizations} onChange={setSelectedOrg} /> */}
      {isAllowed() ? (
        <>
          <div className="Nav-label">DATA</div>
          <nav>
            {/*<NavLink to="/dashboard">*/}
            {/*  <span className="icon icon-meter"></span>*/}
            {/*  <span>Dashboard</span>*/}
            {/*</NavLink>*/}
            {/*<NavLink to="/markets">*/}
            {/*  <span className="icon icon-markets"></span>*/}
            {/*  <span>Markets</span>*/}
            {/*</NavLink>*/}
            {/*<NavLink to="/accounts"> <span className="icon icon-wallets"></span> <span>Accounts</span> </NavLink> */}
            <NavLink to="/exposures">
              <span className="icon icon-umbrella"></span>
              <span>Exposures</span>
            </NavLink>
            {/*<NavLink to="/alerts">*/}
            {/*  <span className="icon icon-warning1"></span>*/}
            {/*  <span>Alerts</span>*/}
            {/*  <span className="alert-count">3</span>*/}
            {/*</NavLink>*/}
          </nav>
        </>
      ) : (
        ''
      )}
      <div className="Nav-label">TRADING</div>
      <nav>
        {/*<NavLink to="/opportunities">*/}
        {/*  <span className="icon icon-opportunities"></span>*/}
        {/*  <span>Opportunities</span>*/}
        {/*</NavLink>*/}
        {/*<NavLink to="/order">*/}
        {/*  <span className="icon icon-order"></span>*/}
        {/*  <span>Enter order</span>*/}
        {/*</NavLink>*/}
        {/*<NavLink to="/bots">*/}
        {/*  <span className="icon icon-bots"></span>*/}
        {/*  <span>Bots</span>*/}
        {/*</NavLink>*/}
        {/*<NavLink to="/calculators">*/}
        {/*  <span className="icon icon-calculators"></span>*/}
        {/*  <span>Calculators</span>*/}
        {/*</NavLink>*/}
        {/* { isAllowed() ? ( <NavLink to="/positions"> <span className="icon icon-order"></span> <span>Positions</span> </NavLink>) : ( '') } */}

        <NavLink to="/otc">
          <span className="icon icon-otc"></span>
          <span>OTC</span>
        </NavLink>
      </nav>
      {isAllowed() ? (
        <>
          <div className="Nav-label">SYSTEM</div>
          <nav>
            {/*<NavLink to="/users">*/}
            {/*  <span className="icon icon-users"></span>*/}
            {/*  <span>Users</span>*/}
            {/*</NavLink>*/}
            {/*<NavLink to="/status">*/}
            {/*  <span className="icon icon-status"></span>*/}
            {/*  <span>Status</span>*/}
            {/*</NavLink>*/}
            <NavLink to="/counterparties">
              <span className="icon icon-counterparties"></span>
              <span>Counterparties</span>
            </NavLink>
            <NavLink to="/banks">
              <span className="icon icon-banks"></span>
              <span>Banks</span>
            </NavLink>
            <NavLink to="/wallets">
              <span className="icon icon-wallets"></span>
              <span>Wallets</span>
            </NavLink>
          </nav>
        </>
      ) : (
        ''
      )}

      <nav>
        <div
          onClick={() =>
            auth.logout().then(() => {
              this.props.history.push('/');
            })
          }
        >
          Logout
        </div>
      </nav>
    </header>
  );
}

export default Nav