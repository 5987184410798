import { format } from 'd3';

export const numberWithCommas = (x) => {
  if (!x) return 0;
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export var scaleDownNumber = function (number) {
  var scaledNumber = format('.5s')(number); // "5.0M"
  return scaledNumber;

  // symbol and number
  // var formatPrefix = {
  //   number: /^[^a-zA-Z]+/.test(scaledNumber) ? scaledNumber.match(/^[^a-zA-Z]+/)[0].trim() : 0,
  //   symbol: /[a-zA-Z]+$/.test(scaledNumber) ? scaledNumber.match(/[a-zA-Z]+$/)[0].trim() : '',
  // }

  // if (formatPrefix.symbol === 'G') formatPrefix.symbol = 'bn'

  // var expDecimals
  // if (formatPrefix.number > 999 || number === formatPrefix.number) {
  //   expDecimals = 0
  // } else {
  //   expDecimals = 2
  // }
  // return decimalCount(formatPrefix.number, expDecimals) + formatPrefix.symbol
};

export var decimalCount = function (number, dec) {
  if (typeof number === 'string') {
    number = parseFloat(number.replace(/,/g, ''));
  }
  if (dec >= 0 && dec !== null) {
    dec = Number(number).toFixed(dec) * 1;
  } else {
    if (number >= 10000 || number <= -10000 || number === 0) {
      dec = 0;
    } else if (number >= 1000 || number <= -1000) {
      dec = 2;
    } else if ((number >= 2 && number <= 1000) || (number < 0 && number >= -2)) {
      dec = 3;
    } else if (number <= 2 && number >= 0 && number <= 0.001) {
      dec = 8;
    } else if (number <= 2 && number >= 0) {
      dec = 6;
    } else if (number > -1 && number < 0) {
      dec = 2;
    } else if ((number < -1 && number >= -1000) || (number < -1000 && number >= -10000)) {
      dec = 2;
    } else if (number < 0.0001) {
      dec = 1;
    }
  }

  return dec;
};

export var decimals = function (number, num_decimals, dec = '.', sep = ',') {
  if (typeof number === 'string') {
    number = parseFloat(number.replace(/,/g, ''));
  }
  if (num_decimals === null || num_decimals === undefined) {
    num_decimals = decimalCount(number);
  }
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+num_decimals) ? 0 : Math.abs(num_decimals),
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
};

export var highlightDiffFont = function (new_data, old_data, num_decimals, pre_text, post_text) {
  if (typeof new_data === 'string') {
    post_text = new_data.replace(/[^a-zA-Z]/g, '');
    new_data = parseFloat(new_data.replace(/[,a-zA-Z]/g, ''));
  }

  if (typeof old_data === 'string') {
    old_data = parseFloat(old_data.replace(/,/g, ''));
  }

  if (typeof pre_text === 'undefined') pre_text = '';
  if (typeof post_text === 'undefined') post_text = '';

  var old_price = pre_text + decimals(old_data, num_decimals, '.', ',') + post_text;
  var new_price = pre_text + decimals(new_data, num_decimals, '.', ',') + post_text;
  // Apply tick coloring
  if (new_price !== old_price) {
    if (old_price === '') {
      // Just fill in
      return new_price;
    } else {
      // Create tick from price difference
      var diff =
        parseFloat(
          new_price
            .substring(pre_text.length, new_price.length - post_text.length)
            .replace(',', ''),
          10
        ) -
        parseFloat(
          old_price
            .substring(pre_text.length, old_price.length - post_text.length)
            .replace(',', ''),
          10
        );

      var className = diff > 0 ? 'green' : 'red';

      var first_different = 0;
      for (var i = 0; i < new_price.length; i++) {
        if (i >= old_price.length) {
          break;
        }
        if (old_price.charAt(i) !== new_price.charAt(i)) {
          first_different = i;
          break;
        }
      }
      var price_html =
        new_price.substr(0, first_different) +
        '<font class="' +
        '' +
        '">' +
        new_price.substr(first_different, new_price.length - first_different) +
        '</font>';

      return price_html;
    }
  } else {
    return new_price;
  }
};

export default {
  decimals,
  highlightDiffFont,
  scaleDownNumber,
};
