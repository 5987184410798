export  class ForexService {
  static loadFromExposures = (data) => {
    const ticks = data && data.length > 0?
      data.filter(x => x && (x.usd_tick || x.exposure_tick)).map(x => x.usd_tick || x.exposure_tick): [];
    return new ForexService(ticks);
  }

  constructor(ticks) {
    this.ticks = ticks;
  }

  allMarketAliasByAssets(baseAlias, quoteAlias) {
    return this.ticks.filter(t =>
      (t.market.base.alias === baseAlias && t.market.quote.alias === quoteAlias) ||
      (t.market.base.alias === quoteAlias && t.market.quote.alias === baseAlias),
    );
  }

  allUsdMarketAliasByAsset(assetAlias) {
    return this.allMarketAliasByAssets(assetAlias, 'USD');
  }

  evaluateUsdNotional(assetAlias, size = 1, vendorPriority = 'ftx') {
    if(assetAlias.toLowerCase() === 'usd' || assetAlias.toLowerCase() === 'usdt') return size;
    const mkts = this.allUsdMarketAliasByAsset(assetAlias);
    if (typeof mkts === 'undefined' || mkts.length <= 0) return undefined;
    let mkt = mkts.find(m => m.market.vendor === vendorPriority);
    if (typeof mkt === 'undefined') mkt = mkts[0];
    const rate = mkt.tick.price;
    return size * ((mkt.market.quote.alias === 'USD') ? rate : 1 / rate);
  }
}