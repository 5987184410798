import React from 'react'
import { NavLink, Route, Switch } from "react-router-dom"
import ExposureDetails from './ExposureDetails'

const ExposureIndex = (props) => {
  // TODO: probably convert them to array not sure what was intuition behind it.
  const exchanges = {
    'bitfinex': {},
    'bitmex': {},
    'deribit': {},
    'ftx': {},
    'ftx_otc': {},
    'osf_otc': {},
    'ftx_us': {},
    'b2c2': {},
    'silvergate': {},
    'deltec':{}
  };

  return (
    <div className="exposure-index">
      <div className="tabs">
        <NavLink exact activeClassName="active" className="tab" 
          to={`${props.match.url}/`}>Aggregate</NavLink>
        {
          exchanges && Object.keys(exchanges).filter(k => typeof exchanges[k] === "object" ).map((exchange,index) => {
            return (
            <NavLink key={index} activeClassName="active" className="tab" to={{
              pathname:`/exposures/${exchange}`,
              state:exchange
            }}
              >{exchange}</NavLink>
            )
          })
        }
      </div>
      <div className="exposure-tab">
        <Switch>
          <Route exact path={`${props.match.path}/`} component={ExposureDetails} />
          <Route path={`${props.match.path}/:exchange`} render={(props) => (
              <ExposureDetails {...props} data={exchanges} />
          )} /> 
        </Switch>
      </div>
    </div>
  )
}

export default ExposureIndex;